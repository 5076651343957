import { DynamicApiUrlsInstance, MANAGE_REQUIREMENT_URLS } from "constants/ApiUrls";

import { api, GenericResponse } from "./api";
export interface IBrand {
  _id: string;
  brandName: string;
  brandLogoUrl: string;
  legalEntityName?: string;
  industry?: string;
}

export interface ILocation {
  _id: string;
  name: string;
  slug: string;
}

export interface ILocalityParams {
  cityId: string;
  limit?: number;
}

export interface IPhoneNumber {
  countryCode: string;
  number: string | number;
}

export interface IBrandPoc {
  _id?: string;
  name: string;
  email: string;
  brandId?: string;
  designation: string;
  phoneNumber: IPhoneNumber;
  alternatePhoneNumbers?: Array<IPhoneNumber>;
  type?: string;
}
export interface ILandLord extends IBrandPoc {
  mallId?: string;
}

export interface ICarpetArea {
  min: number;
  max: number;
}

export interface IRequirement {
  localityId: string;
  floors: Array<string>;
  shopType: string;
}

export interface ICommonRequirements {
  carpetArea: ICarpetArea;
  cityId: string;
  minFloorPlate?: number;
  height?: number;
  frontage?: number;
}

export interface ICreateRequirement extends ICommonRequirements {
  brandPOC: IBrandPoc;
  remarks: string;
  requirementDetails: Array<IRequirement>;
}

export interface IEditRequirement {
  brandRequirementId: string;
  body: ICreateRequirement;
}

export type IEditRequirementResponse = GenericResponse<{
  brandRequirementId: string;
}>;

export type IFloor = string;
export type IShopType = string;

export type IBrandListResponse = GenericResponse<{ brands: IBrand[] }>;
export type IFloorListResponse = GenericResponse<{ floors: IFloor[] }>;
export type IShopTypeResponse = GenericResponse<{ shopTypes: IShopType[] }>;
export type ICityListResponse = GenericResponse<{ cities: Array<ILocation> }>;
export type ILocalityListResponse = GenericResponse<{
  localities: Array<ILocation>;
}>;
export type ICreateRequirementResponse = GenericResponse<{
  brandRequirementId: string;
}>;

const createRequirementMetaDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBrandList: builder.query<IBrandListResponse, void>({
      query: () => ({
        url: MANAGE_REQUIREMENT_URLS.GET_BRAND_LIST,
      }),
      providesTags: ["Brands"],
    }),
    getFloorList: builder.query<IFloorListResponse, void>({
      query: () => ({
        url: MANAGE_REQUIREMENT_URLS.GET_FLOOR_LIST,
      }),
    }),
    getShopTypeList: builder.query<IShopTypeResponse, void>({
      query: () => ({
        url: MANAGE_REQUIREMENT_URLS.GET_SHOP_TYPE_LIST,
      }),
    }),
    getCityList: builder.query<ICityListResponse, void>({
      query: () => ({
        url: MANAGE_REQUIREMENT_URLS.GET_CITY_LIST,
      }),
    }),
    getAllCityList: builder.query<ICityListResponse, void>({
      query: () => ({
        url: MANAGE_REQUIREMENT_URLS.GET_ALL_CITY_LIST,
      }),
    }),
    getLocalityList: builder.query<ILocalityListResponse, ILocalityParams>({
      query: (params) => ({
        url: MANAGE_REQUIREMENT_URLS.GET_LOCALITY_LIST,
        params,
      }),
    }),
  }),
});

const manageRequirement = api.injectEndpoints({
  endpoints: (builder) => ({
    createRequirement: builder.mutation<ICreateRequirementResponse, ICreateRequirement>({
      query: (body) => ({
        url: MANAGE_REQUIREMENT_URLS.CREATE_REQUIREMENT,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RequirementDetailsCount"],
    }),
    editRequirement: builder.mutation<IEditRequirementResponse, IEditRequirement>({
      query: ({ body, brandRequirementId }) => ({
        url: DynamicApiUrlsInstance.editRequirement(brandRequirementId),
        body,
        method: "PUT",
      }),
      invalidatesTags: ["RequirementDetailsCount"],
    }),
  }),
});

export const {
  useGetBrandListQuery,
  useGetFloorListQuery,
  useGetShopTypeListQuery,
  useGetLocalityListQuery,
  useGetCityListQuery,
  useGetAllCityListQuery,
} = createRequirementMetaDataApi;
export const { useCreateRequirementMutation, useEditRequirementMutation } = manageRequirement;
