import { ENUM_URLS } from "constants/ApiUrls";

import { api, GenericResponse } from "./api";

export interface IEnum {
  [key: string]: string;
}

export interface IEnumData {
  floor: IEnum;
  shopType: IEnum;
  industry: IEnum;
  parking: IEnum;
  shopStatus: IEnum;
  shopTag: IEnum;
  shopPOCDesignation: IEnum;
  financialYear: IEnum;
  brokerageUnits: IEnum;
  month: IEnum;
  wipBillingMilestones: IEnum;
  wipStatus: IEnum;
  areaUnits: IEnum;
  analyticsCategory: IEnum;
  shopPOCType: IEnum;
  invoiceStatus: IEnum;
  nudgeStatus: IEnum;
  serviceType: IEnum;
  creditPeriod: IEnum;
  creditRating: IEnum;
  decliningReasons: IEnum;
  consultingWipBillingMilestones: IEnum;
  invoiceRequestType: IEnum;
  consultingServiceType: IEnum;
  irfStatus: IEnum;
  irfDecliningReasons: IEnum;
  paymentClaimDecliningReasons: IEnum;
}

export type IEnumsResponse = GenericResponse<IEnumData>;
export const enumsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStaticEnums: builder.query<IEnumsResponse, void>({
      query: (body) => ({
        url: ENUM_URLS.ENUMS,
        method: "GET",
        body,
      }),
    }),
  }),
});

export const { useGetStaticEnumsQuery } = enumsApi;
