import { ButtonTypes, inputType, nodeType } from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { convertToRupee } from "app/utils/helperFunctions";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { EmptyNode } from "features/invoices/components/formHelpers";
import { StaticValue } from "features/wip/components/WIP-BrokerageProjectionForm/partials";
import React from "react";

import styles from "../../../wip/index.module.scss";

export const FYDetailsNode: (
  fy: string,
  landlordAmount: number,
  brandAmount: number,
  toggleDetails: () => void,
  billingClient: string,
  visible?: boolean
) => ArFormContainerNode = (fy, landlordAmount, brandAmount, toggleDetails, billingClient, visible) => {
  return {
    type: nodeType.container,
    elementData: {
      innerContainerClassName: styles.staticDataBox,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        {
          type: nodeType.container,
          elementData: {
            body: (
              <span className={styles.emptyBoxContainer}>
                <span className={styles.emptyBox}></span>
              </span>
            ),
          },
        },
        {
          type: nodeType.container,
          elementData: {
            innerContainerClassName: `${styles.commercialInnerContainer}`,
            outerContainerClassName: styles.commercialOuterConatiner,
          },
          childNode: {
            type: nodeType.array,
            childNode:
              billingClient === STRING_CONSTANTS.BRAND
                ? [
                    {
                      type: nodeType.container,
                      elementData: {
                        body: <StaticValue label={FORM_FIELDS.FINANCIAL_YEAR} value={fy} />,
                      },
                    },
                    {
                      type: nodeType.container,
                      elementData: {
                        body: (
                          <StaticValue label={FORM_FIELDS.BRAND_BROKERAGE} value={`${convertToRupee(brandAmount)} `} />
                        ),
                      },
                    },
                    EmptyNode,
                  ]
                : billingClient === STRING_CONSTANTS.LANDLORD
                ? [
                    {
                      type: nodeType.container,
                      elementData: {
                        body: <StaticValue label={FORM_FIELDS.FINANCIAL_YEAR} value={fy} />,
                      },
                    },
                    {
                      type: nodeType.container,
                      elementData: {
                        body: (
                          <StaticValue label={FORM_FIELDS.LL_BROKERAGE} value={`${convertToRupee(landlordAmount)}`} />
                        ),
                      },
                    },
                    EmptyNode,
                  ]
                : [
                    {
                      type: nodeType.container,
                      elementData: {
                        body: <StaticValue label={FORM_FIELDS.FINANCIAL_YEAR} value={fy} />,
                      },
                    },
                    {
                      type: nodeType.container,
                      elementData: {
                        body: (
                          <StaticValue label={FORM_FIELDS.LL_BROKERAGE} value={`${convertToRupee(landlordAmount)}`} />
                        ),
                      },
                    },
                    {
                      type: nodeType.container,
                      elementData: {
                        body: (
                          <StaticValue label={FORM_FIELDS.BRAND_BROKERAGE} value={`${convertToRupee(brandAmount)} `} />
                        ),
                      },
                    },
                  ],
          },
        },
        {
          type: nodeType.input,
          elementData: {
            inputType: inputType.button,
            inputProps: {
              type: ButtonTypes.Link,
              children: visible ? STRING_CONSTANTS.HIDE_PROJECTION : STRING_CONSTANTS.SHOW_PROJECTION,
              size: "small",
              onClick: toggleDetails,
            },
          },
        },
      ],
    },
  };
};
