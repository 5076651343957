import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type ToggleNotification = {
  show: boolean;
};

const initialState: ToggleNotification = {
  show: false,
};

const toggleNotificationSlice = createSlice({
  name: "toggleNotification",
  initialState,
  reducers: {
    showNotification: (state) => {
      state.show = true;
    },
    hideNotification: (state) => {
      state.show = false;
    },
  },
});

export const selectShowNotification = (state: RootState) => state.toggleNotifictaion.show;
export const { showNotification, hideNotification } = toggleNotificationSlice.actions;
export default toggleNotificationSlice.reducer;
