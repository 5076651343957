import { isStringEmpty } from "app/utils/helperFunctions";
import { DynamicApiUrlsInstance, NOTIFICATION_URLS, SHOP_URLS } from "constants/ApiUrls";

import { api, GenericResponse } from "./api";

export interface IShopStatusEnums {
  [id: string]: string;
}

export type IGetShopStatusEnumsResponse = GenericResponse<{
  shopStatusEnum: IShopStatusEnums;
}>;

export interface ISelfUpdateShopStatus {
  shopId: string;
  status: {
    state: string;
  };
}

export const shopMutationApis = api.injectEndpoints({
  endpoints: (build) => ({
    getShopStatusEnums: build.query<IGetShopStatusEnumsResponse, void>({
      query: () => ({
        url: SHOP_URLS.SHOP_STATUS_ENUMS,
      }),
    }),
    askToUpdateShopsStatus: build.mutation<GenericResponse, string[]>({
      query: (shopIds) => ({
        url: NOTIFICATION_URLS.ASK_TO_UPDATE,
        body: {
          shopIds,
        },
        method: "POST",
      }),
    }),
    askToUpdateDraftShop: build.mutation<GenericResponse, string>({
      query: (shopId) => ({
        url: DynamicApiUrlsInstance.askToUpdateDraftShop(shopId),
        method: "POST",
        skip: isStringEmpty(shopId),
      }),
    }),
    selfUpdateShopStatus: build.mutation<GenericResponse, ISelfUpdateShopStatus>({
      query: ({ shopId, status }) => ({
        url: DynamicApiUrlsInstance.selfUpdateShopStatus(shopId),
        body: {
          status,
        },
        skip: isStringEmpty(shopId),
        method: "PUT",
      }),
      invalidatesTags: ["SelfUpdate"],
    }),
  }),
});

export const {
  useGetShopStatusEnumsQuery,
  useSelfUpdateShopStatusMutation,
  useAskToUpdateShopsStatusMutation,
  useAskToUpdateDraftShopMutation,
} = shopMutationApis;
