import { useAppDispatch } from "app/hooks";
import { api } from "app/services/api";
import { showNotification } from "app/slice/toggleNotification";
import { IPushNotification } from "app/utils/interfaces/index.interface";
import React, { useCallback, useEffect, useState } from "react";

import { onMessageListener } from "../../app/utils/firebase";
import sound from "../../assets/audio/success.mp3";

function PushNotifications() {
  const [notification, setNotification] = useState({ title: "", body: "" });

  const dispatch = useAppDispatch();

  const toggleNotificationUsingRedux = useCallback(() => {
    dispatch(showNotification());
  }, [dispatch]);

  const invalidateNotificationsTag = useCallback(() => {
    dispatch(api.util.invalidateTags(["Notifications"]));
  }, [dispatch]);

  const playSound = async () => {
    try {
      const audio = new Audio(sound);
      await audio.play();
    } catch {
      console.error("Error in playing notification sound");
    }
  };

  const onBackgroundOrQuitMessageRecieved = useCallback(() => {
    try {
      playSound();
    } catch (error) {
      console.error("Failed to play sound:", error);
    } finally {
      toggleNotificationUsingRedux();
      invalidateNotificationsTag();
    }
  }, [invalidateNotificationsTag, toggleNotificationUsingRedux]);

  const onForegroundMessage = useCallback(
    (payload: any) => {
      const notification = payload?.notification as IPushNotification;
      const notify = new Notification(notification?.title, {
        body: notification?.body,
      });
      notify.addEventListener("click", () => {
        // Trigger state update or perform other actions
        notify.close();
        toggleNotificationUsingRedux();
      });
      playSound();
      invalidateNotificationsTag();
      setNotification({
        title: notification?.title,
        body: notification?.body,
      });
    },
    [toggleNotificationUsingRedux, invalidateNotificationsTag]
  );

  useEffect(() => {
    const unsubscribe = onMessageListener().then((payload: any) => onForegroundMessage(payload));
    return () => {
      unsubscribe.catch((err) => console.error("failed: ", err));
    };
  }, [notification, onForegroundMessage]);

  useEffect(() => {
    const channel = new BroadcastChannel("notification-channel");
    channel.addEventListener("message", onBackgroundOrQuitMessageRecieved);

    // Clean up the channel listener when component unmounts
    return () => {
      channel.close();
    };
  }, [onBackgroundOrQuitMessageRecieved]);

  return <React.Fragment></React.Fragment>;
}
export default PushNotifications;
