import { ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch } from "app/hooks";
import { IMatchingShopDetail } from "app/services/matchingPropertiesApi";
import { useGenerateBrochureForShopMutation } from "app/services/optionListApi";
import { IShopDetails, IShopListing } from "app/services/shopDetailsApi";
import { setJobId } from "app/slice/brochureSlice";
import { setOpenModal, setShopId } from "components/appModals/addToOptionListSlice";
import { useToastService } from "components/toastService";
import { SHOP_STATUS } from "constants/ListingEnums";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React from "react";

import AskToUpdateShops from ".";
import styles from "./index.module.scss";

const ShopAction: React.FC<{
  shop: IMatchingShopDetail | IShopDetails | IShopListing;
}> = ({ shop }) => {
  const dispatch = useAppDispatch();

  const [generateBrochureApi] = useGenerateBrochureForShopMutation();

  const { errorToast } = useToastService();

  const handleAddToMultipleOptionList = () => {
    dispatch(setOpenModal(true));
    dispatch(setShopId(shop?._id));
  };

  const generateBrochureForShop = async () => {
    let generateBrochureResponse;
    try {
      generateBrochureResponse = await generateBrochureApi(shop?._id).unwrap();
    } catch {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    if (generateBrochureResponse?.success) {
      dispatch(setJobId(generateBrochureResponse?.data?.jobId));
    } else {
      errorToast(generateBrochureResponse?.reason || TOAST_MESSAGES.ERROR_IN_GENERATING_BROCHURE);
    }
  };
  return (
    <div className={styles.actionBtnContainer}>
      <ArButton type={ButtonTypes.Outlined} onClick={generateBrochureForShop}>
        {STRING_CONSTANTS.SHARE_OPTIONS}
      </ArButton>
      {shop?.status?.shopTag === SHOP_STATUS.EXPIRED ? (
        <AskToUpdateShops shop={shop} buttonType={ButtonTypes.Primary} />
      ) : (
        <ArButton onClick={handleAddToMultipleOptionList} type={ButtonTypes.Outlined}>
          <PlusOutlined />
        </ArButton>
      )}
    </div>
  );
};

export default ShopAction;
