import {
  ArButton,
  ArForm,
  ArInputType,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { PlusSquareOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useForm } from "antd/es/form/Form";
import { useGetConsultingWipDetailsQuery, useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import { IBrand, ILandLord, useGetBrandListQuery } from "app/services/createRequirmentApi";
import { IClientPOC, useGetClientPOCListQuery } from "app/services/invoices";
import { getValueOrDash, isNullOrUndefiend, isObjectNullOrUndefiendOrEmpty } from "app/utils/helperFunctions";
import { IWIPApproval } from "app/utils/interfaces/index.interface";
import BrandListModal from "components/appModals/brandListModal";
import LandLordListModal from "components/LandLordListModal";
import AddLLModal from "components/LandLordListModal/partials";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { FORM_FIELDS, FORM_MODAL_STRING } from "constants/RequirementFrom";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import {
  getClientPOCModalOptions,
  getClientPOCObjFromArray,
} from "features/invoices/components/IRFContactDetails/partials";
import { getBrandOptions } from "features/manageRequirement/create-requirement/partials";
import { DeleteDraftWIPModal } from "features/wip/components/ConfirmationModals";
import { StaticValue, StaticValueNode } from "features/wip/components/WIP-BrokerageProjectionForm/partials";
import { WipFormProps } from "features/wip/index.interface";
import { CreateWipFooter, getWIPForm } from "features/wip/partials";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import styles from "../../../wip/index.module.scss";
import BrandPOCListModal from "../BrandPOCListModal";
import { AddBrandPOCModal } from "../BrandPOCListModal/partials";

const ConsultingWIPContactDetailsForm = ({ inModal, closeModal: closeEditModal }: WipFormProps) => {
  const [llModal, setLLModal] = useState({
    open: false,
    idx: -1,
  });
  const [editLLModal, setEditLLModal] = useState({
    open: false,
    idx: -1,
  });
  const [editBrandModal, setEditBrandModal] = useState({
    open: false,
    idx: -1,
  });
  const [deleteWIPModal, setDeleteWIPModal] = useState(false);

  const [contactsUUID, setContactsUUID] = useState<string[]>([]);
  const [contactFormContext] = useForm();
  const [brand, setBrand] = useState<IBrand | undefined>(undefined);
  const [contacts, setContacts] = useState<(ILandLord | undefined)[]>([]);
  const [initialValues, setInitialValues] = useState<ILandLord[]>([]);
  const [brandPOC, setBrandPOC] = useState<(IClientPOC | undefined)[]>([]);
  const [brandPOCUUID, setBrandPOCUUID] = useState<string[]>([]);
  const [brandModal, setBrandModal] = useState(false);
  const [brandPOCModal, setBrandPOCModal] = useState({ open: false, idx: -1 });
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [updateWip, { isLoading }] = useUpdateConsultingWipDetailsMutation();
  const { data: brandList } = useGetBrandListQuery();

  const [searchParams] = useSearchParams();

  const { errorToast, successToast } = useToastService();

  const { data: wip } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });

  const billingClient = wip?.data?.wip?.wipMetaData?.billingClient;
  const { data: clientPOCDetails } = useGetClientPOCListQuery(
    { clientType: "Brand" as string, clientId: brand?._id as string },
    {
      refetchOnMountOrArgChange: true,
      skip: isNullOrUndefiend(brand?._id),
    }
  );

  const deletePocNode = useCallback(
    (idx: number) => {
      const arr = [...contacts];
      arr.splice(idx, 1);
      setContacts(arr);

      const arr2 = [...contactsUUID];
      arr2.splice(idx, 1);
      setContactsUUID(arr2);
    },
    [contacts, contactsUUID]
  );
  const deleteBrandPocNode = useCallback(
    (idx: number) => {
      const arr = [...brandPOC];
      arr.splice(idx, 1);
      setBrandPOC(arr);

      const arr2 = [...brandPOCUUID];
      arr2.splice(idx, 1);
      setBrandPOCUUID(arr2);
    },
    [brandPOC, brandPOCUUID]
  );

  const resetBrandPoc = () => {
    setBrandPOC([undefined]);
    setBrandPOCUUID([uuidv4()]);
  };

  const handleSubmitBrandModal = useCallback(
    (value: CheckboxValueType) => {
      setBrandModal(false);
      const brand = brandList?.data?.brands?.find(({ brandName }) => brandName === value);
      contactFormContext.setFieldsValue({
        [FORM_FIELDS.BRAND_NAME]: brand?.brandName,
      });
      setBrand(brand);
      resetBrandPoc();
    },
    [brandList?.data?.brands, contactFormContext]
  );

  const SelectBrandModal = (
    <BrandListModal
      open={brandModal}
      onCancel={() => setBrandModal(false)}
      title={FORM_MODAL_STRING.SELECT_BRAND}
      submitText={FORM_MODAL_STRING.DONE}
      onSubmit={handleSubmitBrandModal}
      options={getBrandOptions(brandList?.data?.brands || [])}
      showSearch={true}
      placeholder={FORM_MODAL_STRING.SEARCH_FOR_BRAND}
      defaultValue={contactFormContext.getFieldValue(FORM_FIELDS.BRAND_NAME)}
    />
  );

  const SelectBrandPOCModal = (
    <BrandPOCListModal
      open={brandPOCModal?.open}
      onCancel={() => setBrandPOCModal({ idx: -1, open: false })}
      title={FORM_MODAL_STRING.SELECT_BRAND_POC}
      submitText={FORM_MODAL_STRING.DONE}
      onSubmit={(val) => handleSubmitPOCBrandModal(val as string, brandPOCModal?.idx)}
      options={getClientPOCModalOptions(clientPOCDetails?.data?.pocs) || []}
      showSearch={true}
      placeholder={FORM_MODAL_STRING.SEARCH_FOR_POC}
      type="Brand"
      clientId={brand?._id}
    />
  );

  const SelectBrand = {
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        initialValue: "",
        name: FORM_FIELDS.BRAND_NAME,
        rules: requiredRulesOnChange,
      },
      inputProps: {
        type: ArInputType.text,
        label: FORM_FIELDS.BRAND_NAME_LABEL,
        varient: varientType.default,
        required: true,
        readOnly: true,
        onClick: () => setBrandModal(true),
      },
    },
  };

  const SelectBrandPOC = (idx: number) => ({
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        initialValue: brandPOC?.[idx]?.name,
        name: FORM_FIELDS.BRAND_POC_LABEL + brandPOCUUID[idx],
        rules: requiredRulesOnChange,
      },
      inputProps: {
        type: ArInputType.text,
        label: FORM_FIELDS.BRAND_POC_LABEL,
        varient: varientType.default,
        required: true,
        readOnly: true,
        onClick: () => {
          if (!brand?._id) {
            errorToast("Select Brand first");
            return;
          }
          setBrandPOCModal({ open: true, idx });
        },
      },
    },
  });

  const LLNode = (idx: number) => {
    const LLNameNode = {
      type: nodeType.input,
      elementData: {
        inputType: inputType.text,
        formItemProps: {
          initialValue: initialValues?.[idx]?.name,
          name: FORM_FIELDS.LL + contactsUUID[idx],
          rules: requiredRulesOnChange,
        },
        inputProps: {
          type: ArInputType.text,
          label: FORM_FIELDS.LL,
          varient: varientType.default,
          required: true,
          readOnly: true,
          onClick: () => setLLModal({ open: true, idx }),
        },
      },
    };
    const llData = contacts[idx];

    if (llData?._id) {
      return [
        LLNameNode,
        StaticValueNode(FORM_FIELDS.LL_TYPE_LABEL, llData?.type || ""),
        StaticValueNode(FORM_FIELDS.EMAIL_LABEL, llData?.email),
        StaticValueNode(FORM_FIELDS.PHONE_NUMBER_LABEL, String(llData?.phoneNumber?.number)),
      ];
    } else {
      return [LLNameNode];
    }
  };

  const BrandPocNodes: ArFormContainerNode[] = Array.from({ length: brandPOC.length }, (_, idx) => {
    return {
      type: nodeType.container,
      elementData: {
        body: (
          <div className="display-flex-space-between">
            <p className="hero-text-medium py-16">
              {STRING_CONSTANTS.BRAND_POC} {STRING_CONSTANTS.CONTACT_DETAIL} {idx + 1}
            </p>
            <div>
              <ArButton
                type={ButtonTypes.Link}
                onClick={() => setEditBrandModal({ open: true, idx })}
                disabled={isNullOrUndefiend(brandPOC[idx])}
              >
                {STRING_CONSTANTS.EDIT}
              </ArButton>
              {"  "}|{"  "}
              <ArButton type={ButtonTypes.Link} onClick={() => deleteBrandPocNode(idx)} disabled={brandPOC?.length < 2}>
                {STRING_CONSTANTS.DELETE}
              </ArButton>
            </div>
          </div>
        ),
        innerContainerClassName: styles.reqInnerContainer,
      },
      childNode: {
        type: nodeType.array,
        childNode: brandPOC?.[idx]?._id
          ? [
              SelectBrandPOC(idx),
              StaticValueNode(FORM_FIELDS.EMAIL_LABEL, getValueOrDash(brandPOC?.[idx]?.email) as string),
              StaticValueNode(
                FORM_FIELDS.PHONE_NUMBER_LABEL,
                getValueOrDash(String(brandPOC?.[idx]?.phoneNumber?.number)) as string
              ),
            ]
          : [SelectBrandPOC(idx)],
      },
    };
  });
  const BrandDetailsNode = {
    type: nodeType.container,
    elementData: {
      body: (
        <div className="display-flex-space-between">
          <p className="hero-text-medium py-16">{STRING_CONSTANTS.BRAND_DETAILS}</p>
        </div>
      ),
      innerContainerClassName: styles.reqInnerContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        SelectBrand,
        {
          type: nodeType.container,
          elementData: {
            body: (
              <StaticValue
                label={FORM_FIELDS.BRAND_LEGAL_NAME}
                value={getValueOrDash(brand?.legalEntityName) as string}
              />
            ),
          },
        },
        {
          type: nodeType.container,
          elementData: {
            body: <StaticValue label={FORM_FIELDS.BRAND_CATEGORY} value={getValueOrDash(brand?.industry) as string} />,
          },
        },
      ],
    },
  };

  const contactPocNodes: ArFormContainerNode[] = Array.from({ length: contacts.length }, (_, idx) => {
    return {
      type: nodeType.container,
      elementData: {
        body: (
          <div className="display-flex-space-between">
            <p className="hero-text-medium py-16">
              {STRING_CONSTANTS.LANDLORD} {STRING_CONSTANTS.CONTACT_DETAIL} {idx + 1}
            </p>
            <div>
              <ArButton
                type={ButtonTypes.Link}
                onClick={() => setEditLLModal({ open: true, idx })}
                disabled={isNullOrUndefiend(contacts[idx])}
              >
                {STRING_CONSTANTS.EDIT}
              </ArButton>
              {"  "}|{"  "}
              <ArButton type={ButtonTypes.Link} onClick={() => deletePocNode(idx)} disabled={contacts?.length < 2}>
                {STRING_CONSTANTS.DELETE}
              </ArButton>
            </div>
          </div>
        ),
        innerContainerClassName: styles.reqInnerContainer,
      },
      childNode: {
        type: nodeType.array,
        childNode: [...LLNode(idx)],
      },
    };
  });
  const addMoreContacts = () => {
    if (contacts.length >= 5) {
      alert(STRING_CONSTANTS.ONLY_5_POC);
      return;
    }
    const arr = [...contacts];
    arr.push(undefined);
    setContacts(arr);

    const arr2 = [...contactsUUID];
    arr2.push(uuidv4());
    setContactsUUID(arr2);
  };

  const addMoreBrandPOC = () => {
    if (brandPOC.length >= 5) {
      alert(STRING_CONSTANTS.ONLY_5_POC);
      return;
    }
    const arr = [...brandPOC];
    arr.push(undefined);
    setBrandPOC(arr);

    const arr2 = [...brandPOCUUID];
    arr2.push(uuidv4());
    setBrandPOCUUID(arr2);
  };

  const AddButton: FormNode = {
    type: nodeType.input,
    elementData: {
      inputType: inputType.button,
      formItemProps: {
        name: STRING_CONSTANTS.ADD_MORE_LL,
      },
      inputProps: {
        type: ButtonTypes.Link,
        children: (
          <>
            <PlusSquareOutlined /> {STRING_CONSTANTS.ADD_MORE_LL}
          </>
        ),
        size: "large",
        disabled: contacts?.length >= 5,
        onClick: addMoreContacts,
        className: "pb-16",
      },
    },
  };
  const AddBrandPOCButton: FormNode = {
    type: nodeType.input,
    elementData: {
      inputType: inputType.button,
      formItemProps: {
        name: STRING_CONSTANTS.ADD_MORE_LL,
      },
      inputProps: {
        type: ButtonTypes.Link,
        children: (
          <>
            <PlusSquareOutlined /> {STRING_CONSTANTS.ADD_MORE_BRAND_POC}
          </>
        ),
        size: "large",
        disabled: brandPOC?.length >= 5,
        onClick: addMoreBrandPOC,
        className: "pb-16",
      },
    },
  };

  const BrandNodeLayout = {
    type: nodeType.container,
    elementData: {},
    childNode: {
      type: nodeType.array,
      childNode: [BrandDetailsNode, ...BrandPocNodes, AddBrandPOCButton],
    },
  };

  const FormLayout = {
    type: nodeType.container,
    elementData: {
      outerContainerClassName: styles.reqOuterContainer,
      innerContainerClassName: styles.inModalContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode:
        billingClient === STRING_CONSTANTS.BRAND
          ? [BrandNodeLayout]
          : billingClient === STRING_CONSTANTS.LANDLORD
          ? [...contactPocNodes, AddButton]
          : [...contactPocNodes, AddButton, BrandNodeLayout],
    },
  };

  const contactForm: FormNode = getWIPForm(
    [FormLayout as ArFormContainerNode],
    billingClient === STRING_CONSTANTS.BRAND
      ? STRING_CONSTANTS.BRAND_CONATCT_DETAIL
      : billingClient === STRING_CONSTANTS.LANDLORD
      ? STRING_CONSTANTS.LL_CONATCT_DETAIL
      : STRING_CONSTANTS.BOTH_CONATCT_DETAIL
  );

  const saveData = async () => {
    let res;

    try {
      res = await updateWip({
        projectId: projectId || "",
        // @ts-ignore
        landlord: { poc: isObjectNullOrUndefiendOrEmpty(contacts?.[0]) ? [] : contacts },
        brand: {
          // @ts-ignore
          poc: isObjectNullOrUndefiendOrEmpty(brandPOC?.[0]) ? [] : brandPOC,
          brandId: brand?._id,
        },
      }).unwrap();
    } catch (e) {
      console.error(e);
    }
    if (res?.success) {
      successToast(TOAST_MESSAGES.SAVED_POC_DATA);
      return true;
    } else {
      errorToast(TOAST_MESSAGES.ERROR_POC_DATA);
    }
    return false;
  };

  const runValidations = useCallback(async () => {
    contactFormContext?.validateFields();
  }, [contactFormContext]);

  const saveDataAndNavigate = () => {
    saveData();
    navigate({
      pathname: `${APP_ROUTES.WIP}/${APP_ROUTES.CONSULTING}${APP_ROUTES.CREATE_WIP}/${projectId}/${APP_ROUTES.WIP_COMMERCIAL_DETAILS}`,
      search: searchParams.get("error") ? "?error=true" : "",
    });
  };
  const runValidationsAndSaveData = async () => {
    let res;
    try {
      res = await contactFormContext?.validateFields();
    } catch (e) {
      console.error(e);
    }
    if (res) {
      const success = await saveData();
      if (success) {
        closeEditModal && closeEditModal();
      }
    }
  };

  const handleUpdateLL = (ll: ILandLord, idx: number) => {
    const arr = [...contacts];
    arr[idx] = ll;
    setContacts(arr);
    contactFormContext.setFieldsValue({
      [FORM_FIELDS.LL + contactsUUID[idx]]: ll?.name,
    });
    setLLModal({ idx: -1, open: false });
    setEditLLModal({ idx: -1, open: false });
  };

  const handleUpdateBrandPOC = (poc: IClientPOC, idx: number) => {
    const arr = [...brandPOC];
    arr[idx] = poc;
    setBrandPOC(arr);
    contactFormContext.setFieldsValue({
      [FORM_FIELDS.BRAND_POC_LABEL + brandPOCUUID[idx]]: poc?.name,
    });
    setBrandModal(false);
    setEditBrandModal({ idx: -1, open: false });
  };

  const handleSubmitPOCBrandModal = (val: string, idx: number) => {
    setBrandPOCModal({ idx: -1, open: false });
    const obj = getClientPOCObjFromArray(clientPOCDetails?.data?.pocs, val);
    const arr = [...brandPOC];
    arr[idx] = obj;
    setBrandPOC(arr);
    contactFormContext.setFieldsValue({
      [FORM_FIELDS.BRAND_POC_LABEL + brandPOCUUID[idx]]: obj?.name,
    });
  };
  useEffect(() => {
    if (wip?.data?.wip?.poc?.length) {
      setContacts(wip?.data?.wip?.poc || []);
      setContactsUUID(Array.from({ length: wip?.data?.wip?.poc?.length }, () => uuidv4()));
      setInitialValues([...(wip?.data?.wip?.poc || [])]);
    } else if (!wip?.data?.wip?.wipMetaData?.property?.shopId) {
      setContacts([undefined]);
      setContactsUUID([uuidv4()]);
    }
  }, [wip]);

  useEffect(() => {
    if (wip?.data?.wip?.brand?.poc?.length) {
      // @ts-ignore
      setBrandPOC(wip?.data?.wip?.brand?.poc || []);
      setBrandPOCUUID(Array.from({ length: wip?.data?.wip?.brand?.poc?.length }, () => uuidv4()));
    } else if (!wip?.data?.wip?.wipMetaData?.property?.shopId) {
      setBrandPOC([undefined]);
      setBrandPOCUUID([uuidv4()]);
    }
    if (wip?.data?.wip?.brand) {
      handleSubmitBrandModal(wip?.data?.wip?.brand?.brandName);
    }
  }, [wip, contactFormContext, handleSubmitBrandModal]);

  useEffect(() => {
    if (contacts?.length && initialValues?.length && searchParams.get("error")) {
      runValidations();
    }
  }, [runValidations, searchParams, contacts?.length, initialValues?.length]);

  return (
    <>
      <ArForm
        form={contactFormContext}
        formNode={contactForm}
        className={`${styles.formWrapper} ${inModal ? styles.modalStyles : undefined}`}
      />
      {SelectBrandModal}

      <LandLordListModal
        open={llModal.open}
        onCancel={() => setLLModal({ open: false, idx: -1 })}
        closeModal={() => setLLModal({ open: false, idx: -1 })}
        mallId={wip?.data?.wip?.wipMetaData?.property?.mall?._id}
        sendDataAndCloseModal={(ll) => handleUpdateLL(ll, llModal?.idx)}
      />
      <AddLLModal
        open={editLLModal?.open}
        onCancel={() => setEditLLModal({ open: false, idx: -1 })}
        closeModal={() => setEditLLModal({ open: false, idx: -1 })}
        mallId={wip?.data?.wip?.wipMetaData?.property?.mall?._id}
        editLLData={contacts[editLLModal?.idx]}
        sendDataAndCloseModal={(ll) => handleUpdateLL(ll, editLLModal?.idx)}
      />
      {SelectBrandPOCModal}
      <AddBrandPOCModal
        title={STRING_CONSTANTS.EDIT_BRAND_POC}
        open={editBrandModal?.open}
        onCancel={() => setEditBrandModal({ open: false, idx: -1 })}
        editBrandPOCData={brandPOC[editBrandModal?.idx]}
        clientId={brand?._id}
        type={"Brand"}
        sendDataAndCloseModal={(poc) => handleUpdateBrandPOC(poc, editBrandModal?.idx)}
      />
      {inModal ? (
        <CreateWipFooter
          nextButtonAction={runValidationsAndSaveData}
          nextButtonProps={{ loading: isLoading }}
          prevButtonAction={closeEditModal}
          nextButtonText={STRING_CONSTANTS.SAVE}
          prevButtonText={STRING_CONSTANTS.CANCEL}
          showMiddleButton={false}
        />
      ) : (
        <CreateWipFooter
          nextButtonAction={saveDataAndNavigate}
          middleButtonAction={saveData}
          middleButtonProps={{ loading: isLoading }}
          prevButtonAction={() => setDeleteWIPModal(true)}
        />
      )}
      <DeleteDraftWIPModal
        open={deleteWIPModal}
        onCancel={() => setDeleteWIPModal(false)}
        closeModal={() => setDeleteWIPModal(false)}
        projectId={wip?.data?.wip?.project?.id}
        wipType={"CONSULTING"}
        actionAfterDeletion={() => navigate(-1)}
        approval={wip?.data?.wip?.approval as IWIPApproval}
      />
    </>
  );
};

export default ConsultingWIPContactDetailsForm;
