import { isStringEmpty } from "app/utils/helperFunctions";
import { SHOP_URLS } from "constants/ApiUrls";
import { IBasicShopDetails, IFloorPlans, IMarketMap, ShopHistoryItem } from "constants/ShopStructure";

import { api, GenericResponse, paginationParams } from "./api";
import { IBrand } from "./createRequirmentApi";

export interface IShopListing extends IBasicShopDetails {
  isNewlyAdded?: boolean;
  marketMap?: IMarketMap;
}

export interface IShopHistoryQueryParams {
  params: paginationParams;
  shopId: string;
}

export interface IShopHistoryResponse {
  shopHistory: ShopHistoryItem[];
  brands: IBrand[];
  paginationParams: {
    count: number;
    previous: number;
    next: number;
  };
}

export interface IShopDetails extends Omit<IBasicShopDetails, "brands"> {
  marketMap: IMarketMap;
  latitude: string;
  longitude: string;
  floorPlans: IFloorPlans[];
}

export const shopDetailsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShopDetails: build.query<GenericResponse<{ shop: IShopDetails }>, string>({
      query: (shopId) => ({
        url: SHOP_URLS.SHOP + shopId,
        skip: isStringEmpty(shopId),
      }),
      providesTags: ["SelfUpdate"],
    }),
    getShopHistory: build.query<GenericResponse<IShopHistoryResponse>, IShopHistoryQueryParams>({
      query: ({ shopId, params }) => ({
        url: SHOP_URLS.SHOP + shopId + SHOP_URLS.HISTORY,
        params,
        skip: isStringEmpty(shopId),
      }),
      providesTags: ["SelfUpdate"],
    }),
  }),
});

export const { useGetShopDetailsQuery, useGetShopHistoryQuery } = shopDetailsApi;
