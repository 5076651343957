import { createSlice } from "@reduxjs/toolkit";
import { IMatchingShopDetail, matchingPropertiesApi } from "app/services/matchingPropertiesApi";
import { RootState } from "app/store";

export type moveToOptionList = {
  [shopId: string]: boolean;
};

interface state {
  moveToOptionList: moveToOptionList;
  matchingProperties: IMatchingShopDetail[];
}

const initialState: state = {
  moveToOptionList: {},
  matchingProperties: [],
};

const moveToOptionListSlice = createSlice({
  name: "moveToOptionList",
  initialState,
  reducers: {
    setMoveToOptionList: (state, action: { payload: string }) => {
      const isShopPresent = state.moveToOptionList[action.payload];
      if (isShopPresent) {
        delete state.moveToOptionList[action.payload];
      } else {
        state.moveToOptionList[action.payload] = true;
      }
    },
    clearMoveToOptionList: (state) => {
      state.moveToOptionList = {};
    },
    setMatchingProperties: (state, action: { payload: IMatchingShopDetail[] }) => {
      state.matchingProperties = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(matchingPropertiesApi.endpoints.createOptionList.matchFulfilled, (state) => {
      state.moveToOptionList = <moveToOptionList>{};
    });
  },
});

export const selectMoveToOptionList = (state: RootState) => state.matchingProperties.moveToOptionList;
export const selectMatchingProperties = (state: RootState) => state.matchingProperties.matchingProperties;
export const { setMoveToOptionList, clearMoveToOptionList, setMatchingProperties } = moveToOptionListSlice.actions;
export default moveToOptionListSlice.reducer;
