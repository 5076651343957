import { ArBaseContainer, ArRadio } from "@anarock/ar-common-react";
import { Spin } from "antd";
import { useGetLocalityTabFiltersQuery, useGetRequirementTabFiltersQuery } from "app/services/analyticsApi";
import { useGetOverviewFilteredShopsQuery } from "app/services/analyticsApi";
import { IFiltersList } from "app/services/listingPageApi";
import { useGetInfiniteScrollParams } from "app/utils/infiniteScrollHook";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { ArEmptyState } from "components/emptyState";
import { SegregatedShopsContainer } from "components/overview/index";
import { OverviewFilterProps } from "components/overview/index.interface";
import { OverviewFilters } from "components/overview/recentUpdateFilters";
import { GET_API_ERRORS } from "constants/getAPIErrors";
import { pageLimit } from "constants/pagination";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { SegregatedShopsComponentProps } from "./index.interface";
import styles from "./index.module.scss";
export const RecentUpdateHeader: React.FC = () => {
  const navigate = useNavigate();
  const pathSegments = useMemo(() => {
    return window.location.pathname.split("/").filter((segment) => segment !== "");
  }, [window.location]);
  useEffect(() => {
    if (!pathSegments?.[1]) {
      navigate(APP_ROUTES.OVERVIEW_REQUIREMENTS);
    }
  }, []);
  return (
    <div className={styles.header}>
      <h4>{STRING_CONSTANTS.RECENT_UPDATE}</h4>
      <div>
        <ArRadio.Group
          className={styles.radioNavigation}
          options={[
            { label: "Requirements", value: APP_ROUTES.OVERVIEW_REQUIREMENTS },
            { label: "Shops", value: APP_ROUTES.LOCALITY },
          ]}
          defaultValue={pathSegments[1] || APP_ROUTES.OVERVIEW_REQUIREMENTS}
          onChange={(e) => navigate(e as string)}
          optionType="button"
        />
      </div>
    </div>
  );
};

export const SegregatedShopsOnOverview: React.FC<SegregatedShopsComponentProps> = ({ filters, fetchingFilters }) => {
  const { pageNo, onScroll, resetPageNo } = useGetInfiniteScrollParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<IFiltersList>(JSON.parse(searchParams.get("filters") || "{}"));
  const {
    data: filteredShopsResponse,
    isError,
    isLoading,
    isFetching,
  } = useGetOverviewFilteredShopsQuery({
    selectedFilters: JSON.parse(searchParams.get("filters") || "{}"),
    params: {
      pageLimit: pageLimit,
      pageNo: pageNo,
    },
  });
  useQueryApiError(
    isError,
    isLoading,
    isFetching,
    filteredShopsResponse,
    GET_API_ERRORS.UNABLE_TO_FETCH_FILTERED_SHOPS
  );
  const filteredShops = filteredShopsResponse?.data?.shopsObjArr || [];
  const paginationParams = filteredShopsResponse?.data?.paginationParams;

  const handleFilterChange: OverviewFilterProps["onFilterChange"] = (value, title) => {
    setSelectedFilters((prevFilters) => {
      return {
        ...prevFilters,
        [title]: value as string | string[],
      };
    });
  };

  useEffect(() => {
    resetPageNo();
    navigate({ search: `?filters=${JSON.stringify(selectedFilters)}` });
  }, [selectedFilters]);

  const filterProps: OverviewFilterProps = {
    filters,
    onFilterChange: handleFilterChange,
  };
  return (
    <React.Fragment>
      {fetchingFilters ? <Spin /> : <OverviewFilters {...filterProps} />}
      <div className={styles.shopList} onScroll={paginationParams?.next ? onScroll : undefined}>
        {(!isFetching || pageNo > 1) && <SegregatedShopsContainer shops={filteredShops} />}
        {!isFetching && filteredShops?.length === 0 && <ArEmptyState />}
        {isFetching && <Spin />}
      </div>
    </React.Fragment>
  );
};

export const RequirementSegregatedShops = () => {
  const [searchParams] = useSearchParams();
  const {
    data: filtersResponse,
    isFetching,
    isError,
    isLoading,
  } = useGetRequirementTabFiltersQuery({
    selectedFilters: JSON.parse(searchParams.get("filters") || "{}"),
  });
  useQueryApiError(isError, isLoading, isFetching, filtersResponse, GET_API_ERRORS.UNABLE_TO_FETCH_FILTERS);
  return <SegregatedShopsOnOverview filters={filtersResponse?.data?.filters || []} fetchingFilters={isFetching} />;
};

export const LocalitySegregatedShops = () => {
  const [searchParams] = useSearchParams();
  const {
    data: filtersResponse,
    isFetching,
    isError,
    isLoading,
  } = useGetLocalityTabFiltersQuery({
    selectedFilters: JSON.parse(searchParams.get("filters") || "{}"),
  });
  useQueryApiError(isError, isLoading, isFetching, filtersResponse, GET_API_ERRORS.UNABLE_TO_FETCH_FILTERS);
  return <SegregatedShopsOnOverview filters={filtersResponse?.data?.filters || []} fetchingFilters={isFetching} />;
};

const RecentUpdate = () => {
  return (
    <ArBaseContainer className={styles.recentUpdate}>
      <RecentUpdateHeader />
      <div className={styles.shopsFiltersContainer}>
        <Outlet />
      </div>
    </ArBaseContainer>
  );
};

export default RecentUpdate;
