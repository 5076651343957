import "./index.css";

import * as Sentry from "@sentry/react";
import ErrorBoundary from "components/errorBoundary";
import { ToastProvider } from "components/toastService";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { store } from "./app/store";
// import reportWebVitals from "./reportWebVitals";
// import { makeServer } from "./mirage";

if (process.env.REACT_APP_ENV === "development") {
  console.log("Development mode -- ");
  // makeServer();
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "http:localhost:3000",
        "https://qa.admin.retail.anarock.com/",
        "https://admin.retail.anarock.com/",
      ],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.REACT_APP_ENV,
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 1.0, // Capture 100% of the transactions, reduce (10%) in production!
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 1.0, // Capture 100% of the transactions,  reduce (10%) in production!
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <ToastProvider>
            <App />
          </ToastProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
