import { createSlice } from "@reduxjs/toolkit";
import { IBrandRequirementDetailOptionList, IGetOptionListResponse, optionListApi } from "app/services/optionListApi";
import { RootState } from "app/store";
import { OPTION_LIST_SHOP_STATUS } from "constants/ListingEnums";

interface state {
  updateStatusOfShops: string[];
  optionList: IBrandRequirementDetailOptionList;
  brochureShops: string[];
}

export const initialOptionListState = {
  [OPTION_LIST_SHOP_STATUS.NEW]: [],
  [OPTION_LIST_SHOP_STATUS.IN_NEGOTIATION]: [],
  [OPTION_LIST_SHOP_STATUS.REJECTED]: [],
  [OPTION_LIST_SHOP_STATUS.SHARED]: [],
  [OPTION_LIST_SHOP_STATUS.SHORTLISTED]: [],
  [OPTION_LIST_SHOP_STATUS.VISIT_DONE]: [],
  [OPTION_LIST_SHOP_STATUS.DROPPED]: [],
  [OPTION_LIST_SHOP_STATUS.BILLED]: [],
  [OPTION_LIST_SHOP_STATUS.DRAFT_WIP_CREATED]: [],
  [OPTION_LIST_SHOP_STATUS.WIP_CREATED]: [],
};

const initialState: state = {
  optionList: initialOptionListState,
  updateStatusOfShops: [],
  brochureShops: [],
};

const updateStatusOfShops = createSlice({
  name: "updateStatusOfShops",
  initialState,
  reducers: {
    setOptionsList: (state, action: { payload: IBrandRequirementDetailOptionList }) => {
      state.optionList = action.payload;
    },
    setBrochureShops: (state, action: { payload: string }) => {
      const isShopPresent = state.brochureShops.includes(action.payload);
      if (isShopPresent) {
        state.brochureShops = state.brochureShops.filter((shopId) => shopId !== action.payload);
      } else {
        state.brochureShops.push(action.payload);
      }
    },
    setUpdateStatusOfShops: (state, action: { payload: string }) => {
      const isShopPresent = state.updateStatusOfShops.includes(action.payload);
      if (isShopPresent) {
        state.updateStatusOfShops = state.updateStatusOfShops.filter((shopId) => shopId !== action.payload);
      } else {
        state.updateStatusOfShops.push(action.payload);
      }
    },
    clearUpdateStatusOfShopsList: (state) => {
      state.updateStatusOfShops = [];
      state.brochureShops = [];
    },
    setUpdateStateList: (state, action: { payload: string[] }) => {
      state.updateStatusOfShops = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      optionListApi.endpoints.getOptionsList.matchFulfilled,
      (state, action: { payload: IGetOptionListResponse }) => {
        state.optionList = action.payload.data?.brandRequirementDetailOptionList || initialOptionListState;
      }
    );
  },
});

export const selectUpdateStatusOfShops = (state: RootState) => state.optionList.updateStatusOfShops;
export const selectOptionsList = (state: RootState) => state.optionList.optionList;
export const selectBrochureShops = (state: RootState) => state.optionList.brochureShops;
export const {
  setUpdateStatusOfShops,
  clearUpdateStatusOfShopsList,
  setOptionsList,
  setBrochureShops,
  setUpdateStateList,
} = updateStatusOfShops.actions;
export default updateStatusOfShops.reducer;
