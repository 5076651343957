import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type moveToOptionList = {
  [shopId: string]: boolean;
};

interface state {
  openModal: boolean;
  shopId: string;
}

const initialState: state = {
  openModal: false,
  shopId: "",
};

const addToOptionsListModalSlice = createSlice({
  name: "addToOptionsListModal",
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    setShopId: (state, action: { payload: string }) => {
      state.shopId = action.payload;
    },
  },
});

export const selectOpenModal = (state: RootState) => state.addToOptionsListModal.openModal;
export const selectShopId = (state: RootState) => state.addToOptionsListModal.shopId;
export const { setOpenModal, setShopId } = addToOptionsListModalSlice.actions;
export default addToOptionsListModalSlice.reducer;
