// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";

import { isNullOrUndefiend } from "./helperFunctions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const requestPermissionAndGetToken = async () => {
  try {
    return Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        })
          .then((currentToken) => {
            if (isNullOrUndefiend(currentToken)) {
              console.error("Failed to generate the app registration token.");
            }
            return currentToken;
          })
          .catch((err) => {
            console.error("An error occurred when requesting to receive the token.", err);
          });
      } else {
        console.error("User Permission Denied.");
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const deleteFcmToken = () => {
  try {
    deleteToken(messaging);
  } catch {
    console.error("error deleting token ");
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
export default firebaseApp;
