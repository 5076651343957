import { isArrayEmpty, isNullOrUndefiend } from "app/utils/helperFunctions";
import { DynamicApiUrlsInstance, OPTION_LIST_URLS } from "constants/ApiUrls";
import { BROCHURE_STATUS, OPTION_LIST_SHOP_STATUS } from "constants/ListingEnums";

import { IAnalyticsFilters } from "./analytics";
import { api, GenericResponse } from "./api";
import { IMatchingPropertyRequest, IMatchingShopDetail } from "./matchingPropertiesApi";
import { IPaginationParam } from "./notificationsApi";

export interface IOptionListShop {
  _id: string;
  brandRequirementDetailId: string;
  status: OPTION_LIST_SHOP_STATUS;
  shop: IMatchingShopDetail;
}

export interface IBrandRequirementDetailOptionList {
  [OPTION_LIST_SHOP_STATUS.NEW]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.IN_NEGOTIATION]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.REJECTED]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.SHARED]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.SHORTLISTED]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.VISIT_DONE]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.DRAFT_WIP_CREATED]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.BILLED]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.DROPPED]: IOptionListShop[];
  [OPTION_LIST_SHOP_STATUS.WIP_CREATED]: IOptionListShop[];
}

export type IGetOptionListResponse = GenericResponse<{
  brandRequirementDetailOptionList: IBrandRequirementDetailOptionList;
  paginationParams: IPaginationParam;
}>;

export type IUpdateOPTION_LIST_SHOP_STATUS = {
  status: OPTION_LIST_SHOP_STATUS;
  optionListIds: string[];
};

export interface IUpdateOptionListShop {
  _id: string;
  brandRequirementDetailId: string;
  shopId: string;
  status: OPTION_LIST_SHOP_STATUS;
}

export interface IBrochure {
  brandRequirementDetailId: string;
  shopIds: string[];
}

export interface IJobDoc {
  _id: string;
  status: BROCHURE_STATUS;
  flow: string;
  exportFileUrl: string;
}

export interface IZipRequest {
  shopId: string | undefined;
  files: string[];
}

export interface IExcelRequest {
  selectedFilters: IAnalyticsFilters;
  url: string;
}

export type IBrochureResponse = GenericResponse<{ jobId: string }>;

export type IJobResponse = GenericResponse<{ jobDoc: IJobDoc }>;

export type IUpdateOptionListShopResponse = GenericResponse<{
  brandRequirementDetailOptionList: IUpdateOptionListShop[];
}>;

export const optionListApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOptionsList: build.query<IGetOptionListResponse, IMatchingPropertyRequest>({
      query: ({ reqId, params }) => ({
        url: OPTION_LIST_URLS.BRAND_REQ + reqId + OPTION_LIST_URLS.GET_OPTION_LIST,
        params,
      }),
      providesTags: ["OptionList", "SelfUpdate"],
    }),
    deleteShopsFromOptionsList: build.mutation<IGetOptionListResponse, string[]>({
      query: (optionListIds) => ({
        url: OPTION_LIST_URLS.DELETE_OPTIONS,
        body: {
          optionListIds,
        },
        method: "DELETE",
      }),
      invalidatesTags: ["OptionList"],
    }),
    updateOptionListShopStatus: build.mutation<IUpdateOptionListShopResponse, IUpdateOPTION_LIST_SHOP_STATUS>({
      query: (body) => ({
        url: OPTION_LIST_URLS.UPDATE_STATUS_OF_SHOPS,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["OptionList", "RequirementDetails"],
    }),
    generateBrochure: build.mutation<IBrochureResponse, IBrochure>({
      query: ({ brandRequirementDetailId, shopIds }) => ({
        url: DynamicApiUrlsInstance.generateBrochure(brandRequirementDetailId),
        body: {
          shopIds,
        },
        method: "POST",
        skip: isArrayEmpty(shopIds),
      }),
    }),
    generateBrochureForShop: build.mutation<IBrochureResponse, string>({
      query: (shopId) => ({
        url: DynamicApiUrlsInstance.generateBrochureForShop(shopId),
        method: "POST",
        skip: isNullOrUndefiend(shopId),
      }),
    }),
    getJobStatus: build.query<IJobResponse, string>({
      query: (jobId) => ({
        url: DynamicApiUrlsInstance.getJobStatus(jobId),
        cache: "no-cache",
      }),
    }),
    generateZipForShop: build.mutation<IBrochureResponse, IZipRequest>({
      query: ({ shopId, files }) => ({
        url: DynamicApiUrlsInstance.generateZipForShop(shopId as string),
        method: "POST",
        skip: isNullOrUndefiend(shopId),
        body: { files },
      }),
    }),
    generateExcelForWip: build.mutation<IBrochureResponse, IExcelRequest>({
      query: ({ url, selectedFilters }) => ({
        url: url,
        method: "POST",
        skip: !selectedFilters || Object.keys(selectedFilters).length === 0,
        body: { selectedFilters },
      }),
    }),
  }),
});

export const {
  useGetOptionsListQuery,
  useDeleteShopsFromOptionsListMutation,
  useUpdateOptionListShopStatusMutation,
  useGenerateBrochureMutation,
  useGenerateBrochureForShopMutation,
  useGetJobStatusQuery,
  useGenerateZipForShopMutation,
  useGenerateExcelForWipMutation,
} = optionListApi;
