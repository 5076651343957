import { ArBaseContainer, PropertyListing, PropertyListingProps } from "@anarock/ar-common-react";
import { useAppDispatch } from "app/hooks";
import { IShopListing } from "app/services/shopListingApi";
import { transFormShopProps } from "app/utils/shopPropsTransformer";
import { setOpenModal, setShopId } from "components/appModals/addToOptionListSlice";
import { APP_ROUTES } from "constants/Routes";
import React from "react";

import styles from "./index.module.scss";
export const ShopCard: React.FC<{
  shop: IShopListing;
  isClickable?: boolean;
}> = ({ shop, isClickable = true }) => {
  const dispatch = useAppDispatch();
  const { shopImages, propertyDetails, currentStatus } = transFormShopProps(shop);
  const onClickAddProperty = () => {
    dispatch(setOpenModal(true));
    dispatch(setShopId(shop._id));
  };

  const onCardClick = () => {
    isClickable && window.open(APP_ROUTES.PROPERTIES + "/" + shop._id, "_blank");
  };

  const shopProps: PropertyListingProps = {
    onClickAddProperty,
    shopAdded: false,
    propertyImage: {
      shopImages,
    },
    propertyDetails,
    currentStatus,
    onCardClick,
  };
  return (
    <div className={styles.shopCard}>
      <PropertyListing {...shopProps} />
    </div>
  );
};

export const ShopList: React.FC<{ shops: IShopListing[] }> = ({ shops }) => {
  return (
    <ArBaseContainer className={styles.shopsWrapper}>
      {shops.map((shop, index) => (
        <ShopCard key={index} shop={shop} />
      ))}
    </ArBaseContainer>
  );
};
