import { BellOutlined } from "@ant-design/icons";
import { Badge, Spin } from "antd";
import { useAppSelector } from "app/hooks";
import { useGetNotificationsCountQuery, useGetNotificationsQuery } from "app/services/notificationsApi";
import { hideNotification, selectShowNotification } from "app/slice/toggleNotification";
import { useQueryApiError } from "app/utils/useQueryApiError";
import ArPopover from "components/appPopover";
import { ArEmptyState } from "components/emptyState";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import styles from "./index.module.scss";
import NotificationsContent from "./partials";

const Notifications: React.FC = () => {
  const { data: currentNotifications, isLoading, isError, isFetching, refetch } = useGetNotificationsQuery();
  useQueryApiError(isError, isLoading, isFetching, currentNotifications);

  const dispatch = useDispatch();

  const notifications = currentNotifications?.data?.notifications;
  const handleOpenChange = (visible: boolean) => {
    if (visible) {
      refetch();
    }
  };
  const ref = useRef<HTMLDivElement | null>(null);
  const { data: notificationsCount } = useGetNotificationsCountQuery(undefined, { refetchOnMountOrArgChange: true });

  const showNotificationState = useAppSelector(selectShowNotification);

  useEffect(() => {
    if (showNotificationState) {
      ref?.current?.click();
      dispatch(hideNotification());
    }
  }, [showNotificationState, dispatch]);

  const content = () => {
    return isLoading ? (
      <Spin />
    ) : notifications?.length ? (
      <NotificationsContent notifications={notifications || []} />
    ) : (
      <ArEmptyState />
    );
  };

  return (
    <ArPopover content={content} onOpenChange={handleOpenChange} trigger={"click"}>
      <div ref={ref}>
        <BellOutlined />
        <Badge
          count={notificationsCount?.data?.unreadNotificationsCount}
          size="small"
          className={styles.countBadge}
          style={{ borderColor: "transparent" }}
        />
      </div>
    </ArPopover>
  );
};

export default Notifications;
