import { ArTabs } from "@anarock/ar-common-react";
import useGetWipFlow from "app/utils/useGetWipFlow";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const tabOptions = [
  {
    key: APP_ROUTES.WIP_SUMMARY,
    label: STRING_CONSTANTS.WIP_SUMMARY,
  },
  {
    key: APP_ROUTES.WIP_DOCUMENTS,
    label: STRING_CONSTANTS.WIP_DOCUMENTS,
  },
  {
    key: APP_ROUTES.WIP_INVOICES,
    label: STRING_CONSTANTS.INVOICES,
  },
];
const WIPTabs = ({ color, showInvoices }: { color: string; showInvoices?: boolean }) => {
  const navigate = useNavigate();

  const userFlow = useGetWipFlow();

  const handleClick = (key: string) => {
    switch (key) {
      case APP_ROUTES.WIP_SUMMARY: {
        navigate(
          {
            pathname: APP_ROUTES.WIP_SUMMARY,
            search: userFlow ? `${STRING_CONSTANTS.USER_FLOW}=${STRING_CONSTANTS.ANALYTICS}` : undefined,
          },
          {
            replace: true,
          }
        );
        break;
      }
      case APP_ROUTES.WIP_DOCUMENTS: {
        navigate(
          {
            pathname: APP_ROUTES.WIP_DOCUMENTS,
            search: userFlow ? `${STRING_CONSTANTS.USER_FLOW}=${STRING_CONSTANTS.ANALYTICS}` : undefined,
          },
          {
            replace: true,
          }
        );
        break;
      }
      case APP_ROUTES.WIP_INVOICES: {
        navigate(
          {
            pathname: APP_ROUTES.WIP_INVOICES,
            search: userFlow ? `${STRING_CONSTANTS.USER_FLOW}=${STRING_CONSTANTS.ANALYTICS}` : undefined,
          },
          {
            replace: true,
          }
        );
        break;
      }

      default: {
        break;
      }
    }
  };
  const isConsulting = location?.pathname?.includes(APP_ROUTES.CONSULTING);

  return (
    <div>
      <ArTabs
        variant="anarock"
        items={showInvoices ? tabOptions : [tabOptions[0], tabOptions[1]]}
        activeKey={isConsulting ? location.pathname.split("/")[5] : location.pathname.split("/")[4]}
        onTabClick={handleClick}
        onChange={handleClick}
        primaryColor={color}
        style={{ width: "946px" }}
      ></ArTabs>
      <Outlet />
    </div>
  );
};

export default WIPTabs;
