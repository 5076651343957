import { ArImage } from "@anarock/ar-common-react";
import { IFilteredShops } from "app/services/analyticsApi";
import { IShopListing } from "app/services/shopListingApi";
import { gethelperTextByCountAndLabel } from "app/utils/helperFunctions";
import BrandLogoPlaceholder from "assets/images/brandLogoPlaceholder.png";
import location from "assets/images/location.png";
import { SHOP_STATUS } from "constants/ListingEnums";
import { ShopCard } from "features/properties/shopListing/partials";
import React from "react";

import { ISegregatedShopsHeaderProps } from "./index.interface";
import styles from "./index.module.scss";

export const SegregatedShopsHeader: React.FC<ISegregatedShopsHeaderProps> = ({ helperText, logo, name }) => {
  return (
    <div className={styles.shopsHeader}>
      {logo}
      <div>
        <h4>{name}</h4>
        <p>{helperText}</p>
      </div>
    </div>
  );
};

export const SegregatedShopList: React.FC<{ shops: IShopListing[] }> = ({ shops }) => {
  return (
    <div className={styles.shopList}>
      {shops?.map((shop, index) => (
        <ShopCard key={index} shop={shop} />
      ))}
    </div>
  );
};

export const SegregatedShopsEntity: React.FC<IFilteredShops> = ({
  brand,
  locality,
  expiredTagShopIds = [],
  newTagShopIds = [],
  updatedTagShopIds = [],
  shops,
}) => {
  const name = brand?.brandName || locality?.name || "";
  const logo = (
    <ArImage
      alt={brand?.brandName || ""}
      url={brand?.brandLogoUrl || (locality?.name ? location : "")}
      imageClassName={styles.brandLogo}
      placeholder={<img src={BrandLogoPlaceholder} />}
      placeholderClassName={styles.brandLogoPlaceholder}
    />
  );

  const helperText = gethelperTextByCountAndLabel([
    {
      label: SHOP_STATUS.NEW,
      value: newTagShopIds,
    },
    {
      label: SHOP_STATUS.UPDATED,
      value: updatedTagShopIds,
    },
    {
      label: SHOP_STATUS.EXPIRED,
      value: expiredTagShopIds,
    },
  ]);

  const headerProps = {
    helperText,
    logo,
    name,
  };

  return (
    <div>
      <SegregatedShopsHeader {...headerProps} />
      <SegregatedShopList shops={shops} />
    </div>
  );
};
