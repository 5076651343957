import { ArNotesProps, ExcitingFeaturesProps, RentDetailProps } from "@anarock/ar-common-react";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import { IMatchingShopDetail } from "app/services/matchingPropertiesApi";
import { IShopDetails, IShopListing } from "app/services/shopDetailsApi";
import AskToUpdateShops from "components/askToUpdateShop";
import ShopAction from "components/askToUpdateShop/shopAction";
import { ShopInfoProps } from "components/shopDetailComponents/basicImageInfoCard/index.interface";
import { SHOP_STATUS, UNITS } from "constants/ListingEnums";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { updateStatus } from "features/requirementListingFlow/matchingProperties/partials";
import React from "react";

import {
  getCompressedString,
  getRent,
  getShopHistoryString,
  getShopImages,
  getValueOrDash,
  integerToCommaString,
  toDate,
} from "./helperFunctions";

export const getPropertyDetails = (shop: IMatchingShopDetail | IShopListing | IShopDetails, draft: boolean = false) => {
  const { status, shopNumber, address, info, completedAt, draft: shopDraft, updatedAt, features } = shop;

  const floorAreaList =
    info?.floors?.map((floor) => floor?.type + " (" + integerToCommaString(floor?.area) + " " + UNITS.SQFT + ")") || [];
  const propertyDescription = {
    name: shopNumber || "-",
    address: address || "-",
    expectedRent: info?.expectedRent
      ? `${UNITS.SYMBOL_RUPEES}` + integerToCommaString(info?.expectedRent) + `${UNITS.PER_MONTH}`
      : "-",
    features: features || [],
  };
  const propertyFeatures = {
    floorsAreaDisplayText: getCompressedString(floorAreaList, 1) as string,
    floorsAreaTooltipText: floorAreaList?.join(", "),
    height: info?.height ? integerToCommaString(info?.height) + ` (${UNITS.FT})` : "-",
    frontage: info?.frontage ? integerToCommaString(info?.frontage) + ` (${UNITS.FT})` : "-",
    status: status?.state,
  };

  const propertyUpdateStatus = {
    name: shop?.surveyor?.name,
    updateStatus: updateStatus(status, completedAt, updatedAt, shopDraft),
    askToUpdateButton: (draft || shop?.status?.shopTag === SHOP_STATUS.EXPIRED) && (
      <AskToUpdateShops shop={shop} draft={draft} />
    ),
  };
  return { propertyDescription, propertyFeatures, propertyUpdateStatus };
};

export const transFormShopProps = (shop: IMatchingShopDetail | IShopListing, draft: boolean = false) => {
  const { images, marketMap } = shop;
  const shopImages = getShopImages(images, marketMap);

  const currentStatus =
    shop?.brands?.length > 0
      ? {
          brandList: shop?.brands?.map((brand) => ({
            src: brand?.brandLogoUrl,
            alt: brand?.brandName,
          })),
          shopState: getShopHistoryString(shop?.shopHistoryDoc),
        }
      : undefined;

  const propertyDetails = getPropertyDetails(shop, draft);

  return { shopImages, propertyDetails, currentStatus };
};

export const getShopDetailsProps = (shop: IMatchingShopDetail | IShopDetails | IShopListing) => {
  const {
    shopNumber,
    address,
    info,
    images: shopImages,
    status,
    surveyor,
    marketMap,
    completedAt,
    draft,
    updatedAt,
    propertyId,
    features,
  } = shop;

  const images = getShopImages(shopImages, marketMap);

  const shopStatus = {
    shopSurveyor: surveyor?.name,
    updateStatus: updateStatus(status, completedAt, updatedAt, draft),
    shopAction: <ShopAction shop={shop} />,
  };
  const floorAreaList =
    info?.floors?.map((item) => {
      return `${item.type} (${integerToCommaString(item.area)} ${UNITS.SQFT})`;
    }) || [];
  const basicShopDetails: ShopInfoProps = {
    images,
    address,
    shopNo: shopNumber,
    shopStatus,
    shopFeatures: [
      { label: FORM_FIELDS.STATUS, value: status?.state },
      { label: FORM_FIELDS.SHOP_TYPE_LABEL, value: info?.shopType },
      {
        label: FORM_FIELDS.FLOORS,
        value: getCompressedString(floorAreaList, 1) as string,
        name: floorAreaList.join(", "),
      },
      {
        label: STRING_CONSTANTS.DOOR_HEIGHT,
        value: integerToCommaString(info?.height) + ` (${UNITS.FT})`,
      },
      {
        label: FORM_FIELDS.FRONTAGE_LABEL,
        value: integerToCommaString(info?.frontage) + ` (${UNITS.FT})`,
      },
      {
        label: FORM_FIELDS.SHOP_ID,
        value: getValueOrDash(propertyId) as string,
        allowCopyToastMessage: TOAST_MESSAGES.COPIED_SHOP_ID,
      },
    ],
    features,
  };
  const rentdetails: RentDetailProps = {
    currentRent: getRent(info?.currentRent, UNITS.PER_MONTH),
    exclation: `${getValueOrDash(info?.escalation)}% in ${getValueOrDash(info?.escalationPeriod)} ${UNITS.MONTHS}`,
    expectedRent: getRent(info?.expectedRent, UNITS.PER_MONTH),
    securityAmount: `${getRent(info?.securityDeposit)} for ${getValueOrDash(info?.securityTenure)} ${UNITS.MONTHS}`,
  };

  const mainFeatures: string[] = [];
  if (info?.cornerProperty) {
    mainFeatures.push(STRING_CONSTANTS.CORNER_PROPERTY);
  }
  if (info?.parking) {
    mainFeatures.push(`${info?.parking} ${STRING_CONSTANTS.PARKING}`);
  }
  if (info?.leaseTerm) {
    mainFeatures.push(`${STRING_CONSTANTS.LEASE_TERM}: ${info?.leaseTerm} ${UNITS.YEARS}`);
  }

  const currentStatus: optionProps[] = [];
  if (info?.lastTenant) {
    currentStatus.push({
      label: STRING_CONSTANTS.LAST_TENANT,
      value: info?.lastTenant,
    });
  }
  if (info?.availableFrom) {
    currentStatus.push({
      label: STRING_CONSTANTS.AVAILABLE_FROM,
      value: toDate(info?.availableFrom),
    });
  }
  if (info?.shopVacantFrom) {
    currentStatus.push({
      label: STRING_CONSTANTS.SHOP_VACANT_FROM,
      value: toDate(info?.shopVacantFrom),
    });
  }
  if (info?.currentTenant) {
    currentStatus.push({
      label: STRING_CONSTANTS.CURRENT_TENANT,
      value: info?.currentTenant,
    });
  }

  const excitingFeatures: ExcitingFeaturesProps = {
    mainFeatures,
    currentStatus,
  };

  const noteCard: ArNotesProps = {
    heading: STRING_CONSTANTS.NOTES,
    content: info?.notes || "-",
  };
  return { basicShopDetails, rentdetails, excitingFeatures, noteCard };
};
