import { ArNotifications } from "@anarock/ar-common-react";
import React from "react";

import { ArNotificationContentProps } from "./index.interface";
import styles from "./index.module.scss";
import { useTransformNotifications } from "./transformNotificationsHook";
const NotificationsContent: React.FC<ArNotificationContentProps> = ({ notifications, onScroll }) => {
  const transformedNotificationProps = useTransformNotifications(notifications.filter(Boolean));
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      onScroll?.();
    }
  };
  return (
    <div className={styles.notificationsWrapper} onScroll={handleScroll}>
      <ArNotifications notifications={transformedNotificationProps} />
    </div>
  );
};

export default NotificationsContent;
