import { ArPostUpload, ArUploadDragger } from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import {
  useDeleteConsultingStatusModalDocumentMutation,
  useGetConsultingWipSignedUrlMutation,
  useUpdateConsultingDocumentToUrlMutation,
  useUploadConsultingDocumentToUrlMutation,
} from "app/services/consultingWip";
import { IDocumentUrl } from "app/services/wip";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React from "react";

import styles from "./index.module.scss";

const DocumentUpload = ({
  documentStructureId,
  allowedFileTypes,
  title,
  documentURLs,
  refetchHandler,
  notUploadedDocumentStructureIDArr,
  color = colors["primary-400"],
  projectId,
}: {
  documentStructureId: string;
  allowedFileTypes: string[];
  title: string;
  documentURLs: IDocumentUrl[];
  refetchHandler: () => void;
  notUploadedDocumentStructureIDArr: string[];
  color?: string;
  projectId: string;
}) => {
  const { successToast, errorToast } = useToastService();

  const [uploadDocument] = useUploadConsultingDocumentToUrlMutation();
  const [updateDocument] = useUpdateConsultingDocumentToUrlMutation();
  const [getSignedUrl] = useGetConsultingWipSignedUrlMutation();
  const [deleteDocument] = useDeleteConsultingStatusModalDocumentMutation();

  const handleUpload: (props: any) => void = async ({ file, onSuccess, onError }) => {
    if (isNullOrUndefiend(file)) {
      onError();
      return;
    } else {
      let signedUrlResponse;
      const payload = [
        {
          documentStructureId: documentStructureId,
          documentSideTag: "front",
          documentFileType: file.type,
        },
      ];
      try {
        const data = await getSignedUrl({
          projectId: projectId || "",
          body: payload,
        }).unwrap();
        signedUrlResponse = data;
      } catch {
        errorToast(TOAST_MESSAGES.FAILED_TO_GENERATE_URL);
      }
      if (signedUrlResponse?.success) {
        const uploadUrlObject = signedUrlResponse?.data?.documentURLs?.[0]?.uploadURL;
        const formData = new FormData();
        for (const key in uploadUrlObject?.fields) {
          formData.append(key, uploadUrlObject?.fields[key] || "");
        }
        formData.append("file", file);
        uploadDocument({
          url: uploadUrlObject?.url || "",
          body: formData,
        })
          .unwrap()
          .then(() => {
            onSuccess();
            const document = [
              {
                documentStructureId: documentStructureId,
                documentURLs: [
                  {
                    url: `${uploadUrlObject?.url}/${uploadUrlObject?.fields?.key}`,
                    alt: documentStructureId,
                    documentSideTag: "front",
                    key: uploadUrlObject?.fields?.key,
                  },
                ],
              },
            ];

            updateDocument({
              projectId: projectId || "",
              body: document,
            })
              .unwrap()
              .then((res) => {
                refetchHandler();
                successToast(TOAST_MESSAGES.DOCUMENT_UPLOADED_SUCCESSFULLY);
              })
              .catch((err) => {
                errorToast(err.message);
              });
          })
          .catch((err) => {
            errorToast(err.message);
            onError();
          });
      } else {
        errorToast(signedUrlResponse?.reason || TOAST_MESSAGES.FAILED_TO_GENERATE_URL);
        onError();
      }
    }
  };

  const deleteFile = async () => {
    const payload = {
      documentStructureId: documentStructureId,
    };
    const data: any = await deleteDocument({
      projectId: projectId || "",
      body: payload,
    });
    if (data?.data?.success) {
      refetchHandler();
      successToast(TOAST_MESSAGES.DOCUMENT_DELETED_SUCCESSFULLY);
    } else {
      errorToast(data?.data?.reason);
    }
  };

  return (
    <div className={styles.fullWidth}>
      {documentURLs?.length ? (
        <ArPostUpload title={title} color={color} showDeleteIcon={true} deleteFile={deleteFile} />
      ) : (
        <>
          {notUploadedDocumentStructureIDArr.includes(documentStructureId) && (
            <div className={styles.errorMessage}>{title} is not uploaded yet</div>
          )}
          <ArUploadDragger
            title={`${STRING_CONSTANTS.CLICK_TO_UPLOAD} ${title}`}
            customRequest={(options) => handleUpload(options)}
            maxCount={1}
            accept={allowedFileTypes?.join(", ")}
            multiple={false}
            color={color}
          />
        </>
      )}
    </div>
  );
};

export default DocumentUpload;
