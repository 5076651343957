import { ArImage, ArNotifications, NotificationProps } from "@anarock/ar-common-react";
import { INotification, useReadNotificationMutation } from "app/services/notificationsApi";
import { INotificationShops } from "app/services/notificationsApi";
import { isNullOrUndefiend, shortenRelativeTime } from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { NOTIFICATION_TYPE } from "constants/notifications";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.scss";

export const useReadNotification = () => {
  const [readNotificationApi] = useReadNotificationMutation();
  const { errorToast } = useToastService();
  const readNotification = async (notificationId: string, readRecipt: boolean) => {
    if (!readRecipt) {
      let readNotificationResponse;
      try {
        readNotificationResponse = await readNotificationApi(notificationId).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      const reason = readNotificationResponse?.reason;
      if (!isNullOrUndefiend(reason)) {
        errorToast(reason as string);
      }
    }
  };
  return readNotification;
};

export const useRedirect = () => {
  const navigate = useNavigate();
  const rediretToMatchignShops = (filters: string) => {
    navigate(`/requirement/matchingProperties?filters=${filters}`);
  };
  const rediretToShopListing = (filters: string) => {
    navigate(`/properties?filters=${filters}`);
  };
  const redirectToShopDetailsPage = (shopId: string) => {
    navigate(`/properties/${shopId}`);
  };
  return {
    rediretToMatchignShops,
    rediretToShopListing,
    redirectToShopDetailsPage,
  };
};

export const useGetOnclick = () => {
  const { rediretToMatchignShops, rediretToShopListing, redirectToShopDetailsPage } = useRedirect();
  const ReadNotification = useReadNotification();
  return (notification: INotification) => {
    const { category, redirect, custom } = notification;
    const shopId = custom?.shops?.[0]?._id || "";
    switch (category) {
      case NOTIFICATION_TYPE.SHOP_NO_LONGER_AVAILABLE:
        return () => {
          redirectToShopDetailsPage(shopId);
          ReadNotification(notification._id, notification.readReceipt);
        };
      case NOTIFICATION_TYPE.NEW_SHOPS_ADDED:
        return () => {
          rediretToShopListing(JSON.stringify(redirect?.filters || {}));
          ReadNotification(notification._id, notification.readReceipt);
        };
      case NOTIFICATION_TYPE.STATUS_UPDATED_FOR_REQUESTED_SHOP:
        return () => {
          redirectToShopDetailsPage(shopId);
          ReadNotification(notification._id, notification.readReceipt);
        };
      case NOTIFICATION_TYPE.BRAND_REQUIREMENT_DETAIL_GOT_NEW_MATCH:
        return () => {
          rediretToMatchignShops(JSON.stringify(redirect?.filters || {}));
          ReadNotification(notification._id, notification.readReceipt);
        };
      default:
        return () => ReadNotification(notification._id, notification.readReceipt);
    }
  };
};

const NotificationShop: React.FC<{
  shop: INotificationShops;
  readNotification: () => void;
}> = ({ shop, readNotification }) => {
  const navigate = useNavigate();
  return (
    <ArNotifications.NotificationShop
      title={shop.title}
      shopImage={{
        url: shop?.image?.url || "",
        alt: shop?.image?.alt,
        imageClassName: styles.notificationShop,
        placeholder: "",
        placeholderClassName: styles.notificationShopPlaceholder,
      }}
      description={shop?.description}
      onClick={() => {
        readNotification();
        navigate(`/properties/${shop?._id}`);
      }}
    />
  );
};

export const useGetNotificationShops = () => {
  const ReadNotification = useReadNotification();
  return (notification: INotification) => {
    const shops = notification?.custom?.shops || [];
    return shops?.map((shop, index) => (
      <NotificationShop
        shop={shop}
        key={index}
        readNotification={() => ReadNotification(notification._id, notification.readReceipt)}
      />
    ));
  };
};

export const useGetCustomNotificationProps = () => {
  const onClick = useGetOnclick();
  const getNotificationsShop = useGetNotificationShops();
  return (notification: INotification) => {
    const customProps = {
      notificationTitle: notification.title,
      children: getNotificationsShop(notification),
      onClick: onClick(notification),
    };
    return customProps;
  };
};

export const getStaticNotificationProps = (notification: INotification) => {
  const timeStamp = shortenRelativeTime(notification?.createdAt);
  const notificationProps = {
    timeStamp,
    readReciept: notification.readReceipt,
    contentClassName: styles.notificationContent,
    notificationClassName: styles.notification,
    notificationStatusLogo: (
      <ArImage
        alt={notification?.icon?.alt || ""}
        url={notification.icon?.url || ""}
        imageClassName={styles.notificationLogo}
        placeholder={""}
        placeholderClassName={styles.notificationLogoPlaceholder}
      />
    ),
  };
  return notificationProps;
};

export const useTransformNotifications: (notifications: INotification[]) => NotificationProps[] = (notifications) => {
  const getCustomProps = useGetCustomNotificationProps();
  const transformedNotifications = notifications.map((notification) => {
    const props: NotificationProps = {
      ...getStaticNotificationProps(notification),
      ...getCustomProps(notification),
    };
    return props;
  });
  return transformedNotifications;
};
