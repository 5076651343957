import {
  ArBrandModals,
  ArForm,
  ArFormInputNode,
  ArInputType,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { DeleteOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Rule } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { useGetConsultingWipDetailsQuery, useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import { useGetStaticEnumsQuery } from "app/services/enums";
import {
  convertEnumsToRadioOptions,
  convertEnumsValuesToArray,
  convertToRupee,
  getCurrentFinancialYear,
  isNullOrUndefiend,
} from "app/utils/helperFunctions";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { FORM_FIELDS, FORM_MODAL_STRING } from "constants/RequirementFrom";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { EmptyNode } from "features/invoices/components/formHelpers";
import { brokerageDayNode } from "features/wip/components/WIP-CommercialDetailsForm/partials";
import { WipCommercialFormProps } from "features/wip/index.interface";
import { CreateWipFooter, getWIPForm } from "features/wip/partials";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import styles from "../.../../../../wip/index.module.scss";
import { CaculatedBrokerageNode } from "./partials";

const ConsultingWIPCommercialDetailsForm = ({
  inModal,
  closeModal: closeEditModal,
  sendWipData,
}: WipCommercialFormProps) => {
  const [FYModal, setFYModal] = useState({
    open: false,
    idx: -1,
  });
  const [CommercialFormContext] = useForm();
  const [commercials, setCommercials] = useState<string[]>([]);
  const { projectId } = useParams();

  const [llAmount, setLLAmount] = useState(0);
  const [brandAmount, setBrandAmount] = useState(0);

  const [searchParams] = useSearchParams();

  const { data: enums } = useGetStaticEnumsQuery();

  const { errorToast, successToast } = useToastService();

  const [updateWip, { isLoading }] = useUpdateConsultingWipDetailsMutation();

  const { data: wip, isFetching: fetchingWipData } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });

  const navigate = useNavigate();
  const billingClient = wip?.data?.wip?.wipMetaData?.billingClient;

  const handleSubmitFYModal = (val: string) => {
    if (FYModal.idx < 0) {
      closeModal();
      return;
    }
    const key = FORM_FIELDS.FINANCIAL_YEAR + commercials?.[FYModal.idx];
    CommercialFormContext.setFieldValue([key], val);
    closeModal();
  };
  const closeModal = () => {
    setFYModal({ open: false, idx: -1 });
  };

  const getLastFyIdx = useCallback(
    (index: number) => {
      const key = FORM_FIELDS.FINANCIAL_YEAR + commercials[index];
      const value = CommercialFormContext.getFieldValue(key);
      const idx = convertEnumsValuesToArray(enums?.data?.financialYear).findIndex((item) => item === value);

      return idx + 1;
    },
    [CommercialFormContext, commercials, enums?.data?.financialYear]
  );

  const deleteFYNode = async (idx: number) => {
    const arr = [...commercials];
    arr.splice(idx, 1);
    await setCommercials(arr);
    onFormValueChange([], CommercialFormContext?.getFieldsValue());
  };

  const SelectFYModal = (
    <ArBrandModals.ArRadioOptionsModal
      open={FYModal.open}
      onCancel={closeModal}
      title={FORM_MODAL_STRING.SELECT_FY}
      submitText={FORM_MODAL_STRING.DONE}
      onSubmit={(val) => handleSubmitFYModal(val as string)}
      destroyOnClose
      options={convertEnumsToRadioOptions(enums?.data?.financialYear, FYModal.open ? getLastFyIdx(FYModal.idx - 1) : 0)}
      defaultValue={CommercialFormContext.getFieldValue(FORM_FIELDS.FINANCIAL_YEAR + commercials?.[FYModal.idx]) || ""}
      showSearch={false}
    />
  );

  const yearRule: Rule = ({ getFieldValue }) => ({
    validator() {
      const set = new Set(commercials?.map((item) => getFieldValue(FORM_FIELDS.FINANCIAL_YEAR + item)));
      if (set.size !== commercials?.length) {
        return Promise.reject(new Error("Duplicate Financial year."));
      }

      return Promise.resolve();
    },
  });

  const FinancialYear: (idx: number) => ArFormInputNode = (idx) => ({
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        name: FORM_FIELDS.FINANCIAL_YEAR + commercials[idx],
        rules: [...requiredRulesOnChange, yearRule],
        initialValue: wip?.data?.wip?.brokerageDetails?.[idx]?.fy
          ? wip?.data?.wip?.brokerageDetails?.[idx]?.fy
          : idx === 0
          ? getCurrentFinancialYear()
          : convertEnumsValuesToArray(enums?.data?.financialYear)?.[getLastFyIdx(idx - 1)],
      },
      inputProps: {
        type: ArInputType.text,
        label: FORM_FIELDS.FINANCIAL_YEAR,
        varient: varientType.default,
        required: true,
        readOnly: true,
        onClick: () => setFYModal({ open: true, idx }),
      },
    },
  });

  const FYNode: (idx: number) => FormNode = (idx) => ({
    type: nodeType.container,
    elementData: {
      body: <>{SelectFYModal}</>,
    },
    childNode: {
      type: nodeType.array,
      childNode: [FinancialYear(idx)],
    },
  });

  const commercialFYNodes: ArFormContainerNode[] = Array.from({ length: commercials.length }, (_, idx) => {
    return {
      type: nodeType.container,
      elementData: {
        innerContainerClassName: styles.fullCommercialContainer,
      },
      childNode: {
        type: nodeType.array,
        childNode: [
          {
            type: nodeType.container,
            elementData: {
              body: (
                <span className={styles.progressContainer}>
                  <span className={styles.progressBox}>{idx + 1}</span>
                </span>
              ),
            },
          },
          {
            type: nodeType.container,
            elementData: {
              innerContainerClassName: styles.commercialInnerContainer,
              outerContainerClassName: styles.commercialOuterConatiner,
            },
            childNode: {
              type: nodeType.array,
              childNode:
                billingClient === STRING_CONSTANTS.BRAND
                  ? [
                      FYNode(idx),
                      brokerageDayNode(
                        FORM_FIELDS.BRAND_BROKERAGE + commercials[idx],
                        FORM_FIELDS.BRAND_BROKERAGE,
                        wip?.data?.wip?.brokerageDetails?.[idx]?.brandBrokerage?.current
                      ),
                      EmptyNode,
                    ]
                  : billingClient === STRING_CONSTANTS.LANDLORD
                  ? [
                      FYNode(idx),
                      brokerageDayNode(
                        FORM_FIELDS.LL_BROKERAGE + commercials[idx],
                        FORM_FIELDS.LL_BROKERAGE,
                        wip?.data?.wip?.brokerageDetails?.[idx]?.shopBrokerage?.current
                      ),
                      EmptyNode,
                    ]
                  : [
                      FYNode(idx),
                      brokerageDayNode(
                        FORM_FIELDS.LL_BROKERAGE + commercials[idx],
                        FORM_FIELDS.LL_BROKERAGE,
                        wip?.data?.wip?.brokerageDetails?.[idx]?.shopBrokerage?.current
                      ),
                      brokerageDayNode(
                        FORM_FIELDS.BRAND_BROKERAGE + commercials[idx],
                        FORM_FIELDS.BRAND_BROKERAGE,
                        wip?.data?.wip?.brokerageDetails?.[idx]?.brandBrokerage?.current
                      ),
                    ],
            },
          },
          {
            type: nodeType.input,
            elementData: {
              inputType: inputType.button,
              inputProps: {
                type: ButtonTypes.Link,
                children: <DeleteOutlined onClick={() => deleteFYNode(idx)} />,
                className: styles.deleteFyBtn,
                disabled: commercials?.length === 1,
              },
            },
          },
        ],
      },
    };
  });

  const addMorecommercials = () => {
    if (commercials.length >= 5) {
      alert(STRING_CONSTANTS.ONLY_5_FY);
      return;
    }
    const arr = [...commercials];
    arr.push(uuidv4());
    setCommercials(arr);
  };

  const AddButton: FormNode = {
    type: nodeType.input,
    elementData: {
      inputType: inputType.button,
      formItemProps: {
        // name: STRING_CONSTANTS.ADD_commercial_BUTTON,
      },
      inputProps: {
        type: ButtonTypes.Link,
        children: (
          <>
            <PlusSquareOutlined /> {STRING_CONSTANTS.ADD_FY}
          </>
        ),
        size: "large",
        disabled: commercials?.length >= 5,
        onClick: addMorecommercials,
        className: "pb-16",
      },
    },
  };

  const YearFormLayout: ArFormContainerNode = {
    type: nodeType.container,
    elementData: {
      body: (
        <p className={`sub-hero-text ${styles.fyBrokerageHeading}`}>
          {STRING_CONSTANTS.FY_PROJECTION_1 +
            " " +
            convertToRupee(llAmount + brandAmount) +
            STRING_CONSTANTS.FY_PROJECTION_2}
        </p>
      ),
      innerContainerClassName: `${styles.inModalContainerCommercial}`,
    },
    childNode: {
      type: nodeType.array,
      childNode: [CaculatedBrokerageNode(brandAmount, llAmount, billingClient || ""), ...commercialFYNodes, AddButton],
    },
  };

  const FormLayout: ArFormContainerNode = {
    type: nodeType.container,
    elementData: {
      outerContainerClassName: `${styles.reqOuterContainer} pr-20`,
    },
    childNode: {
      type: nodeType.array,
      childNode: [YearFormLayout],
    },
  };

  const CommercialForm: FormNode = getWIPForm(
    [FormLayout as ArFormContainerNode],
    STRING_CONSTANTS.COMMERCIAL_DETAILS_STEP
  );

  const onFormValueChange = (_: Array<any>, allValues: Array<any>) => {
    let llAmount = 0;
    let brandAmount = 0;
    Object.keys(allValues).forEach((field) => {
      if (field.includes(FORM_FIELDS.LL_BROKERAGE)) {
        llAmount += Number(CommercialFormContext?.getFieldValue(field)) || 0;
      }
      if (field.includes(FORM_FIELDS.BRAND_BROKERAGE)) {
        brandAmount += Number(CommercialFormContext?.getFieldValue(field)) || 0;
      }
    });
    setLLAmount(llAmount);
    setBrandAmount(brandAmount);
  };
  const covertDataToWipObject = () => {
    const brokerageDetails = commercials?.map((val, idx) => {
      return {
        fy: CommercialFormContext.getFieldValue(FORM_FIELDS.FINANCIAL_YEAR + val),
        shopBrokerage: {
          current: CommercialFormContext.getFieldValue(FORM_FIELDS.LL_BROKERAGE + val),
        },
        brandBrokerage: {
          current: CommercialFormContext.getFieldValue(FORM_FIELDS.BRAND_BROKERAGE + val),
        },
        monthlyBrokerageDetails:
          CommercialFormContext.getFieldValue(FORM_FIELDS.FINANCIAL_YEAR + val) ===
          wip?.data?.wip?.brokerageDetails?.[idx]?.fy
            ? [
                ...(wip?.data?.wip?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.map((item) => {
                  const shopBrokerage = {
                    current: item?.shopBrokerage?.current,
                  };
                  const brandBrokerage = {
                    current: item?.brandBrokerage?.current,
                  };
                  return {
                    ...item,
                    shopBrokerage,
                    brandBrokerage,
                  };
                }) || []),
              ]
            : [],
      };
    });
    return {
      projectId: projectId || "",

      brokerageDetails,
    };
  };

  const saveData = async () => {
    const wipObject = covertDataToWipObject();
    let res;
    try {
      res = await updateWip(wipObject).unwrap();
    } catch (e) {
      console.error(e);
    }
    if (res?.success) {
      successToast(TOAST_MESSAGES.SAVED_COMMERCIAL_DATA);
      return true;
    } else {
      errorToast(TOAST_MESSAGES.ERROR_COMMERCIAL_DATA);
    }
    return false;
  };

  const saveAndNavigate = () => {
    ``;
    saveData();
    navigate({
      pathname: `${APP_ROUTES.WIP}/${APP_ROUTES.CONSULTING}${APP_ROUTES.CREATE_WIP}/${projectId}/${APP_ROUTES.WIP_BROKERAGE_DETAILS}`,
      search: searchParams.get("error") ? "?error=true" : "",
    });
  };

  const runValidations = useCallback(() => {
    CommercialFormContext?.validateFields();
  }, [CommercialFormContext]);

  const runValidationsAndPassWipData = async () => {
    let res;
    try {
      res = await CommercialFormContext?.validateFields();
    } catch (e) {
      console.error(e);
    }
    if (res) {
      sendWipData && sendWipData(covertDataToWipObject());
    }
  };

  useEffect(() => {
    if (wip?.success && wip?.data?.wip?.brokerageDetails) {
      setCommercials(Array.from({ length: wip?.data?.wip?.brokerageDetails?.length || 1 }, () => uuidv4()));
      let llAmount = 0;
      let brandAmount = 0;
      wip?.data?.wip?.brokerageDetails?.forEach((item) => {
        llAmount += Number(item?.shopBrokerage?.current);
        brandAmount += Number(item?.brandBrokerage?.current || 0);
      });
      setLLAmount(llAmount);
      setBrandAmount(brandAmount);
    } else {
      setCommercials([uuidv4()]);
    }
  }, [wip]);

  useEffect(() => {
    if (commercials?.length && searchParams.get("error")) {
      runValidations();
    }
  }, [runValidations, searchParams, commercials?.length]);

  return (
    <>
      {fetchingWipData ? (
        <Spin tip={STRING_CONSTANTS.LOADING_PLEASE_WAIT} size="large" />
      ) : (
        <>
          <ArForm
            form={CommercialFormContext}
            formNode={CommercialForm}
            className={`${styles.formWrapper} ${inModal ? styles.modalStyles : undefined}`}
            onValuesChange={onFormValueChange}
          />
          {inModal ? (
            <CreateWipFooter
              nextButtonAction={runValidationsAndPassWipData}
              nextButtonProps={{ loading: isLoading }}
              prevButtonAction={closeEditModal}
              nextButtonText={STRING_CONSTANTS.SAVE}
              prevButtonText={STRING_CONSTANTS.CANCEL}
              showMiddleButton={false}
            />
          ) : (
            <CreateWipFooter
              prevButtonText={STRING_CONSTANTS.PREVIOUS_STEP}
              middleButtonAction={saveData}
              nextButtonAction={saveAndNavigate}
              middleButtonProps={{ loading: isLoading }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConsultingWIPCommercialDetailsForm;
