import {
  ArBaseContainer,
  ArButton,
  ArForm,
  ArInputType,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { InputRef } from "antd";
import { Rule } from "antd/es/form";
import { useForm, useWatch } from "antd/es/form/Form";
import { useForgotPasswordMutation } from "app/services/auth";
import useQuery from "app/utils/useQueryHook";
import { useToastService } from "components/toastService";
import { EMAIL_ID_VALIDATION } from "constants/ErrorMessage";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "../login/index.module.scss";
import localStyles from "./index.module.scss";

const ForgotPassword: React.FC = () => {
  const { errorToast, successToast } = useToastService();
  const [successScreen, setSuccessScreen] = useState<boolean>(false);
  const [emailContext] = useForm();
  const email = useWatch("email", emailContext);
  const [timer, setTimer] = useState(0);

  const navigate = useNavigate();
  const params = useQuery();

  const defaultEmail = params.get("email") || "";

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const ref = useRef<InputRef>(null);

  const initiateForgotPassword = async (email: string) => {
    let res;
    try {
      res = await forgotPassword({ email }).unwrap();
      setTimer(20);
    } catch {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    return res;
  };

  const resendLink = async () => {
    const res = await initiateForgotPassword(email);
    if (res?.success) {
      successToast(STRING_CONSTANTS.RESENT_SUCCESS);
    }
  };

  useEffect(() => {
    successScreen && ref.current?.focus();
  }, [successScreen]);

  const emailRules: Rule[] = [
    { required: true, message: EMAIL_ID_VALIDATION },
    {
      type: "email",
      message: EMAIL_ID_VALIDATION,
      validateTrigger: "onBlur",
    },
    {
      validator: async (_, name: string) => {
        const res = await initiateForgotPassword(name);

        if (!res?.success) {
          return Promise.reject(res?.reason);
        }
      },
      validateTrigger: "onSubmit",
    },
  ];

  const returnToLogin = () => {
    navigate({
      pathname: APP_ROUTES.LOGIN,
      search: `?email=${email}`,
    });
  };

  const EmailForm: FormNode = {
    type: nodeType["container"],
    elementData: {
      title: STRING_CONSTANTS.RESET_PASSWORD,
      body: <h4>{STRING_CONSTANTS.NO_FEAR}</h4>,
      outerContainerClassName: `${styles.headings} ${successScreen && styles.hide}`,
    },
    childNode: {
      type: nodeType["array"],
      childNode: [
        {
          type: nodeType["input"],
          elementData: {
            inputType: inputType["text"],
            inputProps: {
              type: ArInputType.email,
              label: "Email",
              autoFocus: true,
              required: true,
              varient: varientType.default,
            },
            formItemProps: {
              rules: emailRules,
              name: "email",
              initialValue: defaultEmail,
            },
          },
        },
        {
          type: nodeType["input"],
          elementData: {
            inputType: inputType["button"],
            inputProps: {
              type: ButtonTypes["Primary"],
              size: "middle",
              htmlType: "submit",
              children: STRING_CONSTANTS.CONFIRM,
              loading: isLoading,
              disabled: !email,
              className: styles.submitButton,
            },
          },
        },
        {
          type: nodeType["container"],
          elementData: {
            outerContainerClassName: styles.btnContainer,
          },
          childNode: {
            type: nodeType["input"],
            elementData: {
              inputType: inputType["button"],
              inputProps: {
                type: ButtonTypes["Link"],
                size: "middle",
                children: STRING_CONSTANTS.BACK_TO_LOGIN,
                className: styles.resetButton,
                onClick: returnToLogin,
              },
              outerContainerClassName: styles.btnContainer,
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (timer >= 1) {
      const id = setInterval(() => setTimer(timer - 1), 1000);
      return () => clearInterval(id);
    }
  }, [timer]);
  return (
    <ArBaseContainer className={styles.loginFormWrapper}>
      <ArForm onFinish={() => setSuccessScreen(true)} formNode={EmailForm} formInstance={emailContext}></ArForm>
      <div className={`${styles.headings} ${localStyles.forgotSuccess} ${!successScreen && styles.hide}`}>
        <h2>{STRING_CONSTANTS.RESET_SENT}</h2>
        <h4>
          {STRING_CONSTANTS.LINK_SENT_1} <b>{email}</b> {STRING_CONSTANTS.LINK_SENT_2}
        </h4>
        <p>
          {STRING_CONSTANTS.DIDNT_RECIEVE}{" "}
          <ArButton
            type={ButtonTypes.Link}
            disabled={timer !== 0}
            onClick={resendLink}
            loading={isLoading}
            className={localStyles.resendButton}
          >
            {" "}
            {STRING_CONSTANTS.RESEND_LINK_BTN} {timer !== 0 && `(${timer})`}
          </ArButton>
        </p>
        <ArButton onClick={returnToLogin} className={styles.submitButton}>
          {STRING_CONSTANTS.RETURN_TO_LOGIN}
        </ArButton>
      </div>
    </ArBaseContainer>
  );
};

export default ForgotPassword;
