import { DynamicApiUrlsInstance, NOTIFICATION_URLS } from "constants/ApiUrls";
import { NOTIFICATION_TYPE } from "constants/notifications";

import { api, GenericResponse } from "./api";

export interface IImage {
  url?: string;
  alt: string;
  key?: string;
}

export interface IPaginationParam {
  count: number;
  previous: number | null;
  next: number | null;
}

export interface INotificationShops {
  _id: string;
  title: string;
  description: string;
  image: IImage;
}

export interface INotificationBrands {
  _id: string;
  title: string;
  description: string;
  image: IImage;
  brandRequirementIds: string[];
}

export interface ICustom {
  shops?: INotificationShops[];
  brands?: INotificationBrands[];
}

export interface INotification {
  _id: string;
  category: NOTIFICATION_TYPE;
  title: string;
  description?: string;
  redirect?: {
    [id: string]:
      | string
      | {
          [id: string]: string;
        };
  };
  icon?: IImage;
  custom?: ICustom;
  readReceipt: false;
  readTime: string;
  createdAt: string;
}

export interface INotifications {
  notifications: INotification[];
  paginationParam: IPaginationParam;
}

export type INotificationsResponse = GenericResponse<INotifications>;
export type INotificationCountResponse = GenericResponse<{
  unreadNotificationsCount: number;
}>;

export type IRegisterTokenResponse = GenericResponse<{ data: null }>;

export const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readNotification: builder.mutation<GenericResponse, string>({
      query: (id) => ({
        url: DynamicApiUrlsInstance.markNotificationAsRead(id),
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    getNotifications: builder.query<INotificationsResponse, void>({
      query: () => ({
        url: NOTIFICATION_URLS.NOTIFICATIONS,
        cache: "no-cache",
      }),
      providesTags: ["Notifications"],
    }),
    requestProperties: builder.mutation<GenericResponse, string>({
      query: (brandRequirementDetailId) => ({
        url: DynamicApiUrlsInstance.requestProperties(brandRequirementDetailId),
        method: "POST",
      }),
    }),
    getNotificationsCount: builder.query<INotificationCountResponse, void>({
      query: () => ({
        url: "/notifications/count",
      }),
      providesTags: ["Notifications"],
    }),
    registerToken: builder.mutation<IRegisterTokenResponse, string>({
      query: (token) => ({
        url: "/auth/firebase-tokens",
        method: "POST",
        body: { firebaseRegistrationTokens: [{ token, deviceType: "WEB" }] },
      }),
    }),
  }),
});

export const {
  useReadNotificationMutation,
  useGetNotificationsQuery,
  useRequestPropertiesMutation,
  useGetNotificationsCountQuery,
  useRegisterTokenMutation,
} = notificationApi;
export const notificationApiReducer = notificationApi.reducer;
