import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type BrochureState = {
  jobId: string;
};

const initialState: BrochureState = {
  jobId: "no-job",
};

const brochureSlice = createSlice({
  name: "brochure",
  initialState,
  reducers: {
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
  },
});

export const selectJobId = (state: RootState) => state.brochure.jobId;
export const { setJobId } = brochureSlice.actions;
export default brochureSlice.reducer;
