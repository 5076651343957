export enum ANALYTICS_STATUS_MAP {
  NEW = "Option lists created",
  SHARED = "Option lists shared",
  SHORTLISTED = "Shortlisted",
}

export const quarterOptions = [
  { value: "1", label: "Quarter 1" },
  { value: "2", label: "Quarter 2" },
  { value: "3", label: "Quarter 3" },
  { value: "4", label: "Quarter 4" },
];

export enum OVERVIEW_ENUMS {
  FILTERS = "Filters",
}
