import { IFilteredShops } from "app/services/analyticsApi";
import React from "react";

import { SegregatedShopsEntity } from "./partials";

export const SegregatedShopsContainer: React.FC<{
  shops: IFilteredShops[];
}> = ({ shops }) => {
  return (
    <React.Fragment>
      {shops?.map((shop, index) => (
        <SegregatedShopsEntity key={index} {...shop} />
      ))}
    </React.Fragment>
  );
};
