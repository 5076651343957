import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import addToOptionsListModalSliceReducer from "components/appModals/addToOptionListSlice";
import authSliceReducer from "features/authentication/authSlice";
import moveToOptionListSliceReducer from "features/requirementListingFlow/matchingProperties/createOptionListSlice";
import updateShopStatusSliceReducer from "features/requirementListingFlow/optionsList/optionlistSlice";
import allRequirementSliceReducer from "features/requirementListingFlow/requiremnetListing/requirementSlice";

import { api, handleErrorWithToast } from "./services/api";
import brochureSliceReducer from "./slice/brochureSlice";
import contactModalSliceReducer from "./slice/contactModalSlice";
import selfUpdateSliceReducer from "./slice/selfUpdateSlice";
import toggleNotificationReducer from "./slice/toggleNotification";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authSliceReducer,
    requirements: allRequirementSliceReducer,
    matchingProperties: moveToOptionListSliceReducer,
    optionList: updateShopStatusSliceReducer,
    addToOptionsListModal: addToOptionsListModalSliceReducer,
    brochure: brochureSliceReducer,
    contactModal: contactModalSliceReducer,
    selfUpdateModal: selfUpdateSliceReducer,
    toggleNotifictaion: toggleNotificationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, handleErrorWithToast),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
// setupListeners(store.dispatch);
