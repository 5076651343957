import {
  ArBrandModals,
  ArForm,
  ArFormInputNode,
  ArInputType,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { DeleteOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Rule } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { useGetConsultingWipDetailsQuery, useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import {
  getCurrentFinancialYear,
  getCurrentMonthIdxForFy,
  getFixedDigit,
  isNullOrUndefiend,
} from "app/utils/helperFunctions";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { FYMonthsArr } from "constants/months";
import { FORM_FIELDS, FORM_MODAL_STRING } from "constants/RequirementFrom";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { EmptyNode } from "features/invoices/components/formHelpers";
import { brokerageDayNode } from "features/wip/components/WIP-CommercialDetailsForm/partials";
import { WipBrokerageFormProps } from "features/wip/index.interface";
import { CreateWipFooter, getWIPForm } from "features/wip/partials";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import styles from "../../../wip/index.module.scss";
import { FYDetailsNode } from "./partials";

export interface IBrokerageState {
  fy: string;
  shopBrokerage: number;
  brandBrokerage: number;
  monthlyBrokerage: string[];
  showDetails: boolean;
}
export interface IMonthModalState {
  open: boolean;
  key?: string;
}

const ConsultingWIPBrokerageDetailsForm = ({
  inModal,
  closeModal: closeEditModal,
  temporaryWipData,
}: WipBrokerageFormProps) => {
  const [MonthModal, setMonthModal] = useState<IMonthModalState>({
    open: false,
    key: undefined,
  });
  const [BrokerageFormContext] = useForm();
  const [brokerage, setBrokerage] = useState<IBrokerageState[]>([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();

  const { errorToast, successToast } = useToastService();
  const [updateWip, { isLoading }] = useUpdateConsultingWipDetailsMutation();

  const { data: wip, isFetching: isFetchingWipData } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });

  const billingClient = wip?.data?.wip?.wipMetaData?.billingClient;

  const handleSubmitMonthModal = (val: string) => {
    if (!MonthModal.key) {
      closeModal();
      return;
    }
    const key = FORM_FIELDS.MONTH + MonthModal.key;
    BrokerageFormContext.setFieldValue([key], val);
    closeModal();
  };
  const closeModal = () => {
    setMonthModal({ open: false, key: undefined });
  };
  const deleteFYNode = async (idx: number, monthIdx: number) => {
    const arr = [...brokerage];
    arr?.[idx]?.monthlyBrokerage?.splice(monthIdx, 1);
    await setBrokerage(arr);
    onFormValueChange([], BrokerageFormContext?.getFieldsValue());
  };

  const getMonthIdxFromKey = useCallback(
    (formKey: string) => {
      const value = BrokerageFormContext.getFieldValue(formKey);
      const monthIdx = FYMonthsArr.findIndex((val) => val == value);
      return monthIdx;
    },
    [BrokerageFormContext]
  );

  const getLastMonthIdx = useCallback(
    (key: string) => {
      let idx = -1;
      let formKey = "";
      // let yearIdx = -1;
      for (let i = 0; i < brokerage?.length; i++) {
        const year = brokerage[i];
        idx = year?.monthlyBrokerage?.findIndex((value) => value === key);
        if (idx > -1) {
          formKey = FORM_FIELDS.MONTH + year?.monthlyBrokerage[idx - 1];
          // yearIdx = i;
          break;
        }
      }

      //If we are in current FY we will stop allowing selection of prev months
      // if (brokerage?.[yearIdx]?.fy === getCurrentFinancialYear() && idx === 0) {
      //   return getCurrentMonthIdxForFy();
      // }

      const monthIdx = getMonthIdxFromKey(formKey);
      //if this is first month of new FY
      if (idx === 0) {
        return 0;
      }

      return monthIdx < 0 ? 12 : monthIdx + 1;
    },
    [brokerage, getMonthIdxFromKey]
  );

  const getLastMonthIdxFromIdx = useCallback(
    (idx: number, monthIdx: number) => {
      //If we are in current FY we will render that month
      if (brokerage?.[idx]?.fy === getCurrentFinancialYear() && monthIdx === 0) {
        return getCurrentMonthIdxForFy();
      }

      const key = FORM_FIELDS.MONTH + brokerage?.[idx]?.monthlyBrokerage?.[monthIdx - 1];

      const month = getMonthIdxFromKey(key);

      //if this is first month of new FY
      if (monthIdx === 0) {
        return 0;
      }

      return month < 0 ? 12 : month + 1;
    },
    [brokerage, getMonthIdxFromKey]
  );

  const SelectMonthModal = (
    <ArBrandModals.ArRadioOptionsModal
      open={MonthModal.open}
      onCancel={closeModal}
      title={FORM_MODAL_STRING.SELECT_MONTH}
      submitText={FORM_MODAL_STRING.DONE}
      onSubmit={(val) => handleSubmitMonthModal(val as string)}
      destroyOnClose
      options={FYMonthsArr.map((value) => {
        return {
          value,
          label: value,
        };
      }).slice(getLastMonthIdx(MonthModal?.key || ""))}
      defaultValue={BrokerageFormContext.getFieldValue(FORM_FIELDS.MONTH + MonthModal.key) || ""}
      showSearch={false}
    />
  );

  const Month: (index: number, monthIndex: number) => ArFormInputNode = (index, monthIndex) => ({
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        name: FORM_FIELDS.MONTH + brokerage?.[index]?.monthlyBrokerage?.[monthIndex],
        rules: [...requiredRulesOnChange, MonthRule],
        initialValue:
          wip?.data?.wip?.brokerageDetails?.[index]?.monthlyBrokerageDetails?.[monthIndex]?.month ||
          FYMonthsArr?.[getLastMonthIdxFromIdx(index, monthIndex)],
      },
      inputProps: {
        type: ArInputType.text,
        label: FORM_FIELDS.MONTH,
        varient: varientType.default,
        required: true,
        readOnly: true,
        onClick: () =>
          setMonthModal({
            open: true,
            key: brokerage?.[index]?.monthlyBrokerage?.[monthIndex],
          }),
      },
    },
  });

  const FYNode: (index: number, monthIndex: number) => FormNode = (index, monthIndex) => ({
    type: nodeType.container,
    elementData: {
      body: <>{SelectMonthModal}</>,
    },
    childNode: {
      type: nodeType.array,
      childNode: [Month(index, monthIndex)],
    },
  });

  const BrokerageDayRule: Rule = ({ getFieldValue }) => ({
    validator(rule, value) {
      //@ts-ignore
      const field = rule?.field;
      let brokerageField: string;

      if (field?.length === 48) {
        //its a ll brokerage
        brokerageField = FORM_FIELDS.LL_BROKERAGE;
      } else {
        // /its a brand brokerage
        brokerageField = FORM_FIELDS.BRAND_BROKERAGE;
      }
      const idx = brokerage?.findIndex((year) => year?.monthlyBrokerage?.includes(field?.slice(-36)));
      const addedValue = brokerage?.[idx]?.monthlyBrokerage?.reduce(
        (acc, curr) =>
          getFieldValue(brokerageField + curr) ? acc + Number(getFieldValue(brokerageField + curr)) : acc,
        0
      );

      if (!value && value != 0) {
        return Promise.reject();
      }

      if (
        brokerageField === FORM_FIELDS.BRAND_BROKERAGE &&
        addedValue !== getFixedDigit(brokerage?.[idx]?.brandBrokerage)
      ) {
        return Promise.reject(new Error("Yearly Brokerage should be equal to combined monthly brokerage"));
      }
      if (
        brokerageField === FORM_FIELDS.LL_BROKERAGE &&
        addedValue !== getFixedDigit(Number(brokerage?.[idx]?.shopBrokerage))
      ) {
        return Promise.reject(new Error("Yearly Brokerage should be equal to combined monthly brokerage"));
      }

      return Promise.resolve();
    },
  });

  const MonthRule: Rule = ({ getFieldValue }) => ({
    validator(rule, value) {
      //@ts-ignore
      const field = rule?.field;
      const idx = brokerage?.findIndex((year) => year?.monthlyBrokerage?.includes(field?.slice(-36)));

      const set = new Set(brokerage[idx]?.monthlyBrokerage?.map((item) => getFieldValue(FORM_FIELDS.MONTH + item)));

      if (set.size !== brokerage[idx]?.monthlyBrokerage?.length) {
        return Promise.reject(new Error("2 or more same months in single FY."));
      }

      return Promise.resolve();
    },
  });

  const commercialFYNodes: (index: number) => ArFormContainerNode[] = (index) =>
    Array.from({ length: brokerage?.[index]?.monthlyBrokerage?.length }, (_, monthIdx) => {
      return {
        type: nodeType.container,
        elementData: {
          innerContainerClassName: styles.fullCommercialContainer,
        },
        childNode: {
          type: nodeType.array,
          childNode: [
            {
              type: nodeType.container,
              elementData: {
                body: (
                  <span className={styles.progressContainer}>
                    <span className={styles.progressBox}>{monthIdx + 1}</span>
                  </span>
                ),
              },
            },
            {
              type: nodeType.container,
              elementData: {
                innerContainerClassName: styles.commercialInnerContainer,
                outerContainerClassName: styles.commercialOuterConatiner,
              },
              childNode: {
                type: nodeType.array,
                childNode:
                  billingClient === STRING_CONSTANTS.BRAND
                    ? [
                        FYNode(index, monthIdx),
                        brokerageDayNode(
                          FORM_FIELDS.BRAND_BROKERAGE + brokerage?.[index]?.monthlyBrokerage?.[monthIdx],
                          FORM_FIELDS.BRAND_BROKERAGE,
                          wip?.data?.wip?.brokerageDetails?.[index]?.monthlyBrokerageDetails?.[monthIdx]?.brandBrokerage
                            ?.current,
                          BrokerageDayRule
                        ),
                        EmptyNode,
                      ]
                    : billingClient === STRING_CONSTANTS.LANDLORD
                    ? [
                        FYNode(index, monthIdx),
                        brokerageDayNode(
                          FORM_FIELDS.LL_BROKERAGE + brokerage?.[index]?.monthlyBrokerage?.[monthIdx],
                          FORM_FIELDS.LL_BROKERAGE,
                          wip?.data?.wip?.brokerageDetails?.[index]?.monthlyBrokerageDetails?.[monthIdx]?.shopBrokerage
                            ?.current,
                          BrokerageDayRule
                        ),
                        EmptyNode,
                      ]
                    : [
                        FYNode(index, monthIdx),
                        brokerageDayNode(
                          FORM_FIELDS.LL_BROKERAGE + brokerage?.[index]?.monthlyBrokerage?.[monthIdx],
                          FORM_FIELDS.LL_BROKERAGE,
                          wip?.data?.wip?.brokerageDetails?.[index]?.monthlyBrokerageDetails?.[monthIdx]?.shopBrokerage
                            ?.current,
                          BrokerageDayRule
                        ),
                        brokerageDayNode(
                          FORM_FIELDS.BRAND_BROKERAGE + brokerage?.[index]?.monthlyBrokerage?.[monthIdx],
                          FORM_FIELDS.BRAND_BROKERAGE,
                          wip?.data?.wip?.brokerageDetails?.[index]?.monthlyBrokerageDetails?.[monthIdx]?.brandBrokerage
                            ?.current,
                          BrokerageDayRule
                        ),
                      ],
              },
            },
            {
              type: nodeType.input,
              elementData: {
                inputType: inputType.button,
                inputProps: {
                  type: ButtonTypes.Link,
                  children: <DeleteOutlined onClick={() => deleteFYNode(index, monthIdx)} />,
                  className: styles.deleteFyBtn,
                  disabled: brokerage?.[index]?.monthlyBrokerage?.length === 1,
                },
              },
            },
          ],
        },
      };
    });

  const addMoreMonths = (idx: number) => {
    if (brokerage?.[idx]?.monthlyBrokerage.length >= 5) {
      alert(STRING_CONSTANTS.ONLY_5_MONTHS);
      return;
    }
    const arr = [...(brokerage?.[idx]?.monthlyBrokerage || [])];
    arr.push(uuidv4());
    const brokerageArr = [...brokerage];
    brokerageArr[idx].monthlyBrokerage = arr;
    setBrokerage(brokerageArr);
  };

  const AddButton: (idx: number) => FormNode = (idx) => {
    return {
      type: nodeType.input,
      elementData: {
        inputType: inputType.button,
        formItemProps: {
          // name: STRING_CONSTANTS.ADD_commercial_BUTTON,
        },
        inputProps: {
          type: ButtonTypes.Link,
          children: (
            <>
              <PlusSquareOutlined /> {STRING_CONSTANTS.ADD_MONTH}
            </>
          ),
          size: "large",
          disabled: brokerage?.[idx]?.monthlyBrokerage.length >= 5,
          onClick: () => addMoreMonths(idx),
          className: "pb-16",
        },
      },
    };
  };

  const toggleDetails = (idx: number) => {
    const arr = [...brokerage];
    arr[idx].showDetails = !brokerage?.[idx]?.showDetails;
    setBrokerage([...arr]);
  };

  const YearFormLayout: (fy: string, llAmount: number, brandAmount: number, idx: number) => ArFormContainerNode = (
    fy,
    llAmount,
    brandAmount,
    idx
  ) => {
    return {
      type: nodeType.container,
      elementData: {},
      childNode: {
        type: nodeType.array,
        childNode: brokerage?.[idx]?.showDetails
          ? [
              FYDetailsNode(fy, llAmount, brandAmount, () => toggleDetails(idx), billingClient || "", true),
              ...commercialFYNodes(idx),
              AddButton(idx),
            ]
          : [FYDetailsNode(fy, llAmount, brandAmount, () => toggleDetails(idx), billingClient || "")],
      },
    };
  };

  const FormLayout: ArFormContainerNode = {
    type: nodeType.container,
    elementData: {
      outerContainerClassName: `${styles.reqOuterContainer} ${inModal ? "pr-8" : ""}`,
      innerContainerClassName: styles.inModalContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        ...(brokerage?.map((item, idx) =>
          YearFormLayout(item?.fy, Number(item?.shopBrokerage || 0), Number(item?.brandBrokerage || 0), idx)
        ) || []),
      ],
    },
  };

  const CommercialForm: FormNode = getWIPForm(
    [FormLayout as ArFormContainerNode],
    STRING_CONSTANTS.BROKERAGE_PROJECTION_STEP
  );

  const onFormValueChange = (_: Array<any>, allValues: Array<any>) => {
    //to be implemented
  };
  const moveToSummaryPage = () => {
    saveData();
    navigate(
      `${APP_ROUTES.WIP}/${APP_ROUTES.CONSULTING}${APP_ROUTES.CREATE_WIP}/${projectId}/${APP_ROUTES.WIP_SUMMARY}`
    );
  };

  const saveData = async () => {
    const wipData = temporaryWipData?._id ? temporaryWipData : wip?.data?.wip;
    const brokerageDetails = brokerage?.map((val) => {
      return {
        fy: val?.fy,
        shopBrokerage: {
          current: val?.shopBrokerage,
        },
        brandBrokerage: {
          current: val?.brandBrokerage,
        },
        monthlyBrokerageDetails: val?.monthlyBrokerage?.map((value) => {
          return {
            month: BrokerageFormContext.getFieldValue(FORM_FIELDS.MONTH + value),
            shopBrokerage: {
              current: Number(BrokerageFormContext.getFieldValue(FORM_FIELDS.LL_BROKERAGE + value)),
            },
            brandBrokerage: {
              current: Number(BrokerageFormContext.getFieldValue(FORM_FIELDS.BRAND_BROKERAGE + value)),
            },
          };
        }),
      };
    });
    let res;
    try {
      res = await updateWip({
        projectId: projectId || "",
        brokerageDetails,
        commercialDetails: wipData?.commercialDetails,
      }).unwrap();
    } catch (e) {
      console.error(e);
    }
    if (res?.success) {
      successToast(TOAST_MESSAGES.SAVED_BROKERAGE_DATA);
      return true;
    } else {
      errorToast(TOAST_MESSAGES.ERROR_BROKERAGE_DATA);
    }
    return false;
  };

  const runValidations = useCallback(() => {
    BrokerageFormContext?.validateFields();
  }, [BrokerageFormContext]);

  const runValidationsAndSaveData = async () => {
    let res;
    try {
      res = await BrokerageFormContext?.validateFields();
    } catch (e) {
      console.error(e);
    }
    if (res) {
      const success = await saveData();
      if (success) {
        closeEditModal && closeEditModal();
      }
    }
  };

  useEffect(() => {
    if (temporaryWipData && temporaryWipData?.brokerageDetails) {
      setBrokerage(
        Array.from(temporaryWipData?.brokerageDetails, (item) => {
          return {
            fy: item?.fy,
            shopBrokerage: Number(item?.shopBrokerage?.current || 0),
            brandBrokerage: Number(item?.brandBrokerage?.current || 0),
            monthlyBrokerage: Array.from({ length: item?.monthlyBrokerageDetails?.length || 1 }, () => uuidv4()),
            showDetails: true,
          };
        })
      );
    } else if (wip?.success && wip?.data?.wip?.brokerageDetails) {
      setBrokerage(
        Array.from(wip?.data?.wip?.brokerageDetails, (item) => {
          return {
            fy: item?.fy,
            shopBrokerage: Number(item?.shopBrokerage?.current || 0),
            brandBrokerage: Number(item?.brandBrokerage?.current || 0),
            monthlyBrokerage: Array.from({ length: item?.monthlyBrokerageDetails?.length || 1 }, () => uuidv4()),
            showDetails: true,
          };
        })
      );
    }
  }, [wip, temporaryWipData]);

  useEffect(() => {
    if (brokerage?.length && searchParams.get("error")) {
      runValidations();
    }
  }, [runValidations, searchParams, brokerage?.length]);

  if (isFetchingWipData) {
    return <Spin tip={STRING_CONSTANTS.LOADING_PLEASE_WAIT} size="large" />;
  }

  return (
    <>
      <ArForm
        form={BrokerageFormContext}
        formNode={CommercialForm}
        onValuesChange={onFormValueChange}
        className={`${styles.formWrapper} ${inModal ? styles.modalStyles : undefined}`}
      />
      {inModal ? (
        <CreateWipFooter
          nextButtonAction={runValidationsAndSaveData}
          nextButtonProps={{ loading: isLoading }}
          prevButtonAction={closeEditModal}
          nextButtonText={STRING_CONSTANTS.SAVE}
          prevButtonText={STRING_CONSTANTS.CANCEL}
          showMiddleButton={false}
        />
      ) : (
        <CreateWipFooter
          prevButtonText={STRING_CONSTANTS.PREVIOUS_STEP}
          middleButtonAction={saveData}
          middleButtonProps={{ loading: isLoading }}
          nextButtonAction={moveToSummaryPage}
          nextButtonText={STRING_CONSTANTS.PREVIEW_WIP_BTN}
        />
      )}
    </>
  );
};

export default ConsultingWIPBrokerageDetailsForm;
