import { useEffect, useRef, useState } from "react";

export const useGetInfiniteScrollParams = () => {
  const [pageNo, setPageNo] = useState(1);
  const lastElementRef = useRef(null);
  const resetPageNo = () => {
    setPageNo(1);
  };
  const incrementPageNo = (next?: number) => {
    if (next) {
      setPageNo(next);
      return;
    }
    setPageNo((prev) => prev + 1);
  };
  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>, next?: number) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - scrollTop <= clientHeight + 100) {
      incrementPageNo(next);
    }
  };
  return { pageNo, resetPageNo, onScroll, setPageNo, lastElementRef };
};

export const useCheckIntersectionHook = (lastElementRef: React.MutableRefObject<null>, fetchMoreData?: () => void) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Last element is in the viewport, fetch more data
          fetchMoreData && fetchMoreData();
        }
      });
    });

    if (lastElementRef && lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef && lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [fetchMoreData]);
};
