// uncomment the commented code to enable self update using contact details.
import { ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { IMatchingShopDetail } from "app/services/matchingPropertiesApi";
import { IShopDetails, IShopListing } from "app/services/shopDetailsApi";
import { useToastService } from "components/toastService";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import { useUpdateShops } from "./askShopToUpdateHook";

const AskToUpdateShops: React.FC<{
  shop: IMatchingShopDetail | IShopDetails | IShopListing;
  draft?: boolean;
  buttonType?: ButtonTypes;
}> = ({ shop, draft = false, buttonType = ButtonTypes.Outlined }) => {
  // const allContactPersons = getAllContactPersons(shop);
  // const [selfUpdate, setSelfUpdate] = useState(false);
  const { successToast, errorToast } = useToastService();
  const { useAskToUpdateShops, useAskToUpdateDraftShops } = useUpdateShops();
  // const dispatch = useAppDispatch();
  const { askToUpdateShops, isLoading } = useAskToUpdateShops();
  const { askToUpdateDraftShops, isLoading: draftShopUpdating } = useAskToUpdateDraftShops();
  const handleAskToUpdate = async () => {
    let askToUpdateShopsResponse;
    try {
      askToUpdateShopsResponse = await askToUpdateShops([shop?._id]);
    } catch {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    if (askToUpdateShopsResponse?.success) {
      successToast(STRING_CONSTANTS.REQUEST_SENT_SUCCESSFULLY);
      // setSelfUpdate(true);
    } else {
      errorToast(askToUpdateShopsResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
  };

  const handleAskToUpdateDraftShop = async () => {
    let askToUpdateDraftShopsResponse;
    try {
      askToUpdateDraftShopsResponse = await askToUpdateDraftShops(shop?._id);
    } catch {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    if (askToUpdateDraftShopsResponse?.success) {
      successToast(STRING_CONSTANTS.REQUEST_SENT_SUCCESSFULLY);
    } else {
      errorToast(askToUpdateDraftShopsResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
  };
  // Uncomment the below code to enable self update

  // const handleSelfUpdate = () => {
  //   dispatch(
  //     setAllContactPersons({
  //       contactPersons: allContactPersons,
  //       shopId: shop?._id,
  //     })
  //   );
  //   dispatch(setSelfUpdateModalOpen(true));
  // };
  return (
    <ArButton
      type={buttonType}
      size={"middle"}
      onClick={(e) => {
        e.stopPropagation();
        // Uncomment the below code to enable self update

        // draft
        //   ? handleAskToUpdateDraftShop()
        //   : selfUpdate
        //   ? handleSelfUpdate()
        //   : handleAskToUpdate();
        draft ? handleAskToUpdateDraftShop() : handleAskToUpdate();
      }}
      loading={draft ? draftShopUpdating : isLoading}
    >
      {/* Uncomment the below code to enable self update */}

      {/* {selfUpdate ? STRING_CONSTANTS.SELF_UPDATE : STRING_CONSTANTS.ASK_TO_UPDATE} */}
      {STRING_CONSTANTS.ASK_TO_UPDATE}
    </ArButton>
  );
};

export default AskToUpdateShops;
