import { USER_ANALYTICS } from "constants/ApiUrls";

import { api, GenericResponse } from "./api";
import { IBrand } from "./createRequirmentApi";
import { ICarpetArea } from "./createRequirmentApi";
import { IFilterListResponse, IGetFilteredListBody, ISelectedFilters } from "./listingPageApi";
import { IPaginationParam } from "./notificationsApi";
import { IShopListing } from "./shopListingApi";

export interface IAnalytics {
  optionListStatusWiseCountObj: {
    [key: string]: number;
  };
  loiSignTargets: number;
}

export interface IInteractedBrand {
  _id: string;
  brand: IBrand;
  cityId: string;
  localityId: string;
  carpetArea: ICarpetArea;
  status: string;
  brandManagerId: string;
  active: boolean;
  brandRequirementId: string;
  updatedAt: string;
}

export interface IMostInteractedBrands {
  brandRequirementDetails: IInteractedBrand[];
}

export interface IBrandHeader {
  _id: string;
  brandName?: string;
  brandLogoUrl?: string;
  createdAt?: string;
  industry?: string;
  legalEntityName?: string;
  updatedAt?: string;
}

export interface ILocalityHeader {
  _id: string;
  name?: string;
  cityId?: string;
  slug?: string;
}

export interface IFilteredShops {
  locality?: ILocalityHeader;
  brand?: IBrandHeader;
  newTagShopIds: string[];
  updatedTagShopIds: string[];
  expiredTagShopIds: string[];
  shops: IShopListing[];
}

export type IUserAnalyticsResponse = GenericResponse<IAnalytics>;
export type IFilteredShopsResponse = GenericResponse<{
  shopsObjArr: IFilteredShops[];
  paginationParams: IPaginationParam;
}>;
export type IMostInteractedBrandsResponse = GenericResponse<IMostInteractedBrands>;

export const analyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserAnalytics: builder.query<IUserAnalyticsResponse, number>({
      query: (quarter) => ({
        url: USER_ANALYTICS.GET_USER_ANALYTICS,
        params: {
          quarter,
        },
      }),
    }),
    getMostInteractedBrands: builder.query<IMostInteractedBrandsResponse, void>({
      query: () => ({
        url: USER_ANALYTICS.MOST_INTERACTED,
      }),
    }),
    getRequirementTabFilters: builder.query<IFilterListResponse, ISelectedFilters>({
      query: (selectedFilters) => ({
        url: USER_ANALYTICS.GET_REQUIREMENT_FILTER,
        body: selectedFilters,
        method: "POST",
      }),
    }),
    getLocalityTabFilters: builder.query<IFilterListResponse, ISelectedFilters>({
      query: (selectedFilters) => ({
        url: USER_ANALYTICS.GET_LOCALITY_FILTERS,
        body: selectedFilters,
        method: "POST",
      }),
    }),
    getOverviewFilteredShops: builder.query<IFilteredShopsResponse, IGetFilteredListBody>({
      query: ({ selectedFilters, params }) => ({
        url: USER_ANALYTICS.GET_FILTERED_SHOPS,
        body: { selectedFilters },
        params,
        method: "POST",
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { selectedFilters } = queryArgs;
        return { selectedFilters };
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems.data?.paginationParams?.previous === null;
        if (newItems.success && currentCache?.data?.shopsObjArr && newItems.data && !isPageOne) {
          currentCache.data?.shopsObjArr.push(...(newItems.data?.shopsObjArr || []));
          currentCache.data.paginationParams = newItems.data.paginationParams;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetUserAnalyticsQuery,
  useGetMostInteractedBrandsQuery,
  useGetRequirementTabFiltersQuery,
  useGetLocalityTabFiltersQuery,
  useGetOverviewFilteredShopsQuery,
} = analyticsApi;
