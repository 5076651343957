import { ArButton, ArDatePicker, ArModal, ArMultiSelect, ButtonTypes } from "@anarock/ar-common-react";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import { useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { IBillingMilestone, IWipBody, useUpdateWipDetailsMutation } from "app/services/wip";
import { convertBillingMilestoneValuesToArray, isNullOrUndefiend } from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { WIP_APPROVAL_STATUS } from "constants/index";
import { DATE_KEYS } from "constants/ListingEnums";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DateFooterProps, WIPUpdateBillingMilestoneModalProps } from "./index.interface";
import styles from "./index.module.scss";

const Footer = ({ updatedateMap, name, value }: DateFooterProps) => {
  return (
    <div className={styles.footer}>
      <ArDatePicker
        placeholder={STRING_CONSTANTS.COMPLETION_DATE}
        value={value ? dayjs(value) : null}
        format={DATE_KEYS.DATE_FORMAT_UI}
        onChange={(date: Dayjs | null) => updatedateMap(name, date?.toISOString() || "")}
        showToday={false}
      />
    </div>
  );
};

export const WIPUpdateBillingMilestoneModal = ({
  open,
  onCancel,
  toggleModal,
  value,
  submitState,
  submitStateAction,
  goBackAction,
  approval,
  wipType,
  projectId,
  ...props
}: WIPUpdateBillingMilestoneModalProps) => {
  const [billingMileStoneValues, setBillingMileStoneValues] = useState<IBillingMilestone[]>([]);
  const [milestoneDateMap, setMileStoneDateMap] = useState<{
    [key: string]: string;
  }>({});

  const { errorToast, successToast } = useToastService();
  const [updateWip, { isLoading }] = useUpdateWipDetailsMutation();
  const [updateConsultingWIP, { isLoading: consultingLoading }] = useUpdateConsultingWipDetailsMutation();
  const { data: enums } = useGetStaticEnumsQuery();

  const navigate = useNavigate();

  const billingMilestoneArrayOptions = useMemo(
    () =>
      convertBillingMilestoneValuesToArray(
        wipType === "CONSULTING" ? enums?.data?.consultingWipBillingMilestones : enums?.data?.wipBillingMilestones
      ),
    [wipType, enums]
  );

  const updatedateMap = useCallback(
    (key: string, value: string) => {
      const obj = { ...milestoneDateMap };
      obj[key] = value;
      setMileStoneDateMap(obj);
      const arr = [...billingMileStoneValues];
      const idx = billingMileStoneValues.findIndex((item) => item.value === key);
      if (idx > -1) {
        arr[idx] = { ...arr[idx], dueDate: value };
        setBillingMileStoneValues(arr);
      }
    },
    [billingMileStoneValues, milestoneDateMap]
  );

  const billingMilestoneOptions: optionProps[] = useMemo(
    () =>
      billingMilestoneArrayOptions?.map(({ key, value }) => ({
        label: value,
        value,
        key,
        footerNode: <Footer updatedateMap={updatedateMap} name={value} value={milestoneDateMap[value]} />,
      })),
    [milestoneDateMap, updatedateMap, billingMilestoneArrayOptions]
  );

  const submitBillingModal = async () => {
    if (billingMileStoneValues?.length === 0) {
      errorToast(STRING_CONSTANTS.PLEASE_SELECT_ONE_BILLING_MILESTONE);
      return;
    }
    const idx = billingMileStoneValues?.findIndex(({ dueDate }) => isNullOrUndefiend(dueDate));
    if (idx !== -1) {
      errorToast(STRING_CONSTANTS.PLEASE_ENRTER_DUE_DATE);
      return;
    }
    const approvalObj = submitState
      ? {
          ...approval,
          approvalStatus: WIP_APPROVAL_STATUS.PENDING,
          isSubmitted: true,
        }
      : approval;
    let res;
    const body: IWipBody = {
      billingMilestones: billingMileStoneValues,
      approval: approvalObj,
      draft: false,
      projectId: projectId,
    };
    if (!submitState) {
      delete body?.draft;
    }
    try {
      if (wipType === "CONSULTING") {
        res = await updateConsultingWIP(body).unwrap();
      } else {
        res = await updateWip(body).unwrap();
      }
    } catch (e) {
      console.error(e);
    }
    if (res?.success) {
      successToast(TOAST_MESSAGES.SAVED_BILLING_MILESTONE);
      if (submitState) {
        wipType === "CONSULTING"
          ? navigate(
              APP_ROUTES.WIP +
                "/" +
                APP_ROUTES.CONSULTING +
                APP_ROUTES.EDIT_WIP +
                "/" +
                projectId +
                "/" +
                APP_ROUTES.WIP_SUMMARY
            )
          : navigate(APP_ROUTES.WIP + APP_ROUTES.EDIT_WIP + "/" + projectId + "/" + APP_ROUTES.WIP_SUMMARY);
      }
    } else {
      errorToast(TOAST_MESSAGES.ERROR_BILLING_MILESTONE);
    }
    toggleModal();
  };

  const handleCheckboxChange = (val: string[]) => {
    const arr = val?.map((item) => ({
      value: item,
      key: billingMilestoneArrayOptions?.find(({ value }) => value === item)?.key || "",
      dueDate: milestoneDateMap[item],
    }));

    setBillingMileStoneValues(arr);
  };

  useEffect(() => {
    if (value) {
      const temp: { [key: string]: string } = {};
      const obj = value.reduce((acc, curr) => {
        acc[curr.value] = curr.dueDate;
        return acc;
      }, temp);
      setMileStoneDateMap(obj);
    } else {
      setBillingMileStoneValues([]);
      setMileStoneDateMap({});
    }
  }, [value]);

  const defaultVal = useMemo(() => value?.map((item) => item.value), [value]);

  return (
    <ArModal
      open={open}
      onCancel={toggleModal}
      title={(submitState ? STRING_CONSTANTS.STEP_2_OFF_2 : "") + STRING_CONSTANTS.CHOOSE_BILLING_MILESTONE}
      showSearch={false}
      className={`${styles.updateModal}`}
      width={550}
      footer={
        submitState ? (
          <div className="display-flex-space-between fullWidth">
            <ArButton onClick={() => (goBackAction ? goBackAction?.() : undefined)} type={ButtonTypes.Outlined}>
              {STRING_CONSTANTS.GOBACK_TO_STEP_1}
            </ArButton>
            <ArButton onClick={submitBillingModal} loading={isLoading || consultingLoading}>
              {STRING_CONSTANTS.SUBMIT_FOR_APPROVAL_WIP}
            </ArButton>
          </div>
        ) : (
          <ArButton onClick={submitBillingModal} loading={isLoading || consultingLoading}>
            {STRING_CONSTANTS.UPDATE_BILLING_MILESTONE}
          </ArButton>
        )
      }
      {...props}
    >
      <div className={`${styles.wrapper} noScrollBar`}>
        <ArMultiSelect
          options={billingMilestoneOptions}
          defaultValue={defaultVal}
          showSearch={false}
          onChange={(val) => handleCheckboxChange(val as string[])}
        />
      </div>
    </ArModal>
  );
};
