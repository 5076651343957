import { ArForm, ArInputType, ArModal, ArTag, inputType, nodeType } from "@anarock/ar-common-react";
import { WarningOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { useCreateLandLordMutation, useUpdateLandLordMutation } from "app/services/wip";
import { convertEnumsToRadioOptions, isNotNullorUndefined } from "app/utils/helperFunctions";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { emailRules, phoneRules, requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { GET_API_ERRORS } from "constants/getAPIErrors";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { RequiredNumberNode, RequiredTextNode } from "features/invoices/components/formHelpers";
import React, { useEffect } from "react";

import { AddLLModalProps } from "./index.interface";
import styles from "./index.module.scss";

const AddLLModal: React.FC<AddLLModalProps> = ({ onSubmit, sendDataAndCloseModal, editLLData, mallId, ...props }) => {
  const [form] = useForm();

  //this is for shop poc. in case if user does not have type we will consider this as new user.
  const editFlow = isNotNullorUndefined(editLLData?.type);

  const { data: enums, isError, isLoading, isFetching } = useGetStaticEnumsQuery();
  useQueryApiError(isError, isLoading, isFetching, enums, GET_API_ERRORS.UNABLE_TO_FETCH_ENUMS);

  const [createLL, { isLoading: createLoading }] = useCreateLandLordMutation();
  const [updateLL, { isLoading: updateLLLoading }] = useUpdateLandLordMutation();

  const { errorToast, successToast } = useToastService();

  const AddBrandForm = {
    type: nodeType.array,
    childNode: [
      RequiredTextNode(FORM_FIELDS.LEGAL_NAME, FORM_FIELDS.LEGAL_NAME),
      {
        type: nodeType.input,
        elementData: {
          inputType: inputType.selectBox,
          formItemProps: {
            name: FORM_FIELDS.LL_TYPE,
            rules: requiredRulesOnChange,
            initialValue: undefined,
          },
          inputProps: {
            type: ArInputType.text,
            label: FORM_FIELDS.LL_TYPE_LABEL,
            required: true,

            options: convertEnumsToRadioOptions(enums?.data?.shopPOCType),
          },
        },
      },
      {
        type: nodeType.input,
        elementData: {
          inputType: inputType.selectBox,
          formItemProps: {
            name: FORM_FIELDS.DESIGNATION,
            rules: requiredRulesOnChange,
            initialValue: undefined,
          },
          inputProps: {
            type: ArInputType.text,
            label: FORM_FIELDS.DESIGNATION_LABEL,
            required: true,

            options: convertEnumsToRadioOptions(enums?.data?.shopPOCDesignation),
          },
        },
      },
      RequiredTextNode(FORM_FIELDS.EMAIL, FORM_FIELDS.EMAIL_LABEL, undefined, undefined, emailRules),
      RequiredNumberNode(FORM_FIELDS.PHONE_NUMBER, FORM_FIELDS.PHONE_NUMBER_LABEL, undefined, undefined, phoneRules),
    ],
  };
  const handleSubmitModal = async () => {
    try {
      await form.validateFields();

      let addLLResponse;
      try {
        addLLResponse = await createLL({
          type: form.getFieldValue(FORM_FIELDS.LL_TYPE),
          name: form.getFieldValue(FORM_FIELDS.LEGAL_NAME),
          email: form.getFieldValue(FORM_FIELDS.EMAIL),
          designation: form.getFieldValue(FORM_FIELDS.DESIGNATION),
          phoneNumber: {
            countryCode: "+91",
            number: form.getFieldValue(FORM_FIELDS.PHONE_NUMBER),
          },

          mallId: mallId,
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (addLLResponse?.success) {
        sendDataAndCloseModal && sendDataAndCloseModal(addLLResponse?.data?.landlord);
        form.resetFields();
        successToast(STRING_CONSTANTS.LL_ADDED_SUCCESSFULLY);
      } else {
        errorToast(addLLResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };
  const handleUpdatetModal = async () => {
    try {
      await form.validateFields();

      let addLLResponse;
      try {
        addLLResponse = await updateLL({
          _id: editLLData?._id,
          type: form.getFieldValue(FORM_FIELDS.LL_TYPE),
          name: form.getFieldValue(FORM_FIELDS.LEGAL_NAME),
          email: form.getFieldValue(FORM_FIELDS.EMAIL),
          designation: form.getFieldValue(FORM_FIELDS.DESIGNATION),
          phoneNumber: {
            countryCode: "+91",
            number: form.getFieldValue(FORM_FIELDS.PHONE_NUMBER),
          },

          mallId: mallId,
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (addLLResponse?.success) {
        sendDataAndCloseModal && sendDataAndCloseModal(addLLResponse?.data?.landlord);
        successToast(STRING_CONSTANTS.LL_UPDATE_SUCCESSFULLY);
      } else {
        errorToast(addLLResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  useEffect(() => {
    if (editLLData) {
      form.setFieldsValue({
        [FORM_FIELDS.LEGAL_NAME]: editLLData.name,
        [FORM_FIELDS.DESIGNATION]: editLLData.designation,
        [FORM_FIELDS.PHONE_NUMBER]: editLLData.phoneNumber?.number,
        [FORM_FIELDS.LL_TYPE]: editLLData.type,
        [FORM_FIELDS.EMAIL]: editLLData.email,
      });
    }
  }, [editLLData, form]);

  return (
    <ArModal
      onSubmit={editFlow ? handleUpdatetModal : handleSubmitModal}
      width={"500px"}
      title={editFlow ? STRING_CONSTANTS.EDIT_LL : STRING_CONSTANTS.ADD_N_LL}
      destroyOnClose
      submitText={editFlow ? STRING_CONSTANTS.EDIT_DETAILS : STRING_CONSTANTS.Add_LL}
      confirmLoading={createLoading || updateLLLoading}
      className={styles.selectBoxWidth}
      {...props}
    >
      {editFlow ? (
        <div className="py-8">
          <ArTag type="full-width" varient="warning">
            <div className="d-flex">
              <WarningOutlined className={"iconSize24"} />
              <div className="pl-8">
                <p className="sub-hero-text">{STRING_CONSTANTS.CHANGES_WILL_APPLY_TO_FUTURE}</p>
                <p className="sub-hero-text-regular-medium">{STRING_CONSTANTS.EXISTED_UNCHANGED}</p>
              </div>
            </div>
          </ArTag>
        </div>
      ) : null}
      <ArForm form={form} formNode={AddBrandForm} />
    </ArModal>
  );
};

export default AddLLModal;
