import { ArBaseContainer, ArButton, ArModal, ButtonTypes } from "@anarock/ar-common-react";
import { Spin } from "antd";
import { useAppSelector } from "app/hooks";
import { useGetConsultingWipDetailsQuery, useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { IWipBody } from "app/services/wip";
import { isNotEqual, isNullOrUndefiend, runConsultingWipValidations } from "app/utils/helperFunctions";
import { IWIPApproval } from "app/utils/interfaces/index.interface";
import useGetWipFlow from "app/utils/useGetWipFlow";
import { WipPages } from "app/utils/wipPages";
import { useToastService } from "components/toastService";
import { WIPUpdateBillingMilestoneModal } from "components/wipUpdateBillingMilestoneModal";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { selectAuthUser } from "features/authentication/authSlice";
import WIPReSubmitModal, {
  WIPApproveModal,
  WIPChangeBrokerageModal,
  WIPDeclineModal,
} from "features/wip/components/ConfirmationModals";
import { CreateWipFooter, getFooterButtons } from "features/wip/partials";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "../../../wip/components/WIP-Summary/index.module.scss";
import {
  getApprovedCommercialDetails,
  getBrokerageDetailsChangeCount,
  getCommercialDetails,
  SummaryCardForBillingMilestones,
  SummaryCardForWIPStatus,
} from "../../../wip/components/WIP-Summary/partials";
import ConsultingWIPBrokerageDetailsForm from "../ConsultingWIP-BrokerageProjectionForm";
import ConsultingWIPCommercialDetailsForm from "../ConsultingWIP-CommercialDetailsForm";
import ConsultingWIPContactDetailsForm from "../ConsultingWIP-ContactDetailsForm";
import UpdateConsultingWipStatusModal from "../UpdateConsultingWipStatusModal";
import { ConsultingWIPSummaryCards } from "./partials";

const ConsultingWIPSummary = () => {
  const { projectId } = useParams();

  const [pocModal, setPocModal] = useState(false);
  const [commercialModal, setCommercialModal] = useState(false);
  const [brokerageModal, setBrokerageModal] = useState(false);
  const [changeBrokerageModal, setChangeBrokerageModal] = useState(false);
  const [temporaryWipData, setTemporaryWipData] = useState<IWipBody>();
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [approvedModalOpen, setApproveModalOpen] = useState(false);
  const [reSubmitModalOpen, setReSubmitModalOpen] = useState(false);

  const [openWIPStatusModal, setOpenWIPstatusModal] = useState(false);
  const [openBillingModal, setOpenBillingModal] = useState(false);

  const [submitState, setSubmitState] = useState(false);

  const { data: enums } = useGetStaticEnumsQuery();

  const navigate = useNavigate();

  const userFlow = useGetWipFlow();

  const location = useLocation();

  const user = useAppSelector(selectAuthUser);

  const [updateWip, { isLoading }] = useUpdateConsultingWipDetailsMutation();

  const { successToast, errorToast } = useToastService();

  const { data: wip, isFetching: isFetchingWipData } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });
  const approval = wip?.data?.wip?.approval;
  const approvedWIP = wip?.data?.wip?.approval?.approvedWIP;

  let contactDetailsChangeCount = 0;
  let LLContactDetailsChangeCount = 0;
  let commercialDetailsChangeCount = 0;
  const BrandPocData = wip?.data?.wip?.brand?.poc?.map(({ name, email, designation, phoneNumber }, idx) => {
    const isNameChanged = approvedWIP && isNotEqual(name, approvedWIP?.brand?.poc?.[idx]?.name || "");
    const isNumberChanged =
      approvedWIP &&
      isNotEqual(
        phoneNumber?.number?.toString(),
        approvedWIP?.brand?.poc?.[idx]?.phoneNumber?.number?.toString() || ""
      );
    const isEmailChanged = approvedWIP && isNotEqual(email, approvedWIP?.brand?.poc?.[idx]?.email || "");
    const isDesignationChanged =
      approvedWIP && isNotEqual(designation, approvedWIP?.brand?.poc?.[idx]?.designation || "");
    if (isNameChanged) {
      contactDetailsChangeCount++;
    }
    if (isNumberChanged) {
      contactDetailsChangeCount++;
    }
    if (isEmailChanged) {
      contactDetailsChangeCount++;
    }
    if (isDesignationChanged) {
      contactDetailsChangeCount++;
    }
    return [
      {
        label: `${FORM_FIELDS.NAME_LABEL} ${idx + 1}`,
        value: name,
        isChanged: isNameChanged,
      },
      {
        label: `${FORM_FIELDS.PHONE_NUMBER_LABEL} ${idx + 1}`,
        value: phoneNumber?.number?.toString(),
        isChanged: isNumberChanged,
      },
      {
        label: `${FORM_FIELDS.EMAIL_LABEL} ${idx + 1}`,
        value: email,
        isChanged: isEmailChanged,
      },

      {
        label: `${FORM_FIELDS.DESIGNATION_LABEL} ${idx + 1}`,
        value: designation,
        isChanged: isDesignationChanged,
      },
    ];
  });

  const BrandPOCDataApproved = approvedWIP?.brand?.poc?.map(({ name, email, designation, phoneNumber }, idx) => {
    const isNameChanged = isNotEqual(name, wip?.data?.wip?.brand?.poc?.[idx]?.name || "");
    const isNumberChanged = isNotEqual(
      phoneNumber?.number?.toString(),
      wip?.data?.wip?.brand?.poc?.[idx]?.phoneNumber?.number?.toString() || ""
    );
    const isEmailChanged = isNotEqual(email, wip?.data?.wip?.brand?.poc?.[idx]?.email || "");
    const isDesignationChanged = isNotEqual(designation, wip?.data?.wip?.brand?.poc?.[idx]?.designation || "");
    return [
      {
        label: `${FORM_FIELDS.NAME_LABEL} ${idx + 1}`,
        value: name,
        isChanged: isNameChanged,
      },
      {
        label: `${FORM_FIELDS.PHONE_NUMBER_LABEL} ${idx + 1}`,
        value: phoneNumber?.number?.toString(),
        isChanged: isNumberChanged,
      },
      {
        label: `${FORM_FIELDS.EMAIL_LABEL} ${idx + 1}`,
        value: email,
        isChanged: isEmailChanged,
      },

      {
        label: `${FORM_FIELDS.DESIGNATION_LABEL} ${idx + 1}`,
        value: designation,
        isChanged: isDesignationChanged,
      },
      { label: "", value: "" },
    ];
  });

  const LLPocData = wip?.data?.wip?.landlord?.poc?.map(({ name, email, designation, phoneNumber }, idx) => {
    const isNameChanged = approvedWIP && isNotEqual(name, approvedWIP?.landlord?.poc?.[idx]?.name || "");
    const isNumberChanged =
      approvedWIP &&
      isNotEqual(
        phoneNumber?.number?.toString(),
        approvedWIP?.landlord?.poc?.[idx]?.phoneNumber?.number?.toString() || ""
      );
    const isEmailChanged = approvedWIP && isNotEqual(email, approvedWIP?.landlord?.poc?.[idx]?.email || "");
    const isDesignationChanged =
      approvedWIP && isNotEqual(designation, approvedWIP?.landlord?.poc?.[idx]?.designation || "");
    if (isNameChanged) {
      LLContactDetailsChangeCount++;
    }
    if (isNumberChanged) {
      LLContactDetailsChangeCount++;
    }
    if (isEmailChanged) {
      LLContactDetailsChangeCount++;
    }
    if (isDesignationChanged) {
      LLContactDetailsChangeCount++;
    }
    return [
      {
        label: `${FORM_FIELDS.NAME_LABEL} ${idx + 1}`,
        value: name,
        isChanged: isNameChanged,
      },
      {
        label: `${FORM_FIELDS.PHONE_NUMBER_LABEL} ${idx + 1}`,
        value: phoneNumber?.number?.toString(),
        isChanged: isNumberChanged,
      },
      {
        label: `${FORM_FIELDS.EMAIL_LABEL} ${idx + 1}`,
        value: email,
        isChanged: isEmailChanged,
      },

      {
        label: `${FORM_FIELDS.DESIGNATION_LABEL} ${idx + 1}`,
        value: designation,
        isChanged: isDesignationChanged,
      },
    ];
  });

  const LLPOCDataApproved = approvedWIP?.landlord?.poc?.map(({ name, email, designation, phoneNumber }, idx) => {
    const isNameChanged = isNotEqual(name, wip?.data?.wip?.landlord?.poc?.[idx]?.name || "");
    const isNumberChanged = isNotEqual(
      phoneNumber?.number?.toString(),
      wip?.data?.wip?.landlord?.poc?.[idx]?.phoneNumber?.number?.toString() || ""
    );
    const isEmailChanged = isNotEqual(email, wip?.data?.wip?.landlord?.poc?.[idx]?.email || "");
    const isDesignationChanged = isNotEqual(designation, wip?.data?.wip?.landlord?.poc?.[idx]?.designation || "");
    return [
      {
        label: `${FORM_FIELDS.NAME_LABEL} ${idx + 1}`,
        value: name,
        isChanged: isNameChanged,
      },
      {
        label: `${FORM_FIELDS.PHONE_NUMBER_LABEL} ${idx + 1}`,
        value: phoneNumber?.number?.toString(),
        isChanged: isNumberChanged,
      },
      {
        label: `${FORM_FIELDS.EMAIL_LABEL} ${idx + 1}`,
        value: email,
        isChanged: isEmailChanged,
      },

      {
        label: `${FORM_FIELDS.DESIGNATION_LABEL} ${idx + 1}`,
        value: designation,
        isChanged: isDesignationChanged,
      },
      { label: "", value: "" },
    ];
  });

  const brandAmount = wip?.data?.wip?.brokerageDetails?.reduce(
    (acc, curr) => acc + Number(curr?.brandBrokerage?.current),
    0
  );
  const llAmount = wip?.data?.wip?.brokerageDetails?.reduce(
    (acc, curr) => acc + Number(curr?.shopBrokerage?.current),
    0
  );

  const totalBrokerageAmount: number = (brandAmount || 0) + (llAmount || 0);

  const approvedBrandAmt = approvedWIP?.brokerageDetails?.reduce(
    (acc, curr) => acc + Number(curr?.brandBrokerage?.current),
    0
  );
  const approvedLLAmt = approvedWIP?.brokerageDetails?.reduce(
    (acc, curr) => acc + Number(curr?.shopBrokerage?.current),
    0
  );

  const approvedTotalBrokerageAmt: number = (approvedBrandAmt || 0) + (approvedLLAmt || 0);

  const CommercialDetails = useMemo(() => {
    return getCommercialDetails({
      wip,
      approvedWIP,
      llAmount,
      approvedLLAmt,
      brandAmount,
      approvedBrandAmt,
      totalBrokerageAmount,
      approvedTotalBrokerageAmt,
      isConsulting: true,
    });
  }, [
    wip,
    approvedWIP,
    llAmount,
    approvedLLAmt,
    brandAmount,
    approvedBrandAmt,
    totalBrokerageAmount,
    approvedTotalBrokerageAmt,
  ]);

  const ApprovedCommercialDetails = useMemo(() => {
    return getApprovedCommercialDetails({
      wip,
      approvedWIP,
      llAmount,
      approvedLLAmt,
      brandAmount,
      approvedBrandAmt,
      totalBrokerageAmt: totalBrokerageAmount,
      approvedTotalBrokerageAmt,
      isConsulting: true,
    });
  }, [
    wip,
    approvedWIP,
    llAmount,
    approvedBrandAmt,
    brandAmount,
    approvedLLAmt,
    totalBrokerageAmount,
    approvedTotalBrokerageAmt,
  ]);

  CommercialDetails.forEach(({ isChanged }) => {
    if (isChanged) commercialDetailsChangeCount++;
  });

  const brokerageDetailsChangeCount = useMemo(() => {
    return getBrokerageDetailsChangeCount({ wip, approvedWIP });
  }, [wip, approvedWIP]);

  const runValidations = () => {
    const validate = runConsultingWipValidations({ ...wip?.data?.wip }, wip?.data?.wip?.wipMetaData?.billingClient);

    if (!validate?.validated) {
      const route =
        APP_ROUTES.WIP +
        "/" +
        APP_ROUTES.CONSULTING +
        APP_ROUTES.CREATE_WIP +
        "/" +
        projectId +
        "/" +
        WipPages[validate.idx];
      navigate({ pathname: route, search: "?error=true" });
      errorToast(STRING_CONSTANTS.WIP_VALIDATIONS_FAILED);
      return;
    }
    setSubmitState(true);
    setOpenWIPstatusModal(true);
  };

  const submitWIP = async () => {
    const wipObject = { ...wip?.data?.wip };
    delete wipObject["billingMilestones"];
    let res;
    try {
      res = await updateWip({ ...wipObject, draft: false, projectId: wipObject?.project?.id }).unwrap();
    } catch (e) {
      console.error(e);
    }
    if (res?.success) {
      successToast(TOAST_MESSAGES.WIP_SAVED_SUCCESS);
      navigate(
        APP_ROUTES.WIP +
          "/" +
          APP_ROUTES.CONSULTING +
          APP_ROUTES.EDIT_WIP +
          "/" +
          wipObject?.project?.id +
          "/" +
          APP_ROUTES.WIP_SUMMARY
      );
    } else {
      errorToast(res?.reason || TOAST_MESSAGES.WIP_SAVED_ERROR);
    }
  };

  const saveAsDraft = () => {
    successToast(TOAST_MESSAGES.WIP_DRAFT_SAVED_SUCCESS);
  };

  const goBack = () => {
    if (userFlow === STRING_CONSTANTS.ANALYTICS) {
      //navigate user to analytics page which is last page
      navigate(-1);
    } else {
      navigate(`${APP_ROUTES.WIP}/${APP_ROUTES.CONSULTING}/${APP_ROUTES.LISTING}`);
    }
  };

  const getWipDataForBrokerage = (wip: IWipBody) => {
    setTemporaryWipData(wip);
    setChangeBrokerageModal(true);
  };

  const cancelChangeInBrokerage = () => {
    setCommercialModal(false);
    setChangeBrokerageModal(false);
  };

  const changeBrokerageDetails = () => {
    cancelChangeInBrokerage();
    setBrokerageModal(true);
  };

  const openBrokerAgeModal = () => {
    setTemporaryWipData(undefined);
    setBrokerageModal(true);
  };

  const IS_DEAL_BILLED = wip?.data?.wip?.status?.wipStatusId === enums?.data?.wipStatus?.BILLED;

  const IS_DEAL_DROPPED = wip?.data?.wip?.status?.wipStatusId === enums?.data?.wipStatus?.DROP_THE_DEAL;

  const isEditable =
    !IS_DEAL_BILLED &&
    !IS_DEAL_DROPPED &&
    Boolean(wip?.data?.wip?.isEditable) &&
    (wip?.data?.wip?.invoicesCount || 0) < 1;

  const backButtonType = IS_DEAL_DROPPED ? ButtonTypes.PrimaryCoral : ButtonTypes.Primary;

  const handleSubmitForApproval = async () => {
    setReSubmitModalOpen(true);
  };

  const handleApproveWip = () => {
    setApproveModalOpen(true);
  };

  const handleDeclineWip = () => {
    setDeclineModalOpen(true);
  };

  const FooterButtons = useMemo(
    () =>
      getFooterButtons(
        user?.subRoles,
        wip?.data?.wip?.approval,
        goBack,
        handleSubmitForApproval,
        handleApproveWip,
        handleDeclineWip
      ),
    [wip?.data?.wip?.approval, user?.subRoles]
  );

  const toggleUpdateStateModal = () => {
    setOpenWIPstatusModal(!openWIPStatusModal);
    setSubmitState(false);
  };

  const toggleBillingMilestoneModal = () => {
    setOpenBillingModal(!openBillingModal);
    setSubmitState(false);
  };

  const submitWIPStatus = () => {
    setOpenBillingModal(true);
    setSubmitState(true);
  };

  const goBackActionForBmModal = () => {
    setOpenBillingModal(false);
    setOpenWIPstatusModal(true);
  };

  if (isFetchingWipData) {
    return <Spin tip={STRING_CONSTANTS.LOADING_PLEASE_WAIT} size="large" />;
  }

  return (
    <div>
      <WIPChangeBrokerageModal
        onCancel={cancelChangeInBrokerage}
        onSubmit={changeBrokerageDetails}
        open={changeBrokerageModal}
      />
      <ArModal open={pocModal} onCancel={() => setPocModal(false)} width={992} footer={null}>
        <ConsultingWIPContactDetailsForm inModal closeModal={() => setPocModal(false)} />
      </ArModal>
      <ArModal open={commercialModal} onCancel={() => setCommercialModal(false)} width={992} footer={null}>
        <ConsultingWIPCommercialDetailsForm
          inModal
          closeModal={() => setCommercialModal(false)}
          sendWipData={getWipDataForBrokerage}
        />
      </ArModal>
      <ArModal open={brokerageModal} onCancel={() => setBrokerageModal(false)} width={992} footer={null}>
        <ConsultingWIPBrokerageDetailsForm
          inModal
          closeModal={() => setBrokerageModal(false)}
          temporaryWipData={temporaryWipData}
        />
      </ArModal>

      <WIPDeclineModal
        open={declineModalOpen}
        onCancel={() => setDeclineModalOpen(false)}
        closeModal={() => setDeclineModalOpen(false)}
        projectId={projectId || ""}
        approval={approval as IWIPApproval}
        wipType="CONSULTING"
      />
      <WIPApproveModal
        open={approvedModalOpen}
        onCancel={() => setApproveModalOpen(false)}
        closeModal={() => setApproveModalOpen(false)}
        projectId={projectId || ""}
        approval={approval as IWIPApproval}
        wipType="CONSULTING"
      />
      <WIPReSubmitModal
        open={reSubmitModalOpen}
        onCancel={() => setReSubmitModalOpen(false)}
        closeModal={() => setReSubmitModalOpen(false)}
        projectId={projectId || ""}
        wipType="CONSULTING"
        approval={approval as IWIPApproval}
      />
      <ArBaseContainer className={styles.summaryContainer}>
        <ConsultingWIPSummaryCards
          BrandPocData={BrandPocData}
          BrandPocDataApproved={BrandPOCDataApproved}
          approvedWIP={approvedWIP}
          contactDetailsChangeCount={contactDetailsChangeCount}
          LLContactDetailsChangeCount={LLContactDetailsChangeCount}
          setPocModal={setPocModal}
          IS_DEAL_DROPPED={IS_DEAL_DROPPED}
          wip={wip}
          openBrokerAgeModal={openBrokerAgeModal}
          brokerageDetailsChangeCount={brokerageDetailsChangeCount}
          CommercialDetails={CommercialDetails}
          ApprovedCommercialDetails={ApprovedCommercialDetails}
          commercialDetailsChangeCount={commercialDetailsChangeCount}
          setCommercialModal={setCommercialModal}
          LLPOCDataApproved={LLPOCDataApproved}
          LLPocData={LLPocData}
          showEditButton={isEditable}
        />

        <SummaryCardForWIPStatus
          wip={wip?.data?.wip}
          isApprovedWIP={approvedWIP ? true : false}
          showEditButton={isEditable}
          onEdit={() => setOpenWIPstatusModal(true)}
        />

        <SummaryCardForBillingMilestones
          wip={wip?.data?.wip}
          isApprovedWIP={approvedWIP ? true : false}
          showEditButton={isEditable}
          onEdit={() => setOpenBillingModal(true)}
        />
      </ArBaseContainer>
      <div className="pb-16"></div>
      {IS_DEAL_DROPPED ? (
        <ArButton size="large" onClick={goBack} type={backButtonType}>
          {STRING_CONSTANTS.GO_BACK_BTN}
        </ArButton>
      ) : location.pathname.includes(APP_ROUTES.EDIT_WIP) ? (
        <>{FooterButtons}</>
      ) : (
        <CreateWipFooter
          prevButtonText={STRING_CONSTANTS.PREVIOUS_STEP}
          nextButtonText={STRING_CONSTANTS.SUBMIT_WIP_BTN}
          nextButtonProps={{ loading: isLoading }}
          nextButtonAction={runValidations}
          middleButtonAction={saveAsDraft}
        />
      )}

      <UpdateConsultingWipStatusModal
        openModal={openWIPStatusModal}
        toggleModal={toggleUpdateStateModal}
        projectId={projectId || ""}
        currentLocationId={wip?.data?.wip?.wipMetaData?.requirement?.brandRequirementDetailId}
        currentStatus={wip?.data?.wip?.status?.wipStatusId}
        submitState={submitState}
        submitStateAction={submitWIPStatus}
      />
      <WIPUpdateBillingMilestoneModal
        open={openBillingModal}
        toggleModal={toggleBillingMilestoneModal}
        value={wip?.data?.wip?.billingMilestones}
        submitState={submitState}
        submitStateAction={submitWIP}
        goBackAction={goBackActionForBmModal}
        wipType={"CONSULTING"}
        projectId={projectId || ""}
        approval={approval as IWIPApproval}
      />
    </div>
  );
};

export default ConsultingWIPSummary;
