import { ContactInfo } from "@anarock/ar-common-react/dist/ui-components/ar-brand-modals/index.interface";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type ContactModalState = {
  open: boolean;
  contactPersons: ContactInfo[];
};

const initialState: ContactModalState = {
  open: false,
  contactPersons: [],
};

const contactModalSlice = createSlice({
  name: "contactModal",
  initialState,
  reducers: {
    setContactModalOpen: (state, action) => {
      state.open = action.payload;
    },
    setContactPersons: (state, action) => {
      state.contactPersons = action.payload;
    },
  },
});

export const selectContactModalOpen = (state: RootState) => state.contactModal.open;
export const selectContactPersons = (state: RootState) => state.contactModal.contactPersons;
export const { setContactModalOpen, setContactPersons } = contactModalSlice.actions;
export default contactModalSlice.reducer;
