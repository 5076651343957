import { useArToast } from "@anarock/ar-common-react";
import { SyncOutlined } from "@ant-design/icons";
import React, { ReactNode, useContext } from "react";

type ToastContextType = {
  successToast: (message: string) => void;
  errorToast: (message: string) => void;
  inProgressToast: (message: string) => void;
};

const ToastContext = React.createContext<ToastContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  successToast: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  errorToast: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inProgressToast: (): void => {},
});

const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { openToast, toastContextHolder } = useArToast();
  const successToast = (message: string) => {
    openToast({
      message: message,
      type: "success",
      duration: 3,
      placement: "bottomLeft",
    }).showToast();
  };

  const errorToast = (message: string) => {
    openToast({
      message: message,
      type: "info",
      duration: 3,
      placement: "bottomLeft",
    }).showToast();
  };

  const inProgressToast = (message: string) => {
    openToast({
      message,
      type: "info",
      duration: 2,
      placement: "bottomLeft",
      icon: <SyncOutlined spin className={"sync"} />,
    }).showToast();
  };

  return (
    <ToastContext.Provider value={{ successToast, errorToast, inProgressToast }}>
      {children}
      {toastContextHolder}
    </ToastContext.Provider>
  );
};

const useToastService = () => {
  const { successToast, errorToast, inProgressToast } = useContext(ToastContext);

  return { successToast, errorToast, inProgressToast };
};

export { ToastProvider, useToastService };
