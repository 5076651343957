import { ArFormInputNode, ArInputType, inputType, nodeType, varientType } from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { Rule } from "antd/es/form";
import { convertToRupee, getFixedDigit } from "app/utils/helperFunctions";
import { numberRulesRequired } from "components/requirmentFormUi/rules";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "../../index.module.scss";

export const CaculatedBrokerageNode: (brandAmt: number, landlordAmt: number) => ArFormContainerNode = (
  brandAmt,
  landlordAmt
) => {
  const brandBrokerage = brandAmt;
  const llBrokerage = landlordAmt;

  return {
    type: nodeType.container,
    elementData: {
      innerContainerClassName: styles.staticDataBox,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        {
          type: nodeType.container,
          elementData: {
            body: (
              <span className={styles.emptyBoxContainer}>
                <span className={styles.emptyBox}></span>
              </span>
            ),
          },
        },
        {
          type: nodeType.container,
          elementData: {
            innerContainerClassName: `${styles.commercialInnerContainer}`,
            outerContainerClassName: styles.commercialOuterConatiner,
          },
          childNode: {
            type: nodeType.array,
            childNode: [
              {
                type: nodeType.container,
                elementData: {
                  body: (
                    <StaticValue
                      label={STRING_CONSTANTS.TOTAL_REVENUE}
                      value={getFixedDigit(brandBrokerage + llBrokerage)}
                    />
                  ),
                },
              },
              {
                type: nodeType.container,
                elementData: {
                  body: <StaticValue label={STRING_CONSTANTS.LL_REVENUE} value={getFixedDigit(llBrokerage)} />,
                },
              },
              {
                type: nodeType.container,
                elementData: {
                  body: <StaticValue label={STRING_CONSTANTS.BRAND_REVENUE} value={getFixedDigit(brandBrokerage)} />,
                },
              },
            ],
          },
        },
      ],
    },
  };
};

export const StaticValue = ({
  label,
  value,
  className,
  extraText,
}: {
  label: string;
  value: number;
  className?: string;
  extraText?: string;
}) => {
  return (
    <div {...classNames(styles.rentBox, className)}>
      <p className="text-regular-low">{label}</p>
      <p className="text-regular">{convertToRupee(value) + " " + (extraText ? extraText : "")}</p>
    </div>
  );
};

export const brokerageDayNode: (
  name: string,
  label: string,
  intialValue?: string | number,
  rule?: Rule
) => ArFormInputNode = (name, label, initialValue, rule) => ({
  type: nodeType.input,
  elementData: {
    inputType: inputType.text,
    formItemProps: {
      name: name,
      rules: rule ? [...numberRulesRequired, rule] : [...numberRulesRequired],
      initialValue,
    },
    inputProps: {
      type: ArInputType.number,
      label: label,
      varient: varientType.default,
      required: true,
      suffix: STRING_CONSTANTS.RUPEE_SYMBOL,
    },
  },
});
