import { ButtonTypes, inputType, nodeType } from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { convertToRupee } from "app/utils/helperFunctions";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "../../index.module.scss";

export const FYDetailsNode: (
  fy: string,
  landlordAmount: number,
  brandAmount: number,
  toggleDetails: () => void,
  visible?: boolean
) => ArFormContainerNode = (fy, landlordAmount, brandAmount, toggleDetails, visible) => {
  return {
    type: nodeType.container,
    elementData: {
      innerContainerClassName: styles.staticDataBox,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        {
          type: nodeType.container,
          elementData: {
            body: (
              <span className={styles.emptyBoxContainer}>
                <span className={styles.emptyBox}></span>
              </span>
            ),
          },
        },
        {
          type: nodeType.container,
          elementData: {
            innerContainerClassName: `${styles.commercialInnerContainer}`,
            outerContainerClassName: styles.commercialOuterConatiner,
          },
          childNode: {
            type: nodeType.array,
            childNode: [
              {
                type: nodeType.container,
                elementData: {
                  body: <StaticValue label={FORM_FIELDS.FINANCIAL_YEAR} value={fy} />,
                },
              },
              {
                type: nodeType.container,
                elementData: {
                  body: <StaticValue label={FORM_FIELDS.LL_BROKERAGE} value={`${convertToRupee(landlordAmount)}`} />,
                },
              },
              {
                type: nodeType.container,
                elementData: {
                  body: <StaticValue label={FORM_FIELDS.BRAND_BROKERAGE} value={`${convertToRupee(brandAmount)} `} />,
                },
              },
            ],
          },
        },
        {
          type: nodeType.input,
          elementData: {
            inputType: inputType.button,
            inputProps: {
              type: ButtonTypes.Link,
              children: visible ? STRING_CONSTANTS.HIDE_PROJECTION : STRING_CONSTANTS.SHOW_PROJECTION,
              size: "small",
              onClick: toggleDetails,
            },
          },
        },
      ],
    },
  };
};

export const StaticValue = ({ label, value }: { label: string; value: number | string }) => {
  const val = typeof value === "number" ? convertToRupee(value) : value;
  return (
    <div className={styles.rentBox}>
      <p className="text-regular-low">{label}</p>
      <p className="text-regular">{val}</p>
    </div>
  );
};

export const StaticValueNode = (label: string, value: number | string) => ({
  type: nodeType.container,
  elementData: {
    body: <StaticValue label={label} value={value} />,
  },
});
