import React, { ReactNode } from "react";

import styles from "./index.module.scss";
import { AppGlobalActionComponent, Footer, Header } from "./partials";

const ArLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={styles.arLayout}>
      <AppGlobalActionComponent />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default ArLayout;
