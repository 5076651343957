import { ArButton, CustomButtonProps } from "@anarock/ar-common-react";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { Empty } from "antd";
import React from "react";

import { ArEmptyProps } from "./index.interface";
import styles from "./index.module.scss";

export const ArEmptyState: React.FC<ArEmptyProps> = ({ emptyButton, emptyMessage, description, ...props }) => {
  const Button: React.FC = () => {
    return (
      <div className={styles.emptyText}>
        <h4>{emptyMessage}</h4>
        {emptyButton && <ArButton {...(emptyButton as CustomButtonProps)} />}
      </div>
    );
  };
  return (
    <Empty
      description={description ? description : <Button />}
      {...props}
      {...classNames(styles.emptyState, props.className)}
    />
  );
};
