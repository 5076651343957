import { ArBaseContainer, ArSteps } from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import { useAppSelector } from "app/hooks";
import { useGetConsultingWipDetailsQuery } from "app/services/consultingWip";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { WipPages } from "app/utils/wipPages";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { selectAuthUser } from "features/authentication/authSlice";
import WIPTabs from "features/wip/components/WIP-TABS";
import { getInfoStripForApproval, WipBreadCrumb } from "features/wip/partials";
import React, { useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "../../wip/index.module.scss";
import ConsultingWIPHeader from "../components/ConsultingWIPHeader";

const ConsultingWIPForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const showTabs =
    location?.pathname?.includes(APP_ROUTES.WIP_DOCUMENTS) ||
    location?.pathname?.includes(APP_ROUTES.WIP_SUMMARY) ||
    location?.pathname?.includes(APP_ROUTES.INVOICES);

  const isEditFlow = location?.pathname?.includes(APP_ROUTES.EDIT_WIP);
  const { data: enums } = useGetStaticEnumsQuery();

  const { data: wip } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });

  const user = useAppSelector(selectAuthUser);

  const IS_DEAL_DROPPED = wip?.data?.wip?.status?.wipStatusId === enums?.data?.wipStatus?.DROP_THE_DEAL;

  const color = IS_DEAL_DROPPED ? colors["coral-400"] : colors["primary-400"];

  const getCurrentStepperIdx = () => {
    const route = location?.pathname?.split("/")?.[5];
    if (route === APP_ROUTES.WIP_DOCUMENTS) {
      return 3;
    }
    const idx = WipPages.findIndex((value) => value === route);

    return idx;
  };

  const onStepperChange = (idx: number) => {
    const route =
      APP_ROUTES.WIP + "/" + APP_ROUTES.CONSULTING + APP_ROUTES.CREATE_WIP + "/" + projectId + "/" + WipPages[idx];
    navigate(route);
  };

  const InfoStrip = useMemo(
    () => (wip?.data?.wip?.draft ? null : getInfoStripForApproval(user?.subRoles, wip?.data?.wip?.approval)),
    [wip?.data?.wip?.approval, wip?.data?.wip?.draft, user?.subRoles]
  );
  return (
    <ArBaseContainer className={styles.wipWrapper}>
      <WipBreadCrumb path={isEditFlow ? STRING_CONSTANTS.EDIT_WIP : STRING_CONSTANTS.CREATE_WIP}></WipBreadCrumb>
      <ConsultingWIPHeader />
      {isEditFlow || IS_DEAL_DROPPED ? (
        <div className="pb-16"></div>
      ) : (
        <div>
          <ArSteps
            className={`${styles.stepsWrapper} py-16`}
            type="default"
            labelPlacement="vertical"
            onChange={onStepperChange}
            current={getCurrentStepperIdx()}
            items={[
              {
                title: STRING_CONSTANTS.CONTACT,
              },
              {
                title: STRING_CONSTANTS.COMMERCIAL_BROKERAGE,
              },
              {
                title: STRING_CONSTANTS.BROKERAGE_PROJECTION,
              },
              {
                title: STRING_CONSTANTS.SUMMARY,
              },
            ]}
          />
        </div>
      )}
      {showTabs ? (
        <>
          <div className={styles.infoStripWrapper}>{InfoStrip}</div>
          <div className="pb-16"></div>
          <WIPTabs color={color} showInvoices={wip?.data?.wip?.approval?.approvalStatus === "APPROVED"} />
        </>
      ) : (
        <Outlet />
      )}
      <div className="pb-16"></div>
    </ArBaseContainer>
  );
};

export default ConsultingWIPForm;
