import { useAppSelector } from "app/hooks";
import { showNotification } from "app/slice/toggleNotification";
import { selectUserDefaultRoute } from "features/authentication/authSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Notify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultRoute = useAppSelector(selectUserDefaultRoute);

  useEffect(() => {
    //show notifications here
    dispatch(showNotification());

    navigate(defaultRoute);
  }, [navigate, dispatch, defaultRoute]);

  return <React.Fragment></React.Fragment>;
};

export default Notify;
