import { isNullOrUndefiend, isStringEmpty } from "app/utils/helperFunctions";
import { DynamicApiUrlsInstance, SHOP_URLS } from "constants/ApiUrls";
import { IBasicShopDetails } from "constants/ShopStructure";

import { api, GenericResponse, paginationParams } from "./api";
import { IBrand } from "./createRequirmentApi";
import { ICity, IFilterListResponse, IFiltersList, ILocality } from "./listingPageApi";
import { IPaginationParam } from "./notificationsApi";

export interface IShopFiltersBody {
  selectedFilters: IFiltersList;
  query?: string;
  defaultVal?: boolean;
}

export interface IShopListParams extends paginationParams {
  draft?: boolean;
}

export interface IFilteredShopListParams extends IShopFiltersBody {
  params: IShopListParams;
  new?: boolean;
}

export interface IShopListing extends IBasicShopDetails {
  isNewlyAdded?: boolean;
}

export type IFilteredShopListResponse = GenericResponse<{
  shops: IShopListing[];
  paginationParams: IPaginationParam;
}>;

export interface IMatchingRequirementDetailsForShop {
  _id: string;
  locality: ILocality;
  city: ICity;
  brand: IBrand;
  isAddedToOptionList: false;
}

export type IMatchingRequiremetDetailsListResponse = GenericResponse<{
  brandRequirementDetails: IMatchingRequirementDetailsForShop[];
}>;

export interface IAddShopToMiltipleOptionListBody {
  shopId: string;
  brandRequirementDetailIds: string[];
}

export interface ISuggestedShop {
  _id: string;
  score: number;
  highlight: string[];
  poc: [
    {
      name: string;
      email: string;
      phoneNumber: number;
    }
  ];
  shopNumber: string;
  address: string;
  cityId: string;
  cityName: string;
  localityId: string;
  localityName: string;
}

export type IGetShopSuggestions = GenericResponse<{
  shopRes: ISuggestedShop[];
}>;

export type INewShopCountResponse = GenericResponse<{
  shopCount: number;
}>;

export const shopListingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShopFilters: build.query<IFilterListResponse, IShopFiltersBody>({
      query: (body) => ({
        url: SHOP_URLS.SHOP_FILTERS,
        body,
        method: "POST",
      }),
    }),
    getFilteredShops: build.query<IFilteredShopListResponse, IFilteredShopListParams>({
      query: ({ selectedFilters, params, query, new: newValue }) => ({
        url: SHOP_URLS.FILTERED_SHOPS + `?new=${newValue}`,
        body: {
          selectedFilters,
          query,
        },
        method: "POST",
        params,
        skip: isNullOrUndefiend(selectedFilters),
      }),
      providesTags: ["FilteredShops", "SelfUpdate"],
    }),
    getDraftShops: build.query<IFilteredShopListResponse, IFilteredShopListParams>({
      query: ({ selectedFilters, params }) => ({
        url: SHOP_URLS.FILTERED_SHOPS,
        body: {
          selectedFilters,
        },
        method: "POST",
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { selectedFilters } = queryArgs;
        return { selectedFilters };
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems?.data?.paginationParams?.previous === null;
        if (newItems?.success && currentCache?.data?.shops && newItems?.data && !isPageOne) {
          currentCache.data?.shops?.push(...(newItems?.data?.shops || []));
          currentCache.data.paginationParams = newItems?.data?.paginationParams;
        } else {
          return newItems;
        }
      },
      providesTags: ["FilteredShops", "SelfUpdate"],
    }),
    getMatchingRequiremetDetails: build.query<IMatchingRequiremetDetailsListResponse, string>({
      query: (shopId) => ({
        url: DynamicApiUrlsInstance.matchingBrands(shopId),
      }),
      providesTags: ["MultipleOptionsList"],
    }),
    addShopToMultipleOptionsList: build.mutation<
      GenericResponse<{ optionListDocs: any }>,
      IAddShopToMiltipleOptionListBody
    >({
      query: ({ brandRequirementDetailIds, shopId }) => ({
        url: DynamicApiUrlsInstance.addShopToMultipleOptionList(shopId),
        body: {
          brandRequirementDetailIds,
        },
        skip: isStringEmpty(shopId),
        method: "POST",
      }),
      invalidatesTags: ["FilteredShops", "MultipleOptionsList"],
    }),
    getSearchSuggestions: build.mutation<IGetShopSuggestions, string>({
      query: (query) => ({
        url: "shop/search/auto-suggest",
        method: "POST",
        body: {
          query,
          limit: 7,
        },
      }),
    }),
  }),
});

export const {
  useGetShopFiltersQuery,
  useGetFilteredShopsQuery,
  useGetDraftShopsQuery,
  useGetMatchingRequiremetDetailsQuery,
  useAddShopToMultipleOptionsListMutation,
  useGetSearchSuggestionsMutation,
} = shopListingApi;
