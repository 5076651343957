import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { ReactComponent as AnarockLogo } from "assets/images/anarockLogo.svg";
import React, { ReactNode } from "react";

import styles from "./index.module.scss";

export const AuthLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={"display-flex-column-center"}>
      <header {...classNames(styles.authHeader)}>
        <AnarockLogo />
      </header>
      {children}
    </div>
  );
};
