import { ArButton, ArTag, ButtonTypes } from "@anarock/ar-common-react";
import { EditOutlined } from "@ant-design/icons";
import { IWipResponse } from "app/services/wip";
import { getValueOrDash, isNotEqual } from "app/utils/helperFunctions";
import { IWip } from "app/utils/interfaces/index.interface";
import { ShopFeature } from "components/shopDetailComponents/basicImageInfoCard";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "../../../wip/components/WIP-Summary/index.module.scss";
import { BrokerageProjectionComponent, SummaryCard } from "../../../wip/components/WIP-Summary/partials";
import IConsultingWIPSummaryCardsProps, { ISinglePOCDetailsProps } from "./index.interface";

export const ConsultingWIPSummaryCards = ({
  BrandPocData,
  BrandPocDataApproved,
  approvedWIP,
  contactDetailsChangeCount,
  LLContactDetailsChangeCount,
  setPocModal,
  IS_DEAL_DROPPED,
  wip,
  openBrokerAgeModal,
  brokerageDetailsChangeCount,
  CommercialDetails,
  ApprovedCommercialDetails,
  commercialDetailsChangeCount,
  setCommercialModal,
  LLPOCDataApproved,
  LLPocData,
  showEditButton,
}: IConsultingWIPSummaryCardsProps) => {
  const billingClient = wip?.data?.wip?.wipMetaData?.billingClient;
  const commercialDetailsArr =
    billingClient === STRING_CONSTANTS.BRAND && CommercialDetails?.[0] && CommercialDetails?.[2]
      ? [CommercialDetails?.[0], CommercialDetails?.[2]]
      : billingClient === STRING_CONSTANTS.LANDLORD && CommercialDetails?.[0] && CommercialDetails?.[1]
      ? [CommercialDetails?.[0], CommercialDetails?.[1]]
      : [...(CommercialDetails || [])];

  const appCommercialDetailsArr =
    billingClient === STRING_CONSTANTS.BRAND && ApprovedCommercialDetails?.[0] && ApprovedCommercialDetails?.[2]
      ? [ApprovedCommercialDetails?.[0], ApprovedCommercialDetails?.[2]]
      : billingClient === STRING_CONSTANTS.LANDLORD && ApprovedCommercialDetails?.[0] && ApprovedCommercialDetails?.[1]
      ? [ApprovedCommercialDetails?.[0], ApprovedCommercialDetails?.[1]]
      : [...(ApprovedCommercialDetails || [])];

  return (
    <>
      {billingClient === STRING_CONSTANTS.BRAND ? (
        <SummaryCard
          heading={STRING_CONSTANTS.ONE_BRAND_CD}
          data={BrandPocData?.flat() || []}
          approvedData={BrandPocDataApproved?.flat() || []}
          isApprovedWIP={approvedWIP ? true : false}
          changeCount={contactDetailsChangeCount}
          onEdit={() => setPocModal(true)}
          showEditButton={showEditButton}
        />
      ) : billingClient === STRING_CONSTANTS.LANDLORD ? (
        <SummaryCard
          heading={STRING_CONSTANTS.ONE_LL_CD}
          data={LLPocData?.flat() || []}
          approvedData={LLPOCDataApproved?.flat() || []}
          isApprovedWIP={approvedWIP ? true : false}
          changeCount={LLContactDetailsChangeCount}
          onEdit={() => setPocModal(true)}
          showEditButton={showEditButton}
        />
      ) : (
        <BrandAndLLContacts
          showEditButton={showEditButton}
          openModal={() => setPocModal(true)}
          contactChangeCount={(LLContactDetailsChangeCount || 0) + (contactDetailsChangeCount || 0)}
          approvedWIP={approvedWIP}
          wip={wip}
        />
      )}
      <SummaryCard
        heading={STRING_CONSTANTS.WIP_SUMMARY_COMMERCIAL}
        data={commercialDetailsArr}
        approvedData={appCommercialDetailsArr}
        isApprovedWIP={approvedWIP ? true : false}
        changeCount={commercialDetailsChangeCount}
        onEdit={() => setCommercialModal(true)}
        showEditButton={showEditButton}
      />

      <BrokerageProjectionComponent
        showEditButton={showEditButton}
        openBrokerAgeModal={openBrokerAgeModal}
        brokerageDetailsChangeCount={brokerageDetailsChangeCount}
        approvedWIP={approvedWIP}
        wip={wip}
      />
    </>
  );
};

export const BrandAndLLContacts = ({
  showEditButton,
  openModal,
  contactChangeCount,
  approvedWIP,
  wip,
}: {
  showEditButton: boolean;
  openModal: () => void;
  contactChangeCount: number;
  approvedWIP?: IWip;
  wip?: IWipResponse;
}) => {
  return (
    <div className={styles.summaryCard}>
      <div className={styles.heading}>
        <h4>{STRING_CONSTANTS.ONE_BOTH_CD}</h4>
        {showEditButton && (
          <ArButton onClick={openModal} type={ButtonTypes.Link}>
            <EditOutlined />
            {contactChangeCount ? STRING_CONSTANTS.EDIT_CHANGES : STRING_CONSTANTS.EDIT}
          </ArButton>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.proposedDataWrapper}>
          {approvedWIP ? (
            <div className="pb-16">
              <ArTag type="small" varient="extended-primary">
                {STRING_CONSTANTS.PROPOSED_CHANGES} ({contactChangeCount})
              </ArTag>
            </div>
          ) : null}
          <div className={styles.flexAlignment}>
            <div className="pb-16">
              <p className="hero-text-medium pb-16">{STRING_CONSTANTS.LANDLORD_S}</p>
              {wip?.data?.wip?.landlord?.poc?.length
                ? wip?.data?.wip?.landlord?.poc?.map((poc, index) => (
                    <SinglePOCDetails index={index} key={index} {...poc} approvedWIP={approvedWIP} />
                  ))
                : "-"}
              <div className="pb-16"></div>
              <p className="hero-text-medium pb-16">
                {getValueOrDash(wip?.data?.wip?.brand?.brandName)} (
                {getValueOrDash(wip?.data?.wip?.brand?.legalEntityName)})
              </p>
              {wip?.data?.wip?.brand?.poc?.length
                ? wip?.data?.wip?.brand?.poc?.map((poc, index) => (
                    <SinglePOCDetails index={index} key={index} {...poc} approvedWIP={approvedWIP} />
                  ))
                : "-"}
            </div>
          </div>
        </div>
        {approvedWIP && contactChangeCount ? (
          <div className={styles.approvedDataWrapper}>
            <div className="pb-16">
              <ArTag type="small" varient="warning">
                {STRING_CONSTANTS.ORIGINAL_VALUES}
              </ArTag>
            </div>
            <div className={styles.flexAlignment}>
              <div className="pb-16">
                <p className="hero-text-medium pb-16">{STRING_CONSTANTS.LANDLORD_S}</p>
                {approvedWIP?.landlord?.poc?.map((poc, index) => (
                  <SinglePOCDetails index={index} key={index} {...poc} />
                ))}
                <div className="pb-16"></div>
                <p className="hero-text-medium pb-16">
                  {getValueOrDash(approvedWIP?.brand?.brandName)} ({getValueOrDash(approvedWIP?.brand?.legalEntityName)}
                  )
                </p>
                {approvedWIP?.brand?.poc?.map((poc, index) => (
                  <SinglePOCDetails index={index} key={index} {...poc} />
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SinglePOCDetails = ({ approvedWIP, index, name, email, phoneNumber, designation }: ISinglePOCDetailsProps) => {
  return (
    <div className={styles.detailContainer + " " + styles.noBorder + " "}>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.NAME_LABEL + " " + (index + 1)}
          value={name}
          className={
            approvedWIP ? (isNotEqual(name, approvedWIP?.landlord?.poc?.[index]?.name || "") ? "bold" : "") : ""
          }
        />
      </div>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.EMAIL_LABEL + " " + (index + 1)}
          value={email}
          className={
            approvedWIP ? (isNotEqual(email, approvedWIP?.landlord?.poc?.[index]?.email || "") ? "bold" : "") : ""
          }
        />
      </div>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.PHONE_NUMBER_LABEL + " " + (index + 1)}
          value={getValueOrDash(phoneNumber?.number) as string}
          className={
            approvedWIP
              ? isNotEqual(phoneNumber?.number, approvedWIP?.landlord?.poc?.[index]?.phoneNumber?.number || "")
                ? "bold"
                : ""
              : ""
          }
        />
      </div>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.DESIGNATION_LABEL + " " + (index + 1)}
          value={designation}
          className={
            approvedWIP
              ? isNotEqual(designation, approvedWIP?.landlord?.poc?.[index]?.designation || "")
                ? "bold"
                : ""
              : ""
          }
        />
      </div>
    </div>
  );
};
