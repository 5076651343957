import { ArTabs, locationRequirement as locationRequirementType } from "@anarock/ar-common-react";
import { useAppSelector } from "app/hooks";
import { useGetMatchingPropertiesQuery } from "app/services/matchingPropertiesApi";
import { useGetOptionsListQuery } from "app/services/optionListApi";
import { useGetDraftShopsQuery } from "app/services/shopListingApi";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { pageLimit } from "constants/pagination";
import { APP_ROUTES } from "constants/Routes";
import { OPTION_LIST_TAB } from "constants/Taboptions";
import React, { ReactNode, useEffect, useMemo } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import styles from "./index.module.scss";
import { selectCurrentRequirement, selectCurrentRequirementLocation } from "./requirementSlice";

export interface currentLocationType {
  selectedLocation: string;
  locationRequirements: locationRequirementType[];
}

export const ShopList: React.FC<{ children: ReactNode[] | ReactNode }> = ({ children }) => {
  return <div className={styles.shopListLayout}>{children}</div>;
};

const ShopListLayout: React.FC = () => {
  const currentLocationId = useAppSelector(selectCurrentRequirementLocation);
  const currentRequirement = useAppSelector(selectCurrentRequirement);
  const currentLocality = currentRequirement?.requirementDetails?.find(
    (requirement) => requirement?._id === currentLocationId
  )?.locality;
  const {
    data: optionsListApiRes,
    isError: optionListError,
    isFetching: fetchingOptionsList,
    isLoading: loadingOptionsList,
  } = useGetOptionsListQuery(
    {
      reqId: currentLocationId as string,
      params: {
        pageLimit: 100,
        pageNo: 1,
      },
    },
    {
      skip: isNullOrUndefiend(currentLocationId),
    }
  );
  const {
    data: matchingShopListResponse,
    isError: matchingShopsError,
    isFetching: fetchingMatchingShops,
    isLoading: loadingMatchingShops,
  } = useGetMatchingPropertiesQuery(
    {
      reqId: currentLocationId as string,
      params: {
        pageLimit: pageLimit,
        pageNo: 1,
      },
    },
    {
      skip: isNullOrUndefiend(currentLocationId),
    }
  );
  const {
    data: draftShopsResponse,
    isError: draftShopsError,
    isFetching: fetchingDraftShops,
    isLoading: loadingDraftShops,
  } = useGetDraftShopsQuery({
    selectedFilters: {
      LOCALITY: currentLocality ? [currentLocality._id] : [],
    },
    params: {
      pageLimit: pageLimit,
      pageNo: 1,
      draft: true,
    },
  });
  useQueryApiError(optionListError, loadingOptionsList, fetchingOptionsList, optionsListApiRes);
  useQueryApiError(matchingShopsError, loadingMatchingShops, fetchingMatchingShops, matchingShopListResponse);
  useQueryApiError(draftShopsError, loadingDraftShops, fetchingDraftShops, draftShopsResponse);
  const matchingPropertiesCount = useMemo(
    () => matchingShopListResponse?.data?.paginationParams?.count || 0,
    [matchingShopListResponse]
  );
  const optionListLength = useMemo(() => optionsListApiRes?.data?.paginationParams?.count || 0, [optionsListApiRes]);
  const draftShopsLength = useMemo(() => draftShopsResponse?.data?.paginationParams?.count || 0, [draftShopsResponse]);
  const tabOptions = [
    {
      key: OPTION_LIST_TAB.OPTION_LIST,
      label: `${OPTION_LIST_TAB.OPTION_LIST_LABEL} (${optionListLength})`,
    },
    {
      key: OPTION_LIST_TAB.MATCHING_LIST,
      label: `${OPTION_LIST_TAB.MATCHING_LIST_LABEL} (${matchingPropertiesCount})`,
    },
    {
      key: OPTION_LIST_TAB.DRAFT,
      label: `${OPTION_LIST_TAB.DRAFT_LABEL} (${draftShopsLength})`,
    },
  ];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname.split("/")?.[2];
    if (!path) {
      navigate({
        pathname: APP_ROUTES.MATCHING_PROPERTIES,
        search: `?filters=${searchParams.get("filters") || "{}"}`,
      });
    }
  }, []);

  const handleClick = (key: string) => {
    switch (key) {
      case OPTION_LIST_TAB.OPTION_LIST: {
        navigate({
          pathname: APP_ROUTES.OPTION_LIST,
          search: `?filters=${searchParams.get("filters") || "{}"}`,
        });
        break;
      }
      case OPTION_LIST_TAB.MATCHING_LIST: {
        navigate({
          pathname: APP_ROUTES.MATCHING_PROPERTIES,
          search: `?filters=${searchParams.get("filters") || "{}"}`,
        });
        break;
      }
      case OPTION_LIST_TAB.DRAFT: {
        navigate({
          pathname: APP_ROUTES.DRAFT_PROPERTIES,
          search: `?filters=${searchParams.get("filters") || "{}"}`,
        });
        break;
      }
      default: {
        navigate({
          pathname: APP_ROUTES.MATCHING_PROPERTIES,
          search: `?filters=${searchParams.get("filters") || "{}"}`,
        });
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <ArTabs
        className={styles.tab}
        variant="anarock"
        items={tabOptions}
        activeKey={location.pathname.split("/")[2]}
        onTabClick={handleClick}
        onChange={handleClick}
      />
      <Outlet />
    </React.Fragment>
  );
};

export default ShopListLayout;
