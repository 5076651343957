export enum TOAST_MESSAGES {
  REJECT_AND_SELECT_STATUS = "Cannot select status and reject all shops at the same time.",
  MOVING_MULTIPLE_SHOPS_TO_LOI_SIGNED = "Cannot move more than one shop to LOI Signed",
  SHOP_ALREADY_IN_OPTIONS_LIST = "Shop is already in the options list.",
  SHOP_UPDATED_SUCCESSFULLY = "Shops status updated successfully",
  SHOPS_REJECTED_SUCCESSFULLY = "Other shops marked as rejected successfully.",
  OPTION_LIST_CREATED_SUCCESSFULLY = "Shops status updated successfully",
  MESSAGE_COPIED = "Copied to clipboard",
  COPIED_SHOP_ID = "Copied shop ID",
  SHOP_ADDED_TO_OPTION_LIST = "Shop added to option lists successfully",
  OPTIONS_SHARED_SUCCESSFULLY = "Options shared successfully",
  OPTIONS_DELETED_SUCCESSFULLY = "Options deleted successfully",
  REQUEST_SENT_SUCCESSFULLY = "Request sent successfully",
  ERROR_IN_GENERATING_BROCHURE = "Error in generating brochure",
  STATUS_UPDATED = "Shop status updated successfully",
  CLIPBOARD_NOT_FOUND = "Clipboard not found.",
  NOT_ACCESSIBLE = "You are not allowed to perform this action.",
  ENTER_VALID_DATA = "Form data is invalid.",
  FAILED_TO_GENERATE_URL = "Failed to generate url.",
  FAILED_TO_UPLOAD_IMAGE = "Failed to upload image.",
  USER_LOGGED_OUT_SUCCESSFULLY = "User logged out successfully.",
  DOCUMENT_UPLOADED_SUCCESSFULLY = "Document uploaded successfully.",
  DOCUMENT_DELETED_SUCCESSFULLY = "Document deleted successfully.",
  UPLOAD_REQUIRED_DOCUMENTS = "Please upload required documents.",
  SAVED_POC_DATA = "POC Data Saved Successfully.",
  ERROR_POC_DATA = "Error saving data for POC",
  SAVED_COMMERCIAL_DATA = "Commercial Data Saved Successfully.",
  ERROR_COMMERCIAL_DATA = "Error saving data for Commercial details.",
  SAVED_BROKERAGE_DATA = "Brokerage Data Saved Successfully.",
  ERROR_BROKERAGE_DATA = "Error saving data for Brokerage details.",
  WIP_DRAFT_SAVED_SUCCESS = "WIP details saved in draft successfully.",
  WIP_SAVED_SUCCESS = "WIP details saved successfully.",
  WIP_SAVED_ERROR = "Error saving WIP Details",
  SAVED_BILLING_MILESTONE = "Billing Milestones Saved Successfully.",
  ERROR_BILLING_MILESTONE = "Error saving data for Billing Milestones.",
  STATUS_UPDATED_SUCCESSFULLY = "Status updated successfully.",
  COMMENT_REQUIRED = "Please mention reason why deal is dropping.",
  ERROR_FETCHING_SHOPS = "Error fetching shops.",
  ERROR_FETCHING_MATCHING_SHOPS = "Error fetching matching shops.",
  ERROR_FETCHING_NEARBY_SHOPS = "Error fetching nearby shops.",
  PLEASE_ENTER_DETAILS = "Please enter all the required details.",
  COMMISSION_SUM_ERROR = "Sum of all Commission % allocation must be 100%",
  PLEASE_ENTER_AMOUNT = "Please enter amount to transfer",
  TRANFER_LESS_THAN_TOTAL = "Transfer amount should be less than total amount in month.",
  PLEASE_SELECT_MONTH = "Please select month to transfer amount",
  PLEASE_SELECT_ATLEAST_ONE_CHECKBOX = "Please select atleast one checkbox",
  OKAY_QUERY_LESS_TANT_TOTAL = "Okay + Query should be less than total amount.",
  OKAY_QUERY_TENTATIVE_LESS_TANT_TOTAL = "Okay + Query + Tentativee should be less than total amount.",
  PLEASE_SELECT_CITY = "Please select the city first to see high street options",
  ERROR_FREEZING_PROJECTION = "Error freezing projection. Please try again later.",
  PROJECTION_FREEZED_SUCCESSFULLY = "Projection freezed successfully.",
  COMPLETION_DATE_UPDATED = "Billing Milestone completion date is updated.",
  ERROR_UPDATING_COMPLETION = "Error updating completion date.",
  DEAL_DROPPED_SUCCESSFULLY = "Deal Dropped Successfully",
  DEAL_REVIVED_SUCCESSFULLY = "Deal Revived Successfully!",
  WIP_APPROVED_SUCCESSFULLY = "WIP/Changes approved successfully! You can now check this WIP in approved section.",
  WIP_DECLINED_SUCCESSFULLY = "WIP/Changes declined successfully, and the reasons have been sent to the brand manager.",
  SAVED_COMMENT = "Comment Saved Successfully.",
  ERROR_COMMENT = "Error saving data for Comment.",
  SHEET_ID_NOT_AVLBL = "Sheet ID not available",
  UNABLE_TO_SYNC_SHEET = "Unable to Sync Sheet data",
  ERROR_FETCHING_SHEET_DATA = "Error fetching sheet data.",
  SYNC_SUCCESS = "All The matching WIP values updated.",
  SHEET_GENERATION_IN_PROGRESS = "Sheet generation in progress. Opening in the next tab shortly.",
  ERROR_EXPORTING_SHEET = "Error exporting sheet data",
  COLLECTION_SYNC_SUCCESS = "All The matching deals outstanding, collected and Projection amount is updated ",
  ERROR_UPDATING_PROJECTION = "Error upadting Projection Details.",
  PROJECTION_DEATILS_UPDATED = "Projection Details updated.",
  VALIDATIONS_ERROR = "Validation Error",
  PLEASE_SELECT_CITY_TO_SEE_MALL = "Please select the City first to see Mall options",
  LOCALITY_CREATED = "Locality Created",
  LOCALITY_UPDATED = "Locality Updated",
  ENTER_LOCALITY_NAME = "Please Enter Locality name",
  DRAFT_DELETED_SUCCESSFULLY = "Draft Deleted Succesfully",
  REQUEST_REVOKE_SUCCESS = "Request Revoked Successfully",
  REQUEST_SUBMITTED_SUCCESSFULLY = "Request Submitted Succesfully",
  REQUEST_APPROVED_SUCCESSFULLY = "Request Approved Succesfully",
  REQUEST_DECLINED_SUCCESSFULLY = "Request Declined Succesfully",
  UNABLE_TO_IMPORT = "Unable to Import",
  INVOICE_ID_AND_DATES_ARE_MANDATORY = "Invoice no. and dates are mandatory",
  SAVED_IRF_CONTACT_DETAILS = "IRF Contact Details Saved Successfully",
  SAVED_IRF_BROKERAGE_DETAILS = "IRF Property & Brokerage Details Saved Successfully",
  PLEASE_UPLOAD_DOCUMENTS_TO_PROCEED = "Please Upload Mandatory Documents to proceed",
  ERROR_FETCHING_WIP_DETAILS = "Error Fetching WIP Details",
  PAYMENT_DATE_ERROR = "Please enter a valid Payment Date",
  ERROR_GENERATING_CLAIM = "Error Generating Payment Claim",
  UPLOAD_PP_DOCUMENTS = "Please upload Payment Proof Documents",
  CLAIM_REQ_SUCCESS = "Claim request sent successfully",
  PLEASE_SELECT_CP = "Please select Credit Period",
  PLEASE_ADD_CP = "Please add Credit Period",
  CP_UPDATED_SUCCESSFULLY = "Credit Period Updated Successfully.",
  SELECT_REV_REV_MONTH = "Please select revenue recognition month",
  SELECT_GST = "Please select GST Number.",
  DRAFT_WIP_DELETED = "Draft WIP deleted successfully.",
  WIP_DELETE_ERROR = "Error deleting Draft WIP.",
}
