import { ArButton, ArCheckbox, ArRadio, ButtonTypes } from "@anarock/ar-common-react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { FILTER_TYPES } from "constants/ListingEnums";
import { OVERVIEW_ENUMS } from "constants/overview";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import { FilterProps, OverviewFilterProps } from "./index.interface";
import styles from "./index.module.scss";

export const GetFilter: React.FC<FilterProps> = (props) => {
  const { type } = props;
  switch (type) {
    case FILTER_TYPES.SINGLE_SELECT:
      return <RadioGroupFilter {...props} />;
    case FILTER_TYPES.MULTI_SELECT:
      return <CheckBoxGroupFilter {...props} />;
    default:
      return <RadioGroupFilter {...props} />;
  }
};

export const useHandleSeemore = () => {
  const [seeMore, setSeeMore] = React.useState(true);
  const getSeeMoreProps = (numOptions: number) => {
    const showSeemore = numOptions > 4;
    const handleSeeMore = () => {
      setSeeMore((prev) => !prev);
    };
    const SeeMoreButton = (
      <ArButton onClick={() => handleSeeMore()} type={ButtonTypes.Link}>
        {seeMore ? STRING_CONSTANTS.SEE_MORE : STRING_CONSTANTS.SEE_LESS}
      </ArButton>
    );
    return { showSeemore, seeMore, SeeMoreButton };
  };
  return { getSeeMoreProps };
};

export const CheckBoxGroupFilter: React.FC<FilterProps> = ({
  data,
  defaultValue,
  selected,
  title,
  hasAll = false,
  onFilterChange,
  id,
}) => {
  const { getSeeMoreProps } = useHandleSeemore();
  const handleChange = (value: CheckboxValueType[]) => {
    onFilterChange?.(value, id);
  };
  const options = data?.map((item) => {
    return { label: item?.title, value: item?.id };
  });
  const { showSeemore, seeMore, SeeMoreButton } = getSeeMoreProps(options?.length);
  return (
    <div className={styles.filter}>
      <h4>{title}</h4>
      <ArCheckbox.Group
        defaultValue={defaultValue as CheckboxValueType[]}
        value={(selected || defaultValue) as CheckboxValueType[]}
        hasAll={hasAll}
        onChange={handleChange}
        options={seeMore ? options?.splice(0, 4) : options}
      />
      {showSeemore && SeeMoreButton}
    </div>
  );
};

export const RadioGroupFilter: React.FC<FilterProps> = ({
  data,
  defaultValue,
  selected,
  title,
  onFilterChange,
  id,
}) => {
  const { getSeeMoreProps } = useHandleSeemore();
  const handleChange = (value: CheckboxValueType) => {
    onFilterChange?.(value, id);
  };
  const options = data?.map((item) => {
    return { label: item?.title, value: item?.id };
  });
  const { showSeemore, seeMore, SeeMoreButton } = getSeeMoreProps(options?.length);
  return (
    <div className={styles.filter}>
      <h4>{title}</h4>
      <ArRadio.Group
        className={styles.filterData}
        defaultValue={defaultValue as CheckboxValueType[]}
        value={(selected || defaultValue) as CheckboxValueType[]}
        onChange={handleChange}
        options={seeMore ? options.splice(0, 4) : options}
      />
      {showSeemore && SeeMoreButton}
    </div>
  );
};

export const OverviewFilters: React.FC<OverviewFilterProps> = ({ filters, onFilterChange }) => {
  return (
    <div className={styles.filtersWrapper}>
      <h4>{OVERVIEW_ENUMS.FILTERS}</h4>
      {filters?.map((filter, index) => (
        <GetFilter key={index} {...filter} onFilterChange={onFilterChange} />
      ))}
    </div>
  );
};
