export const monthsArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthsTentativeArr = [
  "January",
  "January Tentative",
  "February",
  "February Tentative",
  "March",
  "March Tentative",
  "April",
  "April Tentative",
  "May",
  "May Tentative",
  "June",
  "June Tentative",
  "July",
  "July Tentative",
  "August",
  "August Tentative",
  "September",
  "September Tentative",
  "October",
  "October Tentative",
  "November",
  "November Tentative",
  "December",
  "December Tentative",
];

export const FYMonthsArr = [
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
];
