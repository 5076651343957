import { ArBrandModals, ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { ArRadioOptionsModalProps } from "@anarock/ar-common-react/dist/ui-components/ar-brand-modals/index.interface";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useState } from "react";

import { AddBrandPOCModal } from "./partials";

interface BrandPOCListProps extends ArRadioOptionsModalProps {
  clientId: string | undefined;
  type: string | undefined;
}

const BrandPOCListModal: React.FC<BrandPOCListProps> = (props) => {
  const [BrandPOCModalOpen, setBrandPOCModalOpen] = useState(false);
  const [open, setOpen] = useState(props.open || false);
  useEffect(() => {
    setOpen(props.open || false);
    setBrandPOCModalOpen(false);
  }, [props.open]);
  const toggleBrandPOCModal = () => {
    setBrandPOCModalOpen(!BrandPOCModalOpen);
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ArBrandModals.ArRadioOptionsModal
        footerButtons={[
          <ArButton key="1" type={ButtonTypes.Secondary} size="large" onClick={toggleBrandPOCModal}>
            {STRING_CONSTANTS.ADD_NEW_POC}
          </ArButton>,
        ]}
        {...props}
        open={open}
      />
      <AddBrandPOCModal
        title={STRING_CONSTANTS.ADD_NEW_BRAND_POC}
        open={BrandPOCModalOpen}
        onCancel={toggleBrandPOCModal}
        clientId={props?.clientId}
        type={props?.type}
      />
    </React.Fragment>
  );
};

export default BrandPOCListModal;
