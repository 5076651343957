import { ButtonTypes, PropertyListing, PropertyListingProps } from "@anarock/ar-common-react";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  IMatchingPropertyRequest,
  IMatchingShopDetail,
  useLazyGetMatchingPropertiesQuery,
} from "app/services/matchingPropertiesApi";
import { getFormattedTimeString } from "app/utils/helperFunctions";
import { useCheckIntersectionHook } from "app/utils/infiniteScrollHook";
import { transFormShopProps } from "app/utils/shopPropsTransformer";
import noMatchingShops from "assets/images/noMatchingProperties.png";
import { ArEmptyState } from "components/emptyState";
import { useToastService } from "components/toastService";
import { SHOP_STATUS } from "constants/ListingEnums";
import { APP_ROUTES } from "constants/Routes";
import { IShopStatus } from "constants/ShopStructure";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import moment from "moment";
import React from "react";

import { ShopList } from "../requiremnetListing/shopListLayout";
import { selectMoveToOptionList, setMoveToOptionList } from "./createOptionListSlice";
import { MatchingPropertiesListProps, NearbyPropertiesListProps } from "./index.interface";
import styles from "./index.module.scss";

export const updateStatus = (shopStatus: IShopStatus, completedAt: string, updatedAt: string, draft?: boolean) => {
  if (draft) {
    return (
      <span className={"sub-hero-text-regular-medium"}>
        {STRING_CONSTANTS.LAST_EDITED} {getFormattedTimeString(updatedAt)}.
      </span>
    );
  }
  const isNewlyCreated = shopStatus?.lastUpdated == null || moment(completedAt).isSameOrAfter(shopStatus?.lastUpdated);

  switch (shopStatus?.shopTag) {
    case SHOP_STATUS.UPDATED:
      return (
        <span className={styles.shopUpdated}>
          <CheckCircleOutlined className={styles.updatedIcon} />{" "}
          {isNewlyCreated ? STRING_CONSTANTS.CREATED : STRING_CONSTANTS.UPDATED}{" "}
          {getFormattedTimeString(shopStatus?.lastUpdated)}.
        </span>
      );
    case SHOP_STATUS.EXPIRED:
      return (
        <span className={styles.shopExpired}>
          <ClockCircleOutlined className={styles.updatedIcon} />{" "}
          {isNewlyCreated ? STRING_CONSTANTS.CREATED : STRING_CONSTANTS.UPDATED}{" "}
          {getFormattedTimeString(shopStatus?.lastUpdated)}.
        </span>
      );
    default:
      return (
        <span className={styles.shopUpdated}>
          <CheckCircleOutlined className={styles.updatedIcon} />{" "}
          {isNewlyCreated ? STRING_CONSTANTS.CREATED : STRING_CONSTANTS.UPDATED}{" "}
          {getFormattedTimeString(shopStatus?.lastUpdated)}.
        </span>
      );
  }
};

export const useGetMatchingShopsFromLazyQuery = () => {
  const [getMatchingShopsQuery, { isFetching, isLoading, ...rest }] = useLazyGetMatchingPropertiesQuery();
  const getMatchingShops = async (params: IMatchingPropertyRequest) => {
    const matchingShops = await getMatchingShopsQuery(params).unwrap();
    const { data, errors, reason, success } = matchingShops;
    return { ...data, errors, reason, success };
  };
  return { getMatchingShops, isFetching, isLoading, ...rest };
};

export const NearbyPropertiesList: React.FC<NearbyPropertiesListProps> = ({
  shops,
  isLoading,
  isFetching,
  hide,
  fetchMoreData,
  lastElementRef,
}) => {
  useCheckIntersectionHook(lastElementRef, fetchMoreData);

  if (hide) {
    return null;
  }
  return (
    <>
      <div className={styles.nearbyShopList}>
        <ShopList>
          <p {...classNames("sub-hero-text", shops.length === 0 ? "pb-16" : "", styles.otherText)}>
            Other Nearby Properties
          </p>
          {shops?.map((shop, index: number) => (
            <div key={index} className={styles.shopCard} ref={index === shops?.length - 3 ? lastElementRef : null}>
              <MatchingShop {...shop} />
            </div>
          ))}
        </ShopList>
        {(isLoading || isFetching) && <Spin tip={STRING_CONSTANTS.FETCHING_MATCHING_PROPERTIES} />}
      </div>
      {!isFetching && shops?.length === 0 ? (
        <ArEmptyState image={noMatchingShops} emptyMessage={STRING_CONSTANTS.NO_NEARBY_PROPERTY} />
      ) : null}
    </>
  );
};

export const MatchingShop: React.FC<IMatchingShopDetail> = (shop) => {
  const { errorToast } = useToastService();
  const dispatch = useAppDispatch();
  const addedShops = useAppSelector(selectMoveToOptionList);
  const { _id, isAddedToOptionList } = shop;
  const { shopImages, propertyDetails, currentStatus } = transFormShopProps(shop);
  const onClickAddProperty = () => {
    if (isAddedToOptionList) {
      errorToast(TOAST_MESSAGES.SHOP_ALREADY_IN_OPTIONS_LIST);
    } else {
      dispatch(setMoveToOptionList(_id));
    }
  };

  const onCardClick = () => {
    window.open(APP_ROUTES.PROPERTIES + "/" + shop._id, "_blank");
  };
  const shopProps: PropertyListingProps = {
    onClickAddProperty,
    shopAdded: isAddedToOptionList || addedShops[_id],
    propertyImage: {
      shopImages,
    },
    propertyDetails,
    currentStatus,
    onCardClick,
  };
  return (
    <div>
      <PropertyListing {...shopProps} />
    </div>
  );
};

export const MatchingPropertiesList: React.FC<MatchingPropertiesListProps> = ({
  shops,
  isLoading,
  isFetching,
  handleRequestAddProperties,
  emptyMessage,
  lastElementRef,
  fetchMoreData,
}) => {
  useCheckIntersectionHook(lastElementRef, fetchMoreData);

  return (
    <>
      <div className={styles.shopList}>
        <ShopList>
          {shops?.map((shop, index: number) => (
            <div key={index} className={styles.shopCard} ref={index === shops?.length - 3 ? lastElementRef : null}>
              <MatchingShop {...shop} />
            </div>
          ))}
        </ShopList>
        {(isLoading || isFetching) && <Spin tip={STRING_CONSTANTS.FETCHING_MATCHING_PROPERTIES} />}
      </div>
      {!isFetching && shops?.length === 0 ? (
        <ArEmptyState
          image={noMatchingShops}
          emptyMessage={emptyMessage}
          emptyButton={{
            children: STRING_CONSTANTS.REQUEST_PROPERTIES,
            type: ButtonTypes.Link,
            onClick: handleRequestAddProperties,
          }}
        />
      ) : null}
    </>
  );
};
