import {
  useAskToUpdateDraftShopMutation,
  useAskToUpdateShopsStatusMutation,
  useSelfUpdateShopStatusMutation,
} from "app/services/shopMutationApis";

export const useUpdateShops = () => {
  const useAskToUpdateShops = () => {
    const [updateShopsStatusMutation, { isLoading }] = useAskToUpdateShopsStatusMutation();
    const askToUpdateShops = (shopIds: string[]) => {
      return updateShopsStatusMutation(shopIds)
        .unwrap()
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    };
    return { askToUpdateShops, isLoading };
  };

  const useAskToUpdateDraftShops = () => {
    const [askToUpdateDraftShop, { isLoading }] = useAskToUpdateDraftShopMutation();
    const askToUpdateDraftShops = (shopId: string) => {
      return askToUpdateDraftShop(shopId)
        .unwrap()
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    };
    return { askToUpdateDraftShops, isLoading };
  };

  const useSelfUpdateShopStatus = () => {
    const [selfUpdateShopStatusMutation, { isLoading }] = useSelfUpdateShopStatusMutation();
    const selfUpdateShopStatus = async (shopId: string, status: string) => {
      await selfUpdateShopStatusMutation({
        shopId,
        status: {
          state: status,
        },
      });
    };
    return { selfUpdateShopStatus, isLoading };
  };

  return {
    useAskToUpdateShops,
    useSelfUpdateShopStatus,
    useAskToUpdateDraftShops,
  };
};
