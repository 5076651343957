import { WarningOutlined } from "@ant-design/icons";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { Component, ErrorInfo, ReactNode } from "react";

import styles from "./index.module.scss";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

const ErrorBoundaryFallback: React.FC = () => {
  return (
    <div className={styles.brokenApp}>
      <WarningOutlined />
      <h3>{STRING_CONSTANTS.SOMETHING_WENT_WRONG}</h3>
    </div>
  );
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFallback />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
