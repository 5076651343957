import { isStringEmpty } from "app/utils/helperFunctions";
import { MATCHING_PROPERTIES_URLS } from "constants/ApiUrls";
import { IBasicShopDetails, IMarketMap } from "constants/ShopStructure";

import { api, GenericResponse, paginationParams } from "./api";
import { IPaginationParam } from "./notificationsApi";
export interface IMatchingShopDetail extends IBasicShopDetails {
  isAddedToOptionList: boolean;
  marketMap?: IMarketMap;
}

export interface ICreateOptionList {
  reqId: string;
  shopIds: string[];
}

export interface IMatchingPropertyRequest {
  reqId: string;
  params: paginationParams;
}

export type IMatchingShopResponse = GenericResponse<{
  shops: IMatchingShopDetail[];
  paginationParams: IPaginationParam;
}>;

export type ICreateOptionListResponse = GenericResponse;

export const matchingPropertiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMatchingProperties: build.query<IMatchingShopResponse, IMatchingPropertyRequest>({
      query: ({ reqId, params }) => ({
        url: MATCHING_PROPERTIES_URLS.BRAND_REQ + reqId + MATCHING_PROPERTIES_URLS.MATCH_LIST,
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { reqId } = queryArgs;
        return { reqId };
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems.data?.paginationParams?.previous === null;
        if (newItems.success && currentCache?.data?.shops && newItems.data && !isPageOne) {
          currentCache.data?.shops.push(...(newItems.data?.shops || []));
          currentCache.data.paginationParams = newItems.data.paginationParams;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["MatchingProperties", "SelfUpdate"],
    }),
    createOptionList: build.mutation<ICreateOptionListResponse, ICreateOptionList>({
      query: ({ reqId, shopIds }) => ({
        url: MATCHING_PROPERTIES_URLS.BRAND_REQ + reqId + MATCHING_PROPERTIES_URLS.ADD_SHOPS,
        body: { shopIds },
        method: "POST",
        skip: isStringEmpty(reqId),
      }),
      invalidatesTags: ["MatchingProperties", "OptionList", "RequirementDetails"],
    }),
    getNearbyProperties: build.query<IMatchingShopResponse, IMatchingPropertyRequest>({
      query: ({ reqId, params }) => ({
        url: MATCHING_PROPERTIES_URLS.BRAND_REQ + reqId + MATCHING_PROPERTIES_URLS.NEARBY_MATCH_LIST,
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { reqId } = queryArgs;
        return { reqId };
      },
      merge: (currentCache, newItems) => {
        const isPageOne = newItems.data?.paginationParams?.previous === null;
        if (newItems.success && currentCache?.data?.shops && newItems.data && !isPageOne) {
          currentCache.data?.shops.push(...(newItems.data?.shops || []));
          currentCache.data.paginationParams = newItems.data.paginationParams;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["MatchingProperties", "SelfUpdate"],
    }),
  }),
});

export const {
  useGetMatchingPropertiesQuery,
  useCreateOptionListMutation,
  useLazyGetMatchingPropertiesQuery,
  useLazyGetNearbyPropertiesQuery,
} = matchingPropertiesApi;
