import { useToastService } from "components/toastService";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { useEffect } from "react";

export const useQueryApiError = (
  isError: boolean,
  isLoading: boolean,
  isFetching?: boolean,
  data?: { success?: boolean; reason?: string | null },
  message?: string
) => {
  const { errorToast } = useToastService();
  let hasError: boolean = false;
  let errorMessage: string | undefined = undefined;
  useEffect(() => {
    hasError = isError || (!isLoading && !isFetching && data?.success === false);
    if (hasError) {
      errorMessage = data?.reason || message || STRING_CONSTANTS.SOMETHING_WENT_WRONG;
    }
    if (hasError && errorMessage) {
      errorToast(errorMessage);
    }
  }, [hasError, errorMessage]);
  return { hasError, errorMessage };
};
