import {
  ArBaseContainer,
  ArButton,
  ArForm,
  ArInputType,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { InputRef } from "antd";
import { Rule } from "antd/es/form";
import { useForm, useWatch } from "antd/es/form/Form";
import { useResetPasswordMutation } from "app/services/auth";
import { openSurveyorApp } from "app/utils/helperFunctions";
import useQuery from "app/utils/useQueryHook";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import localStyles from "../forgotPassword/index.module.scss";
import styles from "../login/index.module.scss";

const ResetPassword: React.FC = () => {
  const [successScreen, setSuccessScreen] = useState(false);

  const [passwordContext] = useForm();
  const password = useWatch("password", passwordContext);
  const confirmPassword = useWatch("confirmPassword", passwordContext);

  const navigate = useNavigate();

  const [resetPassword, { isLoading: loginLoading }] = useResetPasswordMutation();

  const ref = useRef<InputRef>(null);

  const params = useQuery();

  const resetToken = params.get("token") || "";
  const userId = params.get("id") || "";
  const userType = params.get("type") || "";

  const returnToLogin = () => {
    if (userType === "surveyor") {
      openSurveyorApp();
    } else {
      navigate({
        pathname: APP_ROUTES.LOGIN,
      });
    }
  };

  const passwordRules: Rule[] = [
    { required: true, message: STRING_CONSTANTS.PASSWORD_REQUIRED },
    {
      validator: async (_, newPassword: string) => {
        const res = await resetPassword({
          newPassword,
          resetToken,
          userId,
        }).unwrap();

        if (!res.success) {
          return Promise.reject(res.reason);
        }
        // dispatch(api.util.invalidateTags(["auth"]));
      },
      validateTrigger: "onSubmit",
    },
  ];
  const confirmPasswordRules: Rule[] = [
    { required: true, message: STRING_CONSTANTS.PASSWORD_REQUIRED },
    {
      validator: async (_, name: string) => {
        if (name !== password) {
          return Promise.reject(STRING_CONSTANTS.PASSWORD_NO_MATCH);
        }
      },
    },
  ];

  const PasswordForm: FormNode = {
    type: nodeType["container"],
    elementData: {
      title: STRING_CONSTANTS.RESET_PASSWORD,
      body: (
        <div>
          <h4>{STRING_CONSTANTS.ALMOST_DONE_RESET}</h4>
        </div>
      ),
      outerContainerClassName: `${styles.headings} ${successScreen && styles.hide}`,
    },
    childNode: {
      type: nodeType["array"],
      childNode: [
        {
          type: nodeType["input"],
          elementData: {
            inputType: inputType["text"],
            inputProps: {
              type: ArInputType.password,
              name: "password",
              label: "New password",
              required: true,
              varient: varientType.default,
              autoFocus: true,
            },
            formItemProps: {
              name: "password",
              rules: passwordRules,
            },
            ref: ref,
          },
        },
        {
          type: nodeType["input"],
          elementData: {
            inputType: inputType["text"],
            inputProps: {
              type: ArInputType.password,
              name: "confirmPassword",
              label: "Confirm new password",
              required: true,
              varient: varientType.default,
            },
            formItemProps: {
              name: "confirmPassword",
              rules: confirmPasswordRules,
            },
            ref: ref,
          },
        },
        {
          type: nodeType["input"],
          elementData: {
            inputType: inputType["button"],
            inputProps: {
              type: ButtonTypes["Primary"],
              size: "middle",
              htmlType: "submit",
              children: STRING_CONSTANTS.CHANGE_PASSWORD,
              loading: loginLoading,
              className: styles.submitButton,
              disabled: !password || password !== confirmPassword,
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (resetToken?.length === 0 || userId?.length === 0) {
      navigate(APP_ROUTES.LOGIN);
    }
  }, [navigate, resetToken, userId]);

  return (
    <ArBaseContainer className={styles.loginFormWrapper}>
      <meta name="robots" content="noindex" />
      <ArForm formNode={PasswordForm} formInstance={passwordContext} onFinish={() => setSuccessScreen(true)}></ArForm>
      <div className={`${styles.headings} ${localStyles.forgotSuccess} ${!successScreen && styles.hide}`}>
        <CheckCircleOutlined className={localStyles.icon} />
        <h2>{STRING_CONSTANTS.CONGRATS}</h2>
        <h4>
          {STRING_CONSTANTS.RESET_SUCCESS_1}
          <br></br>
          {STRING_CONSTANTS.RESET_SUCCESS_2}
        </h4>
        <ArButton onClick={returnToLogin} className={styles.submitButton}>
          {STRING_CONSTANTS.RETURN_TO_LOGIN}
        </ArButton>
      </div>
    </ArBaseContainer>
  );
};

export default ResetPassword;
