import { ArButton, ArPostUpload, ArTag, ButtonTypes } from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import { EditOutlined } from "@ant-design/icons";
import { IWipResponse } from "app/services/wip";
import {
  convertToRupee,
  getFormattedDateOrDash,
  getValueOrDash,
  isNotEqual,
  isNullOrUndefiend,
} from "app/utils/helperFunctions";
import { IWip } from "app/utils/interfaces/index.interface";
import { ShopFeature } from "components/shopDetailComponents/basicImageInfoCard";
import { UNITS } from "constants/ListingEnums";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import { BrokerageProjectionComponentProps, DataProps, SummaryCardProps } from "./index.interface";
import styles from "./index.module.scss";

export const SummaryCard = ({
  heading,
  data,
  approvedData,
  isApprovedWIP,
  changeCount,
  onEdit,
  showEditButton,
}: SummaryCardProps) => {
  if (isNullOrUndefiend(data) || data?.length === 0) {
    return null;
  }
  return (
    <div className={styles.summaryCard}>
      <div className={styles.heading}>
        <h4>{heading}</h4>
        {showEditButton && (
          <ArButton type={ButtonTypes.Link} onClick={onEdit}>
            <EditOutlined />
            {changeCount ? STRING_CONSTANTS.EDIT_CHANGES : STRING_CONSTANTS.EDIT}
          </ArButton>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.proposedDataWrapper}>
          {isApprovedWIP ? (
            <div className="pb-16">
              <ArTag type="small" varient="extended-primary">
                {STRING_CONSTANTS.PROPOSED_CHANGES} ({changeCount})
              </ArTag>
            </div>
          ) : null}
          <div className={styles.flexAlignment}>
            {data?.map(({ label, value, isChanged }, index) => (
              <div className={styles.detailsChild} key={index}>
                <ShopFeature label={label} value={value} className={isChanged ? "bold" : ""} />
              </div>
            ))}
          </div>
        </div>
        {isApprovedWIP && changeCount ? (
          <div className={styles.approvedDataWrapper}>
            <div className="pb-16">
              <ArTag type="small" varient="warning">
                {STRING_CONSTANTS.ORIGINAL_VALUES}
              </ArTag>
            </div>
            <div className={styles.flexAlignment}>
              {approvedData?.map(({ label, value, isChanged }, index) => (
                <div className={styles.detailsChild} key={index}>
                  <ShopFeature label={label} value={value} className={isChanged ? "strikeThrough" : ""} />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const SummaryCardForWIPStatus = ({
  wip,
  showEditButton,
  onEdit,
  isApprovedWIP,
}: {
  wip?: IWip;
  showEditButton: boolean;
  onEdit: () => void;
  isApprovedWIP: boolean;
}) => {
  const wipStatus = wip?.status?.wipStatus?.title;

  const changeCount = wip?.status?.wipStatusId === wip?.approval?.approvedWIP?.status?.wipStatusId ? 0 : 1;
  ///need to check this with BE
  const documents = wip?.status?.wipStatus?.documents?.[0];
  const approvedDocuments = wip?.approval?.approvedWIP?.status?.wipStatus?.documents?.[0];
  const downloadFile = async (downloadUrl: string, documentStructureId: string) => {
    const downloadLink = window.document.createElement("a");

    const url = downloadUrl;
    if (!url || !downloadLink) {
      return;
    }
    downloadLink.href = url;
    downloadLink.download = documentStructureId;
    window.document.body.appendChild(downloadLink);
    downloadLink.click();
    window.document.body.removeChild(downloadLink);
  };

  // if (isNullOrUndefiend(wipStatus)) {
  //   return null;
  // }

  return (
    <div className={styles.summaryCard}>
      <div className={styles.heading}>
        <h4>{STRING_CONSTANTS.WIP_CURRENT_STATUS}</h4>
        {showEditButton && (
          <ArButton type={ButtonTypes.Link} onClick={onEdit}>
            <EditOutlined />
            {changeCount && isApprovedWIP ? STRING_CONSTANTS.EDIT_CHANGES : STRING_CONSTANTS.EDIT}
          </ArButton>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.proposedDataWrapper} style={{ width: "100%" }}>
          {isApprovedWIP ? (
            <div className="pb-16">
              <ArTag type="small" varient="extended-primary">
                {STRING_CONSTANTS.PROPOSED_CHANGES} ({changeCount})
              </ArTag>
            </div>
          ) : null}

          <p className="sub-hero-text-regular py-8">{getValueOrDash(wipStatus)}</p>
          {documents ? (
            <div className=" pt-8">
              <ArPostUpload
                title={wipStatus || ""}
                downloadFile={() =>
                  downloadFile(documents?.documentURLs?.[0]?.url || "", documents?.documentStructureId || "")
                }
                showDownloadIcon={true}
                showDeleteIcon={false}
                color={colors.primary400}
              />
            </div>
          ) : null}
        </div>
        {isApprovedWIP && changeCount ? (
          <div className={`${styles.approvedDataWrapper} `}>
            <div className="pb-16">
              <ArTag type="small" varient="warning">
                {STRING_CONSTANTS.ORIGINAL_VALUES}
              </ArTag>
              <p className={`sub-hero-text-regular ${changeCount ? "strikeThrough" : ""} pt-16`}>
                {getValueOrDash(wip?.approval?.approvedWIP?.status?.wipStatus?.title)}
              </p>
              {approvedDocuments ? (
                <div className=" pt-8">
                  <ArPostUpload
                    title={wipStatus || ""}
                    downloadFile={() =>
                      downloadFile(
                        approvedDocuments?.documentURLs?.[0]?.url || "",
                        approvedDocuments?.documentStructureId || ""
                      )
                    }
                    showDownloadIcon={true}
                    showDeleteIcon={false}
                    color={colors.primary400}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const SummaryCardForBillingMilestones = ({
  wip,
  showEditButton,
  onEdit,
  isApprovedWIP,
}: {
  wip?: IWip;
  showEditButton: boolean;
  onEdit: () => void;
  isApprovedWIP: boolean;
}) => {
  // const wipStatus = wip?.status?.wipStatus?.title;

  const changeCount =
    JSON.stringify(wip?.billingMilestones) === JSON.stringify(wip?.approval?.approvedWIP?.billingMilestones)
      ? 0
      : wip?.billingMilestones?.length;

  // if (isNullOrUndefiend(!wip?.billingMilestones?.length)) {
  //   return null;
  // }
  return (
    <div className={styles.summaryCard}>
      <div className={styles.heading}>
        <h4>{STRING_CONSTANTS.WIP_BILLING_MILESTONE}</h4>
        {showEditButton && (
          <ArButton type={ButtonTypes.Link} onClick={onEdit}>
            <EditOutlined />
            {changeCount && isApprovedWIP ? STRING_CONSTANTS.EDIT_CHANGES : STRING_CONSTANTS.EDIT}
          </ArButton>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.proposedDataWrapper} style={{ width: "100%" }}>
          {isApprovedWIP && changeCount ? (
            <div className="pb-16">
              <ArTag type="small" varient="extended-primary">
                {STRING_CONSTANTS.PROPOSED_CHANGES} ({changeCount})
              </ArTag>
            </div>
          ) : null}

          <div className="sub-hero-text-regular ">
            {wip?.billingMilestones?.length
              ? wip?.billingMilestones?.map(({ value, dueDate }, idx) => (
                  <div key={value} className="display-flex-space-between pb-16 pt-16">
                    <p className="sub-hero-text-regular  ">{`${idx + 1}- ${value}`}</p>
                    <p className="sub-hero-text-regular-low ">{getFormattedDateOrDash(dueDate)}</p>
                  </div>
                ))
              : "-"}
          </div>
        </div>
        {isApprovedWIP && changeCount ? (
          <div className={styles.approvedDataWrapper}>
            <div className="pb-16">
              <ArTag type="small" varient="warning">
                {STRING_CONSTANTS.ORIGINAL_VALUES}
              </ArTag>
              {isNullOrUndefiend(wip?.approval?.approvedWIP?.billingMilestones) ||
              wip?.approval?.approvedWIP?.billingMilestones?.length === 0 ? (
                <p className="sub-hero-text pt-16">{STRING_CONSTANTS.NO_ORIGINAL_VALUE}</p>
              ) : null}
              {wip?.approval?.approvedWIP?.billingMilestones?.map(({ value, dueDate }, idx) => (
                <div key={value} className="display-flex-space-between pb-16 pt-16">
                  <p className="sub-hero-text-regular strikeThrough">{`${idx + 1}- ${value}`}</p>
                  <p className="sub-hero-text-regular-low ">{getFormattedDateOrDash(dueDate)}</p>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const BrokerageProjectionComponent = ({
  showEditButton,
  openBrokerAgeModal,
  brokerageDetailsChangeCount,
  approvedWIP,
  wip,
}: BrokerageProjectionComponentProps) => {
  const billingClient = wip?.data?.wip?.wipMetaData?.billingClient;
  const isBrandOnly = billingClient === STRING_CONSTANTS.BRAND;
  const isLLOnly = billingClient === STRING_CONSTANTS.LANDLORD;
  return (
    <div className={styles.summaryCard}>
      <div className={styles.heading}>
        <h4>{STRING_CONSTANTS.WIP_SUMMARY_BROKERAGE}</h4>
        {showEditButton && (
          <ArButton onClick={openBrokerAgeModal} type={ButtonTypes.Link}>
            <EditOutlined />
            {brokerageDetailsChangeCount ? STRING_CONSTANTS.EDIT_CHANGES : STRING_CONSTANTS.EDIT}
          </ArButton>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.proposedDataWrapper}>
          {approvedWIP ? (
            <div className="pb-16">
              <ArTag type="small" varient="extended-primary">
                {STRING_CONSTANTS.PROPOSED_CHANGES} ({brokerageDetailsChangeCount})
              </ArTag>
            </div>
          ) : null}
          <div className={styles.flexAlignment}>
            {wip?.data?.wip?.brokerageDetails?.map((fy, idx) => (
              <div key={idx} className="pb-16">
                <p className="hero-text-medium pb-16">{getValueOrDash(fy?.fy) + " " + STRING_CONSTANTS.PROJECTION}</p>
                {fy?.monthlyBrokerageDetails?.map(({ shopBrokerage, brandBrokerage, month }, index) => (
                  <div className={styles.detailContainer + " " + styles.noBorder + " "} key={index}>
                    <div className={styles.detailsChild}>
                      <ShopFeature
                        label={FORM_FIELDS.MONTH}
                        value={month}
                        className={
                          approvedWIP
                            ? isNotEqual(
                                month,
                                approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]?.month || ""
                              )
                              ? "bold"
                              : ""
                            : ""
                        }
                      />
                    </div>
                    {isBrandOnly ? null : (
                      <div className={styles.detailsChild}>
                        <ShopFeature
                          label={FORM_FIELDS.LL_BROKERAGE}
                          value={convertToRupee(Number(getValueOrDash(shopBrokerage?.current)))}
                          className={
                            approvedWIP
                              ? isNotEqual(
                                  shopBrokerage?.current,
                                  Number(
                                    approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]
                                      ?.shopBrokerage?.current
                                  )
                                )
                                ? "bold"
                                : ""
                              : ""
                          }
                        />
                      </div>
                    )}
                    {isLLOnly ? null : (
                      <div className={styles.detailsChild}>
                        <ShopFeature
                          label={FORM_FIELDS.BRAND_BROKERAGE}
                          value={convertToRupee(Number(getValueOrDash(brandBrokerage?.current)))}
                          className={
                            approvedWIP
                              ? isNotEqual(
                                  brandBrokerage?.current,
                                  Number(
                                    approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]
                                      ?.brandBrokerage?.current
                                  )
                                )
                                ? "bold"
                                : ""
                              : ""
                          }
                        />
                      </div>
                    )}
                    {isBrandOnly || isLLOnly ? null : (
                      <div className={styles.detailsChild}>
                        <ShopFeature
                          label={FORM_FIELDS.TOTAL_BROKERAGE}
                          value={
                            isNaN(Number(brandBrokerage?.current + shopBrokerage?.current))
                              ? "-"
                              : convertToRupee(Number(brandBrokerage?.current) + Number(shopBrokerage?.current))
                          }
                          className={
                            approvedWIP
                              ? isNotEqual(
                                  Number(brandBrokerage?.current) + Number(shopBrokerage?.current),
                                  Number(
                                    approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]
                                      ?.brandBrokerage?.current
                                  ) +
                                    Number(
                                      approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]
                                        ?.shopBrokerage?.current
                                    )
                                )
                                ? "bold"
                                : ""
                              : ""
                          }
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        {approvedWIP && brokerageDetailsChangeCount ? (
          <div className={styles.approvedDataWrapper}>
            <div className="pb-16">
              <ArTag type="small" varient="warning">
                {STRING_CONSTANTS.ORIGINAL_VALUES}
              </ArTag>
            </div>
            <div className={styles.flexAlignment}>
              {approvedWIP?.brokerageDetails?.map((fy, idx) => (
                <div key={idx} className="pb-16">
                  <p className="hero-text-medium pb-16">{getValueOrDash(fy?.fy) + " " + STRING_CONSTANTS.PROJECTION}</p>
                  {fy?.monthlyBrokerageDetails?.map(({ shopBrokerage, brandBrokerage, month }, index) => (
                    <div className={styles.detailContainer + " " + styles.noBorder + " "} key={index}>
                      <div className={styles.detailsChild}>
                        <ShopFeature label={FORM_FIELDS.MONTH} value={month} />
                      </div>
                      <div className={styles.detailsChild}>
                        <ShopFeature label={FORM_FIELDS.LL_BROKERAGE} value={convertToRupee(shopBrokerage?.current)} />
                      </div>
                      <div className={styles.detailsChild}>
                        <ShopFeature
                          label={FORM_FIELDS.BRAND_BROKERAGE}
                          value={convertToRupee(brandBrokerage?.current)}
                        />
                      </div>
                      <div className={styles.detailsChild}>
                        <ShopFeature
                          label={FORM_FIELDS.TOTAL_BROKERAGE}
                          value={
                            isNaN(Number(brandBrokerage?.current + shopBrokerage?.current))
                              ? "-"
                              : convertToRupee(Number(brandBrokerage?.current) + Number(shopBrokerage?.current))
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const getCommercialDetails = ({
  wip,
  approvedWIP,
  llAmount,
  approvedLLAmt,
  brandAmount,
  approvedBrandAmt,
  totalBrokerageAmount,
  approvedTotalBrokerageAmt,
  isConsulting,
}: {
  wip: IWipResponse | undefined;
  approvedWIP: Omit<IWip, "approval"> | undefined;
  llAmount: number | undefined;
  approvedLLAmt: number | undefined;
  brandAmount: number | undefined;
  approvedBrandAmt: number | undefined;
  totalBrokerageAmount: number | undefined;
  approvedTotalBrokerageAmt: number | undefined;
  isConsulting: boolean | undefined;
}): DataProps[] => {
  return [
    isConsulting
      ? {
          label: STRING_CONSTANTS.TOTAL_FY,
          value: getValueOrDash(wip?.data?.wip?.brokerageDetails?.length) as string,
          isChanged:
            approvedWIP &&
            isNotEqual(wip?.data?.wip?.brokerageDetails?.length || "", approvedWIP?.brokerageDetails?.length || ""),
        }
      : {
          label: FORM_FIELDS.AREA,
          value: (wip?.data?.wip?.commercialDetails?.area?.value || "") + " " + UNITS.SQFT,
          isChanged:
            approvedWIP &&
            isNotEqual(
              wip?.data?.wip?.commercialDetails?.area?.value || "",
              approvedWIP?.commercialDetails?.area?.value || ""
            ),
        },
    {
      label: STRING_CONSTANTS.REVENUE_FROM_LL,
      value: convertToRupee(llAmount || 0),
      isChanged: approvedWIP && isNotEqual(llAmount || 0, approvedLLAmt || 0),
    },
    {
      label: STRING_CONSTANTS.REVENUE_FROM_BRAND,
      value: convertToRupee(brandAmount || 0),
      isChanged: approvedWIP && isNotEqual(brandAmount || 0, approvedBrandAmt || 0),
    },
    {
      label: STRING_CONSTANTS.TOTAL_REVENUE,
      value: convertToRupee(totalBrokerageAmount || 0),
      isChanged: approvedWIP && isNotEqual(totalBrokerageAmount || 0, approvedTotalBrokerageAmt || 0),
    },
  ];
};

export const getApprovedCommercialDetails = ({
  wip,
  approvedWIP,
  llAmount,
  approvedLLAmt,
  brandAmount,
  approvedBrandAmt,
  totalBrokerageAmt,
  approvedTotalBrokerageAmt,
  isConsulting,
}: {
  wip: IWipResponse | undefined;
  approvedWIP: Omit<IWip, "approval"> | undefined;
  llAmount: number | undefined;
  approvedLLAmt: number | undefined;
  brandAmount: number | undefined;
  approvedBrandAmt: number | undefined;
  totalBrokerageAmt: number | undefined;
  approvedTotalBrokerageAmt: number | undefined;
  isConsulting: boolean | undefined;
}): DataProps[] => {
  return [
    isConsulting
      ? {
          label: STRING_CONSTANTS.TOTAL_FY,
          value: getValueOrDash(wip?.data?.wip?.brokerageDetails?.length) as string,
          isChanged: isNotEqual(
            wip?.data?.wip?.brokerageDetails?.length || "",
            approvedWIP?.brokerageDetails?.length || ""
          ),
        }
      : {
          label: FORM_FIELDS.AREA,
          value: (approvedWIP?.commercialDetails?.area?.value || "") + " " + UNITS.SQFT,
          isChanged: isNotEqual(
            wip?.data?.wip?.commercialDetails?.area?.value || "",
            approvedWIP?.commercialDetails?.area?.value || ""
          ),
        },
    {
      label: STRING_CONSTANTS.REVENUE_FROM_LL,
      value: convertToRupee(approvedLLAmt || 0),
      isChanged: isNotEqual(llAmount || 0, approvedLLAmt || 0),
    },
    {
      label: STRING_CONSTANTS.REVENUE_FROM_BRAND,
      value: convertToRupee(approvedBrandAmt || 0),
      isChanged: isNotEqual(brandAmount || 0, approvedBrandAmt || 0),
    },
    {
      label: STRING_CONSTANTS.TOTAL_REVENUE,
      value: convertToRupee(approvedTotalBrokerageAmt || 0),
      isChanged: isNotEqual(totalBrokerageAmt || 0, approvedTotalBrokerageAmt || 0),
    },
  ];
};

export const getBrokerageDetailsChangeCount = ({
  wip,
  approvedWIP,
}: {
  wip: IWipResponse | undefined;
  approvedWIP: Omit<IWip, "approval"> | undefined;
}) => {
  let brokerageDetailsChangeCount = 0;

  if (!approvedWIP) return brokerageDetailsChangeCount;

  wip?.data?.wip?.brokerageDetails?.forEach((fy, idx) => {
    fy?.monthlyBrokerageDetails?.forEach(({ shopBrokerage, brandBrokerage, month }, index) => {
      if (isNotEqual(month, approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]?.month || "")) {
        brokerageDetailsChangeCount++;
      }
      if (
        isNotEqual(
          shopBrokerage?.current,
          Number(approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]?.shopBrokerage?.current)
        )
      ) {
        brokerageDetailsChangeCount++;
      }
      if (
        isNotEqual(
          brandBrokerage?.current,
          Number(approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]?.brandBrokerage?.current)
        )
      ) {
        brokerageDetailsChangeCount++;
      }
      if (
        isNotEqual(
          Number(brandBrokerage?.current) + Number(shopBrokerage?.current),
          Number(approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]?.brandBrokerage?.current) +
            Number(approvedWIP?.brokerageDetails?.[idx]?.monthlyBrokerageDetails?.[index]?.shopBrokerage?.current)
        )
      ) {
        brokerageDetailsChangeCount++;
      }
    });
  });

  return brokerageDetailsChangeCount;
};
