import { ArBrandModals } from "@anarock/ar-common-react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { FORM_MODAL_STRING } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { getBrandLabel } from "features/manageRequirement/create-requirement/partials";
import React from "react";

import { AddToOptionListsModalProps } from "./index.interface";
import styles from "./index.module.scss";
import { ShopAlreadyInOptionListBrands } from "./partials";
export const ArAddToOptionsListModal: React.FC<AddToOptionListsModalProps> = ({
  open,
  onSubmit,
  onCancel,
  brandRequirementDetails,
  isLoading,
  isSubmitting,
  ...props
}) => {
  const brandsInOptionsList = brandRequirementDetails.filter(
    (brandRequirementDetails) => brandRequirementDetails.isAddedToOptionList
  );

  const matchingBrands = brandRequirementDetails.filter(
    (brandRequirementDetails) => !brandRequirementDetails.isAddedToOptionList
  );

  const matchingBrandsOptions = matchingBrands.map(({ brand, _id, locality }) => {
    return {
      label: getBrandLabel(brand, locality.name),
      value: _id,
      name: brand?.brandName,
    };
  });

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel && onCancel(e);
  };
  const handleSubmit = (val: CheckboxValueType[]) => {
    onSubmit && onSubmit(val.map(String));
  };

  const header = <ShopAlreadyInOptionListBrands brandRequirementDetails={brandsInOptionsList} />;

  return (
    <ArBrandModals.ArMultiSelectModal
      open={open}
      onCancel={handleCancel}
      title={`${STRING_CONSTANTS.ADD_TO_OPTIONS_LIST} ${
        isNullOrUndefiend(brandRequirementDetails?.[0]?.city?.name)
          ? STRING_CONSTANTS.CITY
          : brandRequirementDetails?.[0]?.city?.name
      }`}
      submitText={STRING_CONSTANTS.ADD}
      onSubmit={handleSubmit}
      options={matchingBrandsOptions}
      showSearch={true}
      placeholder={FORM_MODAL_STRING.SEARCH_FOR_BRAND}
      destroyOnClose={true}
      loading={isLoading}
      confirmLoading={isSubmitting}
      header={brandsInOptionsList.length ? header : null}
      className={styles.matchingListModal}
      listHeaderText={`${STRING_CONSTANTS.SELECT_BRAND_FOR_OPTION_LIST} (${matchingBrandsOptions.length})`}
      {...props}
    ></ArBrandModals.ArMultiSelectModal>
  );
};
