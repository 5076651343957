import { nodeType } from "@anarock/ar-common-react";
import { ArFormContainerNode } from "@anarock/ar-common-react/dist/ui-components/ar-form/index.interface";
import { getFixedDigit } from "app/utils/helperFunctions";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { EmptyNode } from "features/invoices/components/formHelpers";
import { StaticValue } from "features/wip/components/WIP-CommercialDetailsForm/partials";
import React from "react";

import styles from "../../../wip/index.module.scss";

export const CaculatedBrokerageNode: (
  brandAmt: number,
  landlordAmt: number,
  billingClient: string
) => ArFormContainerNode = (brandAmt, landlordAmt, billingClient) => {
  const brandBrokerage = brandAmt;
  const llBrokerage = landlordAmt;

  return {
    type: nodeType.container,
    elementData: {
      innerContainerClassName: styles.staticDataBox,
    },
    childNode: {
      type: nodeType.array,
      childNode: [
        {
          type: nodeType.container,
          elementData: {
            body: (
              <span className={styles.emptyBoxContainer}>
                <span className={styles.emptyBox}></span>
              </span>
            ),
          },
        },
        {
          type: nodeType.container,
          elementData: {
            innerContainerClassName: `${styles.commercialInnerContainer}`,
            outerContainerClassName: styles.commercialOuterConatiner,
          },
          childNode: {
            type: nodeType.array,
            childNode: [
              {
                type: nodeType.container,
                elementData: {
                  body: (
                    <StaticValue
                      label={STRING_CONSTANTS.TOTAL_REVENUE}
                      value={getFixedDigit(brandBrokerage + llBrokerage)}
                    />
                  ),
                },
              },
              ...(billingClient === STRING_CONSTANTS.BRAND
                ? [
                    {
                      type: nodeType.container,
                      elementData: {
                        body: (
                          <StaticValue label={STRING_CONSTANTS.BRAND_REVENUE} value={getFixedDigit(brandBrokerage)} />
                        ),
                      },
                    },
                    EmptyNode,
                  ]
                : billingClient === STRING_CONSTANTS.LANDLORD
                ? [
                    {
                      type: nodeType.container,
                      elementData: {
                        body: <StaticValue label={STRING_CONSTANTS.LL_REVENUE} value={getFixedDigit(llBrokerage)} />,
                      },
                    },
                    EmptyNode,
                  ]
                : [
                    {
                      type: nodeType.container,
                      elementData: {
                        body: <StaticValue label={STRING_CONSTANTS.LL_REVENUE} value={getFixedDigit(llBrokerage)} />,
                      },
                    },
                    {
                      type: nodeType.container,
                      elementData: {
                        body: (
                          <StaticValue label={STRING_CONSTANTS.BRAND_REVENUE} value={getFixedDigit(brandBrokerage)} />
                        ),
                      },
                    },
                  ]),
            ],
          },
        },
      ],
    },
  };
};
