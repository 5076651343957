import { ArBrandModals, ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useGetShopStatusEnumsQuery, useSelfUpdateShopStatusMutation } from "app/services/shopMutationApis";
import {
  selectAllContactPersons,
  selectSelfUpdateModalOpen,
  selectShopId,
  setSelfUpdateModalOpen,
} from "app/slice/selfUpdateSlice";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { useToastService } from "components/toastService";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React from "react";

const SelfUpdateShop = () => {
  const { data: ShopStatusResponse, isError, isFetching, isLoading } = useGetShopStatusEnumsQuery();
  useQueryApiError(isError, isLoading, isFetching, ShopStatusResponse, undefined);
  const statusEnums = ShopStatusResponse?.data?.shopStatusEnum;
  const openModal = useAppSelector(selectSelfUpdateModalOpen);
  const contactPersons = useAppSelector(selectAllContactPersons);
  const shopId = useAppSelector(selectShopId);
  const [UpdateShopStatus] = useSelfUpdateShopStatusMutation();
  const { errorToast, successToast } = useToastService();
  const dispatch = useAppDispatch();
  const [openContactModal, setOpenContactModal] = React.useState(false);
  const [openStatusModal, setOpenStatusModal] = React.useState(false);
  const handleSubmitSelfUpdate = () => {
    dispatch(setSelfUpdateModalOpen(!openModal));
    setOpenContactModal(!openContactModal);
  };
  const handleSubmitContactModal = () => {
    setOpenContactModal(!openContactModal);
    setOpenStatusModal(!openStatusModal);
  };

  const handleSubmitStatusModal = (state: string) => {
    UpdateShopStatus({
      shopId,
      status: {
        state,
      },
    })
      .unwrap()
      .then((res) => {
        const { success, reason } = res;
        if (success) {
          successToast(TOAST_MESSAGES.STATUS_UPDATED);
          setOpenStatusModal(!openStatusModal);
        } else {
          errorToast(reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
        }
      })
      .catch((err) => {
        errorToast(err.message);
      });
  };
  const options = Object.values(statusEnums || {}).map((key) => {
    return { label: key, value: key };
  });
  return (
    <React.Fragment>
      <ArBrandModals.ArSelfUpdateStatusModal
        open={openModal}
        onSubmit={handleSubmitSelfUpdate}
        onCancel={() => dispatch(setSelfUpdateModalOpen(!openModal))}
      />
      <ArBrandModals.ArContactInfoModal
        open={openContactModal}
        onCancel={() => {
          setOpenContactModal(!openContactModal);
        }}
        contactPersons={contactPersons}
        footer={
          <>
            <ArButton
              type={ButtonTypes.Secondary}
              onClick={() => {
                setOpenContactModal(!openContactModal);
              }}
            >
              {STRING_CONSTANTS.CANCEL}
            </ArButton>
            <ArButton onClick={handleSubmitContactModal} type={ButtonTypes.Primary}>
              {STRING_CONSTANTS.UPDATE_STATUS}
            </ArButton>
          </>
        }
      />
      <ArBrandModals.ArRadioOptionsModal
        open={openStatusModal}
        title={STRING_CONSTANTS.UPDATE_STATUS}
        submitText={STRING_CONSTANTS.UPDATE_STATUS}
        options={options}
        onCancel={() => {
          setOpenStatusModal(!openStatusModal);
        }}
        onSubmit={(val) => handleSubmitStatusModal(val as string)}
      />
    </React.Fragment>
  );
};

export default SelfUpdateShop;
