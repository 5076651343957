import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { Popover, PopoverProps } from "antd";
import React from "react";

import styles from "./index.module.scss";
const ArPopover: React.FC<PopoverProps> = ({ children, content, placement = "bottomRight", className, ...props }) => {
  return (
    <Popover
      overlayClassName={classNames(styles.popOver, className).className}
      placement={placement}
      content={content}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default ArPopover;
