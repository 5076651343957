import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "app/services/auth";
import { filtersApi, IBrandRequirement } from "app/services/listingPageApi";
import { RootState } from "app/store";

export interface requirementsState {
  requirements: IBrandRequirement[];
  currentRequirement: IBrandRequirement | undefined;
  currentRequirementLocation: string | undefined;
}

const initialState: requirementsState = {
  requirements: [],
  currentRequirement: undefined,
  currentRequirementLocation: undefined,
};

const allRequirementSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    setAllRequirements: (state, action: { payload: IBrandRequirement[] }) => {
      state.requirements = action.payload;
    },
    setCurrentRequirement: (
      state,
      action: {
        payload: { requirement: IBrandRequirement; currentLocation?: string };
      }
    ) => {
      state.currentRequirement = action.payload.requirement;
      state.currentRequirementLocation =
        action.payload?.currentLocation || action.payload?.requirement?.requirementDetails?.[0]?._id;
    },
    setCurrentRequirementLocation: (state, action: { payload: string }) => {
      state.currentRequirementLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.requirements = [];
      state.currentRequirement = undefined;
    });
    builder.addMatcher(filtersApi.endpoints.getRequirementList.matchFulfilled, (state, { payload }) => {
      if (payload.success && payload.data) {
        state.requirements = payload.data.brandRequirements;
        if (payload?.data?.paginationParams?.previous === null || state.currentRequirement === undefined) {
          state.currentRequirement = payload.data.brandRequirements[0];
          state.currentRequirementLocation = payload?.data?.brandRequirements[0]?.requirementDetails?.[0]._id;
        }
      }
    });
  },
});

export const selectRequirements = (state: RootState) => state.requirements.requirements;
export const selectCurrentBrand = (state: RootState) => state.requirements.currentRequirement;
export const selectCurrentRequirementLocation = (state: RootState) => state.requirements.currentRequirementLocation;
export const selectCurrentRequirement = (state: RootState) => state.requirements.currentRequirement;

export const { setAllRequirements, setCurrentRequirement, setCurrentRequirementLocation } = allRequirementSlice.actions;
export default allRequirementSlice.reducer;
