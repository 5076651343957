import { ArForm, ArModal, ArModalProps, ArTag, FormNode, nodeType } from "@anarock/ar-common-react";
import { WarningOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { IClientPOC, useAddClientPOCMutation, useEditClientPOCMutation } from "app/services/invoices";
import { isNotNullorUndefined } from "app/utils/helperFunctions";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { RequiredTextNode } from "features/invoices/components/formHelpers";
import React, { useEffect } from "react";

interface AddBrandPOCModalProps extends Omit<ArModalProps, "onCancel"> {
  onCancel?: () => void;
  clientId: string | undefined;
  type: string | undefined;
  editBrandPOCData?: IClientPOC | undefined;
  sendDataAndCloseModal?: (poc: IClientPOC) => void;
}

export const AddBrandPOCModal: React.FC<AddBrandPOCModalProps> = ({
  onSubmit,
  editBrandPOCData,
  sendDataAndCloseModal,
  ...props
}) => {
  const [form] = useForm();
  const { errorToast, successToast } = useToastService();

  const [addBrandPOC] = useAddClientPOCMutation();
  const [editBrandPOC] = useEditClientPOCMutation();

  const editFlow = isNotNullorUndefined(editBrandPOCData?.name);

  useEffect(() => {
    if (editBrandPOCData) {
      form.setFieldsValue({
        [FORM_FIELDS.POC_NAME]: editBrandPOCData.name,
        [FORM_FIELDS.POC_EMAIL]: editBrandPOCData.email,
        [FORM_FIELDS.POC_NUMBER]: editBrandPOCData.phoneNumber?.number,
      });
    }
  }, [editBrandPOCData, form]);

  const handleSubmitModal = async () => {
    try {
      const validatedForm = await form.validateFields();
      let BrandPOCResponse;
      try {
        BrandPOCResponse = await addBrandPOC({
          module: props?.type,
          moduleEntryId: props?.clientId,
          name: validatedForm[FORM_FIELDS.POC_NAME],
          email: validatedForm[FORM_FIELDS.POC_EMAIL],
          phoneNumber: {
            countryCode: "+91",
            number: validatedForm[FORM_FIELDS.POC_NUMBER],
          },
          alternatePhoneNumbers: [],
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (BrandPOCResponse?.success) {
        successToast(STRING_CONSTANTS.POC_ADDED_SUCCESSFULLY);
        props?.onCancel?.();
      } else {
        errorToast(BrandPOCResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  const handleUpdateModal = async () => {
    try {
      const validatedForm = await form.validateFields();
      let BrandPOCResponse;
      try {
        BrandPOCResponse = await editBrandPOC({
          module: props?.type,
          moduleEntryId: props?.clientId,
          name: validatedForm[FORM_FIELDS.POC_NAME],
          email: validatedForm[FORM_FIELDS.POC_EMAIL],
          phoneNumber: {
            countryCode: "+91",
            number: validatedForm[FORM_FIELDS.POC_NUMBER],
          },
          alternatePhoneNumbers: [],

          _id: editBrandPOCData?._id,
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }

      if (BrandPOCResponse?.success) {
        sendDataAndCloseModal && sendDataAndCloseModal(BrandPOCResponse?.data?.poc);
        successToast(STRING_CONSTANTS.POC_EDITED_SUCCESSFULLY);
      } else {
        errorToast(BrandPOCResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  const AddBrandPOCForm: FormNode = {
    type: nodeType.array,
    childNode: [
      RequiredTextNode(FORM_FIELDS.POC_NAME, FORM_FIELDS.CLIENT_POC_NAME, "", undefined, requiredRulesOnChange),
      RequiredTextNode(FORM_FIELDS.POC_NUMBER, FORM_FIELDS.CLIENT_POC_NO),
      RequiredTextNode(FORM_FIELDS.POC_EMAIL, FORM_FIELDS.CLIENT_POC_EMAIL),
    ],
  };
  return (
    <ArModal
      onSubmit={editFlow ? handleUpdateModal : handleSubmitModal}
      width={"500px"}
      {...props}
      submitText={editFlow ? STRING_CONSTANTS.EDIT_DETAILS : STRING_CONSTANTS.ADD_NEW_CLIENT_POC}
    >
      {editFlow ? (
        <div className="py-8">
          <ArTag type="full-width" varient="warning">
            <div className="d-flex">
              <WarningOutlined className={"iconSize24"} />
              <div className="pl-8">
                <p className="sub-hero-text">{STRING_CONSTANTS.CHANGES_WILL_APPLY_TO_FUTURE}</p>
                <p className="sub-hero-text-regular-medium">{STRING_CONSTANTS.EXISTED_UNCHANGED}</p>
              </div>
            </div>
          </ArTag>
        </div>
      ) : null}
      <ArForm form={form} formNode={AddBrandPOCForm} />
    </ArModal>
  );
};
