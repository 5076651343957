import { ArImage, ArTootip } from "@anarock/ar-common-react";
import {
  ArBrandModals,
  ArButton,
  ArForm,
  ArInputType,
  ArModal,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { message, Upload } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useForm } from "antd/es/form/Form";
import { useAddBrandMutation, useLazyGetSignedUrlQuery, useUploadImageToUrlMutation } from "app/services/addBrandApi";
import { IMatchingRequirementDetailsForShop } from "app/services/shopListingApi";
import BrandPlaceholderLogo from "assets/images/brandLogoPlaceholder.png";
import { ReactComponent as Folder } from "assets/images/folder.svg";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { FORM_FIELDS, FORM_MODAL_STRING } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React, { useEffect, useState } from "react";
const { Dragger } = Upload;

import { FormInstance } from "antd/lib/form";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { useToastService } from "components/toastService";
import { GET_API_ERRORS } from "constants/getAPIErrors";
import { imageTypes } from "constants/notifications";

import { AddBrandModalProps } from "./index.interface";
import styles from "./index.module.scss";

export const ShopAlreadyInOptionListBrands: React.FC<{
  brandRequirementDetails: IMatchingRequirementDetailsForShop[];
}> = ({ brandRequirementDetails }) => {
  return (
    <div>
      <p className="text-regular-medium ">{STRING_CONSTANTS.SHOP_ALREADY_IN_OPTIONS_LIST}</p>
      <div className={styles.optionListBrandImages}>
        {brandRequirementDetails.map(({ brand }, index) => {
          return (
            <ArTootip key={index} heading={brand?.brandName}>
              <div>
                <ArImage
                  alt={brand?.brandName}
                  url={brand.brandLogoUrl}
                  placeholder={<img loading="lazy" src={BrandPlaceholderLogo} />}
                  imageClassName={styles.brandLogo}
                  placeholderClassName={styles.brandLogoPlaceholder}
                />
              </div>
            </ArTootip>
          );
        })}
      </div>
    </div>
  );
};

const UploadBrand: React.FC<{
  form: FormInstance;
  setLogoUrl: (url: string) => void;
  disable: boolean;
}> = ({ form, setLogoUrl, disable }) => {
  const { successToast, errorToast } = useToastService();
  const [uploadImage] = useUploadImageToUrlMutation();
  const [getSignedUrl] = useLazyGetSignedUrlQuery();
  const handleUploadBrandLogo: (props: any) => void = async ({ file, onSuccess, onError }) => {
    if (form.getFieldValue(FORM_FIELDS.BRAND_NAME) === undefined) {
      message.error(STRING_CONSTANTS.ENTER_BRAND_NAME);
      onError();
      return;
    } else {
      let signedUrlResponse;
      try {
        const { data } = await getSignedUrl(form.getFieldValue(FORM_FIELDS.BRAND_NAME));
        signedUrlResponse = data;
      } catch {
        errorToast(TOAST_MESSAGES.FAILED_TO_GENERATE_URL);
      }
      if (signedUrlResponse?.success) {
        const uploadUrlObject = signedUrlResponse?.data?.brandLogoUploadUrlObj;
        const formData = new FormData();
        for (const key in uploadUrlObject?.fields) {
          formData.append(key, uploadUrlObject?.fields[key] || "");
        }
        formData.append("file", file);
        uploadImage({
          url: uploadUrlObject?.url || "",
          body: formData,
        })
          .unwrap()
          .then(() => {
            onSuccess();
            setLogoUrl(`${uploadUrlObject?.url}/${uploadUrlObject?.fields?.key}` || "");
            successToast(STRING_CONSTANTS.LOGO_UPLOADED_SUCCESSFULLY);
          })
          .catch((err) => {
            errorToast(err.message);
            onError();
          });
      } else {
        errorToast(signedUrlResponse?.reason || TOAST_MESSAGES.FAILED_TO_GENERATE_URL);
        onError();
      }
    }
  };

  return (
    <div className={styles.dragger}>
      <h3>{STRING_CONSTANTS.ADD_LOGO}</h3>
      <Dragger
        disabled={disable}
        customRequest={(options) => handleUploadBrandLogo(options)}
        accept={imageTypes.join()}
        maxCount={1}
        multiple={false}
      >
        <Folder />
        <p>{STRING_CONSTANTS.DRAG_FILES_HERE}</p>
        <ArButton disabled={disable} type={ButtonTypes.Link} size="large">
          {STRING_CONSTANTS.BROWSE_FILE}
        </ArButton>
      </Dragger>
    </div>
  );
};

export const useGetBrandNameNode = (form: FormInstance) => {
  const [brandLogoUrl, setBrandLogoUrl] = useState<string | undefined>(undefined);
  const [disable, setdisable] = useState<boolean>(true);
  const uploadBrand = <UploadBrand disable={disable} form={form} setLogoUrl={(url) => setBrandLogoUrl(url)} />;

  const brandNameNode: FormNode = {
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        rules: [
          {
            required: true,
            validator: (_, value) => {
              if (value) {
                setdisable(false);
                return Promise.resolve();
              }
              setdisable(true);
              return Promise.reject(new Error(STRING_CONSTANTS.BRAND_NAME_REQUIRED));
            },
            validateTrigger: ["onChange", "onBlur"],
          },
        ],
        name: FORM_FIELDS.BRAND_NAME,
        required: true,
        help: disable && STRING_CONSTANTS.BRAND_NAME_REQUIRED,
      },
      inputProps: {
        type: ArInputType.text,
        label: FORM_FIELDS.BRAND_NAME_LABEL,
        varient: varientType.default,
        required: true,
      },
    },
  };

  return { brandLogoUrl, brandNameNode, uploadBrand };
};

export const AddBrandModal: React.FC<AddBrandModalProps> = ({ onSubmit, ...props }) => {
  const [form] = useForm();
  const [openIndustryModal, setIndustryModal] = useState<boolean>(false);
  const [industryValues, setIndustryValues] = useState<CheckboxValueType>();
  const { data: enums, isError, isLoading, isFetching } = useGetStaticEnumsQuery();
  useQueryApiError(isError, isLoading, isFetching, enums, GET_API_ERRORS.UNABLE_TO_FETCH_ENUMS);
  const industries = enums?.data?.industry;
  const options = Object.entries(industries || {}).map(([key, value]) => {
    return { label: value, value: key };
  });
  const handleSubmitIndustry = (value: CheckboxValueType) => {
    setIndustryModal(!openIndustryModal);
    setIndustryValues(value);
  };
  useEffect(() => {
    form.setFieldValue(FORM_FIELDS.INDUSTRY, industries?.[industryValues as string]);
  }, [industryValues]);
  const { brandLogoUrl, brandNameNode, uploadBrand } = useGetBrandNameNode(form);
  const [addBrand] = useAddBrandMutation();
  const { errorToast, successToast } = useToastService();

  const IndustryModal = (
    <ArBrandModals.ArRadioOptionsModal
      destroyOnClose={true}
      width={"500px"}
      open={openIndustryModal}
      onCancel={() => handleClickIndustry()}
      title={FORM_MODAL_STRING.SELECT_INDUSTRY}
      submitText={FORM_MODAL_STRING.DONE}
      onSubmit={(value) => handleSubmitIndustry(value)}
      showSearch={true}
      options={options}
      placeholder={FORM_MODAL_STRING.SEARCH_INDUSTRY}
    />
  );
  const handleClickIndustry = () => {
    setIndustryModal(!openIndustryModal);
  };

  const AddBrandForm: FormNode = {
    type: nodeType.array,
    childNode: [
      brandNameNode,
      {
        type: nodeType.input,
        elementData: {
          inputType: inputType.text,
          formItemProps: {
            rules: requiredRulesOnChange,
            name: FORM_FIELDS.LEGAL_ENTITY,
          },
          inputProps: {
            type: ArInputType.text,
            label: FORM_FIELDS.LEGAL_ENTITY_LABEL,
            varient: varientType.default,
            required: true,
          },
        },
      },
      {
        type: nodeType.container,
        elementData: {
          body: IndustryModal,
        },
        childNode: {
          type: nodeType.input,
          elementData: {
            inputType: inputType.text,
            inputProps: {
              type: ArInputType.text,
              label: FORM_FIELDS.INDUSTRY_LABEL,
              varient: varientType.default,
              required: true,
              readOnly: true,
              onClick: handleClickIndustry,
            },
            formItemProps: {
              rules: requiredRulesOnChange,
              name: FORM_FIELDS.INDUSTRY,
            },
          },
        },
      },
    ],
  };
  const handleSubmitModal = async () => {
    try {
      const validatedForm = await form.validateFields();
      let addBrandResponse;
      try {
        addBrandResponse = await addBrand({
          brandName: validatedForm[FORM_FIELDS.BRAND_NAME],
          industry: industryValues as string,
          legalEntityName: validatedForm[FORM_FIELDS.LEGAL_ENTITY],
          brandLogoUrl: brandLogoUrl as string,
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (addBrandResponse?.success) {
        successToast(STRING_CONSTANTS.BRAND_ADDED_SUCCESSFULLY);
        props?.onCancel?.();
      } else {
        errorToast(addBrandResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  return (
    <ArModal onSubmit={handleSubmitModal} width={"500px"} {...props}>
      <ArForm form={form} formNode={AddBrandForm} />
      {uploadBrand}
    </ArModal>
  );
};
