import { ContactInfo } from "@anarock/ar-common-react/dist/ui-components/ar-brand-modals/index.interface";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type SelfUpdateModalState = {
  open: boolean;
  contactPersons: ContactInfo[];
  shopId: string;
};

const initialState: SelfUpdateModalState = {
  open: false,
  contactPersons: [],
  shopId: "",
};

const selfUpdateModalSlice = createSlice({
  name: "selfUpdateModal",
  initialState,
  reducers: {
    setSelfUpdateModalOpen: (state, action) => {
      state.open = action.payload;
    },
    setAllContactPersons: (
      state,
      action: {
        payload: {
          contactPersons: ContactInfo[];
          shopId: string;
        };
      }
    ) => {
      state.contactPersons = action.payload.contactPersons;
      state.shopId = action.payload.shopId;
    },
  },
});

export const selectSelfUpdateModalOpen = (state: RootState) => state.selfUpdateModal.open;
export const selectAllContactPersons = (state: RootState) => state.selfUpdateModal.contactPersons;
export const selectShopId = (state: RootState) => state.selfUpdateModal.shopId;
export const { setAllContactPersons, setSelfUpdateModalOpen } = selfUpdateModalSlice.actions;
export default selfUpdateModalSlice.reducer;
