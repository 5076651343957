import { ArBrandModals } from "@anarock/ar-common-react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectContactModalOpen, selectContactPersons, setContactModalOpen } from "app/slice/contactModalSlice";
import React from "react";

const ViewContactDetailsModal: React.FC = () => {
  const open = useAppSelector(selectContactModalOpen);
  const contactPersons = useAppSelector(selectContactPersons);
  const dispatch = useAppDispatch();
  return (
    <ArBrandModals.ArContactInfoModal
      open={open}
      onCancel={() => dispatch(setContactModalOpen(false))}
      contactPersons={contactPersons}
    />
  );
};

export default ViewContactDetailsModal;
