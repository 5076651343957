import { CONSULTING_WIP_URLS, WIP_URLS } from "constants/ApiUrls";

import { IAnalyticsSortRequest, IAnalyticsTable, IFilterListResponse, IWipListResponse } from "./analytics";
import { api, GenericResponse } from "./api";
import { IComment } from "./collections";
import { ISelectedFilters } from "./listingPageApi";
import {
  IDeleteDocumentBody,
  IDeleteStatusModalDocument,
  IDocumentBody,
  IGetSignedUrl,
  IGetSignedUrlResponse,
  IGetWipDynamicStatusBody,
  IUpdateWipStatus,
  IWipBody,
  IWipDocumentsResponse,
  IWipResponse,
  IWipStatusResponse,
} from "./wip";

export interface IFiltersRequest {
  selectedFilters: ISelectedFilters;
  defaultVal?: boolean;
}

export interface IConsultingWIPBody {
  profitCenter: string;
  billingClient: string;
  cityIds: string[];
}

export type IGenericResponse = GenericResponse<void>;

export const consultingWipApi = api.injectEndpoints({
  endpoints: (builder) => ({
    consultingWipCommentList: builder.query<GenericResponse<{ comments: IComment[] }>, string | null | undefined>({
      query: (projectId) => ({
        url: `${CONSULTING_WIP_URLS.COMMENT}${CONSULTING_WIP_URLS.CONSULTING_WIP}/${projectId}`,
        method: "GET",
      }),
      providesTags: ["ConsultingWipComment"],
    }),
    postCommentToConsultingWip: builder.mutation<
      GenericResponse<void>,
      { projectId: string | null | undefined; text: string }
    >({
      query: ({ projectId, text }) => ({
        url: `${CONSULTING_WIP_URLS.COMMENT}${CONSULTING_WIP_URLS.CONSULTING_WIP}/${projectId}`,
        method: "POST",
        body: { text: text },
      }),
      invalidatesTags: ["ConsultingWipComment", "ConsultingWipList"],
    }),
    consultingWipList: builder.query<IWipListResponse, IAnalyticsSortRequest>({
      query: ({ filters, params, query, uid }) => ({
        url: CONSULTING_WIP_URLS.WIP_CONSULTING_LIST,
        method: "POST",
        body: { selectedFilters: filters, query, uid },
        params,
      }),
      providesTags: ["ConsultingWipList"],
    }),
    consultingWiFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters, defaultVal }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${CONSULTING_WIP_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters, defaultVal },
      }),
    }),
    getConsultingWipInvoiceTable: builder.query<GenericResponse<IAnalyticsTable>, string>({
      query: (projectId) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${CONSULTING_WIP_URLS.PROJECT}/${projectId}${CONSULTING_WIP_URLS.INVOICES}`,
        method: "GET",
      }),
      providesTags: ["ConsultingWipProjection"],
    }),
    createConsultingWip: builder.mutation<IWipResponse, IConsultingWIPBody>({
      query: (body) => ({
        url: CONSULTING_WIP_URLS.WIP_CONSULTING,
        method: "POST",
        body,
      }),
      invalidatesTags: ["WipDetails"],
    }),
    getConsultingWipSignedUrl: builder.mutation<IGetSignedUrlResponse, IGetSignedUrl>({
      query: ({ projectId, body }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/documents/upload-url`,
        method: "POST",
        body,
      }),
    }),
    uploadConsultingDocumentToUrl: builder.mutation<void, any>({
      query: ({ url, body }) => ({
        url,
        method: "POST",
        body,
        credentials: "omit",
      }),
      transformResponse: (_, meta: any) => {
        const res = meta?.response?.headers.get("location");
        return res;
      },
    }),
    updateConsultingDocumentToUrl: builder.mutation<void, IDocumentBody>({
      query: ({ projectId, body }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/documents`,
        method: "POST",
        body,
      }),
    }),
    getConsultingWipDocuments: builder.query<IWipDocumentsResponse, string>({
      query: (projectId) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/documents`,
        method: "GET",
      }),
    }),
    deleteConsultingDocument: builder.mutation<IGenericResponse, IDeleteDocumentBody>({
      query: ({ projectId, body }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/status/documents`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WipDetails"],
    }),
    getConsultingWipDynamicStatus: builder.query<IWipStatusResponse, IGetWipDynamicStatusBody>({
      query: ({ projectId, documentStructureId }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/status/documents/${documentStructureId}`,
        method: "GET",
      }),
    }),
    getConsultingWipDetails: builder.query<IWipResponse, string>({
      query: (projectId) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}`,
        method: "GET",
      }),
      providesTags: ["ConsultingWipDetails"],
    }),
    updateConsultingWipDetails: builder.mutation<IWipResponse, IWipBody>({
      query: (body) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${body?.projectId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (data) => {
        if (data?.success) {
          return ["ConsultingWipDetails", "ConsultingWipList"];
        }
        return [];
      },
    }),
    deleteConsultingWip: builder.mutation<IWipResponse, string>({
      query: (projectId) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}${WIP_URLS.DRAFT}`,
        method: "DELETE",
      }),
      invalidatesTags: (data) => {
        if (data?.success) {
          return ["ConsultingWipDetails", "ConsultingWipList"];
        }
        return [];
      },
    }),
    getConsultingWipStatusList: builder.query<IWipStatusResponse, string>({
      query: (projectId) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/status/list`,
        method: "GET",
      }),
    }),
    deleteConsultingStatusModalDocument: builder.mutation<void, IDeleteStatusModalDocument>({
      query: ({ projectId, body }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/documents`,
        method: "DELETE",
        body,
      }),
    }),
    updateConsultingWipStatus: builder.mutation<IWipResponse, IUpdateWipStatus>({
      query: ({ projectId, body }) => ({
        url: `${CONSULTING_WIP_URLS.WIP_CONSULTING}${WIP_URLS.PROJECT}/${projectId}/status`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ConsultingWipDetails", "ConsultingWipList"],
    }),
  }),
});

export const {
  useConsultingWipCommentListQuery,
  usePostCommentToConsultingWipMutation,
  useConsultingWipListQuery,
  useConsultingWiFiltersQuery,
  useGetConsultingWipInvoiceTableQuery,
  useCreateConsultingWipMutation,
  useGetConsultingWipSignedUrlMutation,
  useUploadConsultingDocumentToUrlMutation,
  useUpdateConsultingDocumentToUrlMutation,
  useLazyGetConsultingWipDocumentsQuery,
  useDeleteConsultingDocumentMutation,
  useLazyGetConsultingWipDynamicStatusQuery,
  useGetConsultingWipDetailsQuery,
  useUpdateConsultingWipDetailsMutation,
  useLazyGetConsultingWipStatusListQuery,
  useDeleteConsultingStatusModalDocumentMutation,
  useUpdateConsultingWipStatusMutation,
  useDeleteConsultingWipMutation,
} = consultingWipApi;
