import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  useAddShopToMultipleOptionsListMutation,
  useGetMatchingRequiremetDetailsQuery,
} from "app/services/shopListingApi";
import { isStringEmpty } from "app/utils/helperFunctions";
import { useQueryApiError } from "app/utils/useQueryApiError";
import { ArAddToOptionsListModal } from "components/appModals/addToOptionListModal";
import { selectOpenModal, selectShopId, setOpenModal } from "components/appModals/addToOptionListSlice";
import { useToastService } from "components/toastService";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React from "react";

const AddToMultileOptionsList = () => {
  const open = useAppSelector(selectOpenModal);
  const dispatch = useAppDispatch();
  const selectedShopId = useAppSelector(selectShopId);
  const {
    data: matchingBrands,
    isFetching,
    isLoading: loadingMatchingBrands,
    isError,
  } = useGetMatchingRequiremetDetailsQuery(selectedShopId, {
    refetchOnMountOrArgChange: true,
    skip: isStringEmpty(selectedShopId),
  });
  useQueryApiError(isError, loadingMatchingBrands, isFetching, matchingBrands);
  const [addToMultipleOptionsList, { isLoading }] = useAddShopToMultipleOptionsListMutation();
  const brandRequirementDetails = matchingBrands?.data?.brandRequirementDetails;

  const { errorToast, successToast } = useToastService();
  const handleAddToMultipleOptionsList = async (brandRequirementDetailIds: string[]) => {
    let addToMultipleOptionsListResponse;
    try {
      addToMultipleOptionsListResponse = await addToMultipleOptionsList({
        shopId: selectedShopId,
        brandRequirementDetailIds,
      }).unwrap();
    } catch {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    const success = addToMultipleOptionsListResponse?.success;
    if (success) {
      successToast(TOAST_MESSAGES.SHOP_ADDED_TO_OPTION_LIST);
      dispatch(setOpenModal(false));
    } else {
      const error = addToMultipleOptionsListResponse?.reason;
      errorToast(error || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
  };
  return (
    <ArAddToOptionsListModal
      onSubmit={handleAddToMultipleOptionsList}
      open={open}
      brandRequirementDetails={brandRequirementDetails || []}
      destroyOnClose
      onCancel={() => dispatch(setOpenModal(false))}
      isLoading={isFetching}
      isSubmitting={isLoading}
    />
  );
};

export default AddToMultileOptionsList;
