import { AUTH_URLS } from "constants/ApiUrls";
import { IUser } from "features/authentication/authSlice";

import { api, GenericResponse, IResponse } from "./api";

export interface ILoginBody {
  email: string;
  password: string;
}

export interface IResetPasswordBody {
  userId: string;
  resetToken: string;
  newPassword: string;
}

export type ILoginResponse = GenericResponse<{ user: IUser }>;
export type ILogoutResponse = GenericResponse<null>;

// Injecting auth endpoints to base API
export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginBody>({
      query: (credentials) => ({
        url: AUTH_URLS.LOGIN,
        method: "POST",
        body: credentials,
      }),
      // invalidatesTags: ["auth"],
    }),
    logout: builder.mutation<ILogoutResponse, void>({
      query: () => ({
        url: AUTH_URLS.LOGOUT,
        method: "POST",
      }),
    }),
    checkAuth: builder.query<ILoginResponse, void>({
      query: () => ({
        url: AUTH_URLS.CHECK_AUTH,
        method: "get",
      }),
    }),
    verifyUser: builder.mutation<IResponse, { email: string }>({
      query: (credentials) => ({
        url: AUTH_URLS.VERIFY_USER,
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<IResponse, { email: string }>({
      query: (credentials) => ({
        url: AUTH_URLS.FORGOT_PASSWORD,
        method: "POST",
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation<IResponse, IResetPasswordBody>({
      query: (credentials) => ({
        url: AUTH_URLS.RESET_PASSWORD,
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useCheckAuthQuery,
  useVerifyUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
